import { SvgIcon } from '@bambu/react-ui';
import type { SvgIconProps } from '@bambu/react-ui';

export function HouseIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox={'0 0 40 40'} {...props}>
      <path d="M21.9997 10.0833L32.9997 18.3333V34.8333H10.9997V18.3333L21.9997 10.0833ZM21.9997 5.5L7.33301 16.5V38.5H36.6663V16.5L21.9997 5.5ZM27.4997 22H21.083V28.4167H14.6663V20.1667H12.833V33H14.6663V30.25H29.333V33H31.1663V25.6667C31.1663 23.65 29.5163 22 27.4997 22ZM17.8747 22.9167C16.6097 22.9167 15.583 23.9433 15.583 25.2083C15.583 26.4733 16.6097 27.5 17.8747 27.5C19.1397 27.5 20.1663 26.4733 20.1663 25.2083C20.1663 23.9433 19.1397 22.9167 17.8747 22.9167Z" />
    </SvgIcon>
  );
}
