import { InvestorGetGoalsForTenantInvestorResponseDto } from '../Goals';
import {
  GoalRecurringSavingsPlanFrequencyEnum,
  GoalRecurringSavingsPlanStatusEnum,
  GoalStatusEnum,
} from '@bambu/shared';

export const getInvestorGoalsMockResponse: InvestorGetGoalsForTenantInvestorResponseDto =
  [
    {
      GoalRecurringSavingsPlans: [
        {
          amount: 0,
          createdAt: new Date('2023-11-20T15:24:07.713Z'),
          createdBy: 'string',
          currency: 'string',
          data: {},
          frequency: GoalRecurringSavingsPlanFrequencyEnum.MONTHLY,
          goalId: 'string',
          id: 'string',
          status: GoalRecurringSavingsPlanStatusEnum.PENDING,
          updatedAt: new Date('2023-11-20T15:24:07.713Z'),
          updatedBy: 'string',
        },
      ],
      computedRiskProfile: {},
      connectPortfolioSummaryId: 'string',
      createdAt: new Date('2023-11-20T15:24:07.713Z'),
      createdBy: 'string',
      data: {},
      goalDescription: 'string',
      goalEndDate: new Date('2023-11-20T15:24:07.713Z'),
      goalName: 'string',
      goalStartDate: new Date('2023-11-20T15:24:07.713Z'),
      goalTimeframe: 0,
      goalValue: 0,
      id: 'string',
      initialInvestment: 0,
      investorId: 'string',
      sendLeadAppointmentEmail: true,
      sendLeadGoalProjectionEmail: true,
      status: GoalStatusEnum.PENDING,
      updatedAt: new Date('2023-11-20T15:24:07.713Z'),
      updatedBy: 'string',
      ConnectPortfolioSummary: {
        RiskProfile: {
          id: 'dfc77d5a-f1d6-49fd-9aac-b9f0c7d7087a',
          lowerLimit: '1',
          upperLimit: '1',
          riskProfileName: 'Low Risk',
          riskProfileDescription:
            'You don’t want to experience volatility in your portfolio.<br/>You don’t expect the value of your portfolio to go down at any point in time.<br/>You should also understand that expected returns are very low.',
          tenantId: '9f0165e8-fa92-4bbf-808e-9dc7b6956970',
        },
        TransactModelPortfolios: [
          {
            connectPortfolioSummaryId: 'string',
            createdAt: '2023-11-20T15:24:07.713Z',
            createdBy: 'string',
            description: 'string',
            expectedAnnualReturn: 0,
            expectedAnnualVolatility: 0,
            factSheetUrl: 'string',
            id: 'string',
            name: 'string',
            partnerModelId: 'string',
            rebalancingThreshold: 0,
            updatedAt: '2023-11-20T15:24:07.713Z',
            updatedBy: 'string',
            TransactModelPortfolioInstruments: [
              {
                instrumentId: 'string',
                transactModelPortfolioId: 'string',
                weightage: 0,
                createdAt: '2023-11-20T15:24:07.713Z',
                createdBy: 'string',
                id: 'string',
                updatedAt: '2023-11-20T15:24:07.713Z',
                updatedBy: 'string',
                Instrument: {
                  InstrumentAssetClass: {
                    createdAt: '2023-11-20T15:24:07.713Z',
                    createdBy: 'string',
                    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                    name: 'string',
                    updatedAt: '2023-11-20T15:24:07.713Z',
                    updatedBy: 'string',
                  },
                  InstrumentCurrency: {
                    createdAt: '2023-11-20T15:24:07.713Z',
                    createdBy: 'string',
                    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                    iso4217Code: 'string',
                    updatedAt: '2023-11-20T15:24:07.713Z',
                    updatedBy: 'string',
                  },
                  InstrumentExchange: {
                    bambuExchangeCode: 'string',
                    createdAt: '2023-11-20T15:24:07.713Z',
                    createdBy: 'string',
                    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                    updatedAt: '2023-11-20T15:24:07.713Z',
                    updatedBy: 'string',
                  },
                  InstrumentFactSheet: [
                    {
                      createdAt: '2023-11-20T15:24:07.713Z',
                      createdBy: 'string',
                      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                      instrumentId: 'string',
                      updatedAt: '2023-11-20T15:24:07.713Z',
                      updatedBy: 'string',
                      url: 'string',
                    },
                  ],
                  bloombergTicker: null,
                  createdAt: '2023-11-20T15:24:07.713Z',
                  createdBy: 'string',
                  cusip: null,
                  id: 'string',
                  instrumentAssetClassId:
                    '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                  instrumentCurrencyId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                  instrumentExchangeId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                  isin: null,
                  name: 'string',
                  ricSymbol: null,
                  updatedAt: '2023-11-20T15:24:07.714Z',
                  updatedBy: 'string',
                },
              },
            ],
          },
        ],
        assetClassAllocation: [
          {
            assetClass: 'Equity',
            percentOfPortfolio: '3.5',
            included: true,
          },
        ],
        description: 'string',
        expectedReturnPercent: 'string',
        expectedVolatilityPercent: 'string',
        id: 'string',
        key: 'string',
        name: 'string',
        reviewed: true,
        risk_profile_id: 'string',
        showSummaryStatistics: true,
        tenantId: 'string',
        sortKey: 0,
      },
      portfolioValue: 0,
      portfolioValueCurrency: 'string',
      portfolioValueDate: 'string',
    },
  ];

export default getInvestorGoalsMockResponse;
