export const getInstrumentsMockRes = {
  data: [
    {
      id: '03f08ab118b6ff8870ad8b6e9135309f',
      bloombergTicker: 'D5BM',
      ricSymbol: null,
      isin: 'LU0490618542',
      cusip: null,
      name: 'Xtrackers S&P 500 Swap UCITS ETF 1C',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.219Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.675Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '53ceb28c-5f97-4587-9e9c-292a00c9c0de',
          url: 'https://etf.dws.com/en-gb/LU0490618542-s-p-500-swap-ucits-etf-1c/',
          instrumentId: '03f08ab118b6ff8870ad8b6e9135309f',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.222Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.222Z',
        },
      ],
    },
    {
      id: '0c9cd604c56b3f66345aaceca8c7ee07',
      bloombergTicker: 'IS15',
      ricSymbol: null,
      isin: 'IE00B5L65R35',
      cusip: null,
      name: 'iShares Corp Bond 0-5yr UCITS ETF GBP (Dist)',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: '340a81d9-3d4d-471e-8ade-458507f46745',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.347Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.779Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: '340a81d9-3d4d-471e-8ade-458507f46745',
        iso4217Code: 'GBP',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'b17953ba-6ec7-4592-ae61-f53fdcd0087a',
          url: 'https://www.ishares.com/uk/individual/en/products/251840/?referrer=tickerSearch',
          instrumentId: '0c9cd604c56b3f66345aaceca8c7ee07',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.349Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.349Z',
        },
      ],
    },
    {
      id: '1328fca2aa7660e9688c9e5180e27f47',
      bloombergTicker: 'IUSA',
      ricSymbol: null,
      isin: 'IE0031442068',
      cusip: null,
      name: 'iShares Core S&P 500 UCITS ETF USD (Dist)',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.174Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.635Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '93680d1b-24ef-4cd6-9b57-d0e71968475a',
          url: 'https://www.ishares.com/uk/individual/en/products/251900/',
          instrumentId: '1328fca2aa7660e9688c9e5180e27f47',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.176Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.176Z',
        },
      ],
    },
    {
      id: '149696a7a208d8f3074ee563f7a42d5b',
      bloombergTicker: 'ISF',
      ricSymbol: null,
      isin: 'IE0005042456',
      cusip: null,
      name: 'iShares Core FTSE 100 UCITS ETF GBP Dist',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: '340a81d9-3d4d-471e-8ade-458507f46745',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.179Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.639Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: '340a81d9-3d4d-471e-8ade-458507f46745',
        iso4217Code: 'GBP',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '15902896-e5ef-4ece-b0ef-731cdcc36074',
          url: 'https://www.ishares.com/uk/individual/en/products/251795/',
          instrumentId: '149696a7a208d8f3074ee563f7a42d5b',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.181Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.181Z',
        },
      ],
    },
    {
      id: '196588837fd33160c0c6930266728394',
      bloombergTicker: 'SGLD',
      ricSymbol: null,
      isin: 'IE00B579F325',
      cusip: null,
      name: 'Invesco Physical Gold ETC',
      instrumentAssetClassId: '22e36a70-eea7-4a43-b056-0b7676e37839',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.164Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.627Z',
      InstrumentAssetClass: {
        id: '22e36a70-eea7-4a43-b056-0b7676e37839',
        name: 'Others',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'e08e8125-f288-4362-909e-2787988dec1d',
          url: 'https://etf.invesco.com/gb/private/en/product/invesco-physical-gold-etc/trading-information?audienceType=private&_ga=2.53135840.956770675.1695291548-1533226516.1695291548',
          instrumentId: '196588837fd33160c0c6930266728394',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.166Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.166Z',
        },
      ],
    },
    {
      id: '1ca9521b41d8082ca39a687fdb005767',
      bloombergTicker: 'VWRL',
      ricSymbol: null,
      isin: 'IE00B3RBWM25',
      cusip: null,
      name: 'Vanguard FTSE All-World UCITS ETF USD Dis',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.146Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.611Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'a0a5e053-3f98-4961-a426-8c7ea99f9c98',
          url: 'https://www.vanguardinvestor.co.uk/investments/vanguard-ftse-all-world-ucits-etf-usd-distributing/overview',
          instrumentId: '1ca9521b41d8082ca39a687fdb005767',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.148Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.148Z',
        },
      ],
    },
    {
      id: '21a6561b27b3b3583d05ff29b1a14876',
      bloombergTicker: 'SPY5',
      ricSymbol: null,
      isin: 'IE00B6YX5C33',
      cusip: null,
      name: 'SPDR S&P 500 UCITS ETF Dist',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.283Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.727Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'ec463625-a899-40cc-888c-6f15e24462d7',
          url: 'https://www.ssga.com/uk/en_gb/intermediary/etfs/funds/spdr-sp-500-ucits-etf-dist-spy5-gy',
          instrumentId: '21a6561b27b3b3583d05ff29b1a14876',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.284Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.284Z',
        },
      ],
    },
    {
      id: '2e38fc42c809593ef850c60790ced143',
      bloombergTicker: 'ISAC',
      ricSymbol: null,
      isin: 'IE00B6R52259',
      cusip: null,
      name: 'iShares MSCI ACWI UCITS ETF USD (Acc)',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.204Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.661Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'dbc7bc72-e1d3-444c-b9b5-ff0a2a36ddd7',
          url: 'https://www.ishares.com/uk/individual/en/products/251850/',
          instrumentId: '2e38fc42c809593ef850c60790ced143',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.205Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.205Z',
        },
      ],
    },
    {
      id: '2f1676af6bdef00517f7d00d148cdead',
      bloombergTicker: 'SPXS',
      ricSymbol: null,
      isin: 'IE00B3YCGJ38',
      cusip: null,
      name: 'Invesco S&P 500 UCITS ETF Acc',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.153Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.616Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '66dbe5a7-1a57-412c-bd8f-a10cbe897a00',
          url: 'https://etf.invesco.com/gb/private/en/product/invesco-sp-500-ucits-etf-acc/trading-information?audienceType=private',
          instrumentId: '2f1676af6bdef00517f7d00d148cdead',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.155Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.155Z',
        },
      ],
    },
    {
      id: '3dcc12dbe7d672d4258b518ccb02f058',
      bloombergTicker: 'IUSE',
      ricSymbol: null,
      isin: 'IE00B3ZW0K18',
      cusip: null,
      name: 'iShares S&P 500 EUR Hedged UCITS ETF (Acc)',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.293Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.735Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
        iso4217Code: 'EUR',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '066bd0e2-5a33-4764-bea2-b18bf915ef05',
          url: 'https://www.ishares.com/uk/individual/en/products/251903/?referrer=tickerSearch',
          instrumentId: '3dcc12dbe7d672d4258b518ccb02f058',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.295Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.295Z',
        },
      ],
    },
    {
      id: '3e6b393fb6bf1ae93a162638eb3b1649',
      bloombergTicker: 'XESX',
      ricSymbol: null,
      isin: 'LU0274211217',
      cusip: null,
      name: 'Xtrackers Euro Stoxx 50 UCITS ETF 1D',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.262Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.709Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
        iso4217Code: 'EUR',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '5bcbb6d9-94e8-4089-bfe2-a2b33181550a',
          url: 'https://etf.dws.com/en-gb/LU0274211217-euro-stoxx-50-ucits-etf-1d/',
          instrumentId: '3e6b393fb6bf1ae93a162638eb3b1649',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.264Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.264Z',
        },
      ],
    },
    {
      id: '40fbd1f77ebad3de1ba45938668cd797',
      bloombergTicker: 'ERNS',
      ricSymbol: null,
      isin: 'IE00BCRY6441',
      cusip: null,
      name: 'iShares £ Ultrashort Bond UCITS ETF GBP (Dist)',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: '340a81d9-3d4d-471e-8ade-458507f46745',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.357Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.787Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: '340a81d9-3d4d-471e-8ade-458507f46745',
        iso4217Code: 'GBP',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '7c0162ce-00f3-4119-bb00-9f6f88a58b57',
          url: 'https://www.ishares.com/uk/individual/en/products/258120/?referrer=tickerSearch',
          instrumentId: '40fbd1f77ebad3de1ba45938668cd797',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.359Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.359Z',
        },
      ],
    },
    {
      id: '415bd6b7f31b32c7ff7c2ce384095738',
      bloombergTicker: 'IEML',
      ricSymbol: null,
      isin: 'IE00B5M4WH52',
      cusip: null,
      name: 'iShares JPM EM Local Govt Bond UCITS ETF USD D',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.288Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.731Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '6ad96078-4042-44d2-b8b1-7b81c908585f',
          url: 'https://www.ishares.com/uk/individual/en/products/251724/?referrer=tickerSearch',
          instrumentId: '415bd6b7f31b32c7ff7c2ce384095738',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.290Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.290Z',
        },
      ],
    },
    {
      id: '44d3fc11943fbf791515064f90eec555',
      bloombergTicker: 'LQDE',
      ricSymbol: null,
      isin: 'IE0032895942',
      cusip: null,
      name: 'iShares $ Corp Bond UCITS ETF USD Dist',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.209Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.666Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '89d47425-9588-4589-b553-f51f038e8195',
          url: 'https://www.ishares.com/uk/individual/en/products/251832/',
          instrumentId: '44d3fc11943fbf791515064f90eec555',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.210Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.210Z',
        },
      ],
    },
    {
      id: '4b0c6c000f4de08286f620506cfccaee',
      bloombergTicker: 'IGLT',
      ricSymbol: null,
      isin: 'IE00B1FZSB30',
      cusip: null,
      name: 'iShares Core UK Gilts UCITS ETF GBP (Dist)',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: '340a81d9-3d4d-471e-8ade-458507f46745',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.337Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.770Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: '340a81d9-3d4d-471e-8ade-458507f46745',
        iso4217Code: 'GBP',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '475bcf75-0fed-4f34-b123-807219c2a7d0',
          url: 'https://www.ishares.com/uk/individual/en/products/251806/?referrer=tickerSearch',
          instrumentId: '4b0c6c000f4de08286f620506cfccaee',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.338Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.338Z',
        },
      ],
    },
    {
      id: '591aac112c1d83f579c32626175aa9a0',
      bloombergTicker: 'EQQQ',
      ricSymbol: null,
      isin: 'IE0032077012',
      cusip: null,
      name: 'Invesco EQQQ NASDAQ-100 UCITS ETF Dist',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.225Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.679Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'c1d85737-a535-4cbb-9156-738e7d629ce8',
          url: 'https://etf.invesco.com/gb/private/en/product/invesco-eqqq-nasdaq-100-ucits-etf-dist/trading-information?audienceType=private&_ga=2.53135840.956770675.1695291548-1533226516.1695291548',
          instrumentId: '591aac112c1d83f579c32626175aa9a0',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.227Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.227Z',
        },
      ],
    },
    {
      id: '597bb65ddccc3503df81d2283b95d1d9',
      bloombergTicker: 'IWDA',
      ricSymbol: null,
      isin: 'IE00B4L5Y983',
      cusip: null,
      name: 'iShares Core MSCI World UCITS ETF USD (Acc)',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.135Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.601Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'e23abfcd-9be8-489a-84b7-6f5c13016837',
          url: 'https://www.ishares.com/uk/individual/en/products/251882/ishares-msci-world-ucits-etf-acc-fund',
          instrumentId: '597bb65ddccc3503df81d2283b95d1d9',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.137Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.137Z',
        },
      ],
    },
    {
      id: '674003a00ccd5dd6d50f7f46ff3c07ca',
      bloombergTicker: 'IWRD',
      ricSymbol: null,
      isin: 'IE00B0M62Q58',
      cusip: null,
      name: 'iShares MSCI World UCITS ETF USD (Dist)',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.246Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.696Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'f2a9d878-3882-4b33-aa75-58699bfce3bd',
          url: 'https://www.ishares.com/uk/individual/en/products/251881/?referrer=tickerSearch',
          instrumentId: '674003a00ccd5dd6d50f7f46ff3c07ca',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.248Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.248Z',
        },
      ],
    },
    {
      id: '6fef1cb5c2834f17ca22553a0aac8722',
      bloombergTicker: 'CSNDX',
      ricSymbol: null,
      isin: 'IE00B53SZB19',
      cusip: null,
      name: 'iShares NASDAQ 100 UCITS ETF USD (Acc)',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.184Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.644Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '96c45af3-9303-46b9-ad3a-d10bbc5c0999',
          url: 'https://www.ishares.com/uk/individual/en/products/253741/?referrer=tickerSearch',
          instrumentId: '6fef1cb5c2834f17ca22553a0aac8722',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.186Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.186Z',
        },
      ],
    },
    {
      id: '78042b031a1e2749ae30b14a9de2fe8f',
      bloombergTicker: 'SEMB',
      ricSymbol: null,
      isin: 'IE00B2NPKV68',
      cusip: null,
      name: 'iShares JP Morgan $ EM Bond UCITS ETF USD Dis',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.230Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.683Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'cc461bb9-0bf7-43a1-9be1-eb9073a42bb7',
          url: 'https://www.ishares.com/uk/individual/en/products/251824/',
          instrumentId: '78042b031a1e2749ae30b14a9de2fe8f',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.232Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.232Z',
        },
      ],
    },
    {
      id: '831a2d044d2e6695b0b09987032c2bee',
      bloombergTicker: 'IBTM',
      ricSymbol: null,
      isin: 'IE00B1FZS798',
      cusip: null,
      name: 'iShares $ Treasury Bd 7-10y UCITS ETF USD Dist',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.194Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.653Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '87191983-9248-4566-9a20-5fd63e676968',
          url: 'https://www.ishares.com/uk/individual/en/products/251716/',
          instrumentId: '831a2d044d2e6695b0b09987032c2bee',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.196Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.196Z',
        },
      ],
    },
    {
      id: '89f72cfaf2d298f2dfed73c10f914714',
      bloombergTicker: 'IEAC',
      ricSymbol: null,
      isin: 'IE00B3F81R35',
      cusip: null,
      name: 'iShares Core € Corp Bond UCITS ETF EUR D',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.158Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.621Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
        iso4217Code: 'EUR',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '368240ab-17f3-4fd5-80c1-e02942a213b5',
          url: 'https://www.ishares.com/uk/individual/en/products/251726/',
          instrumentId: '89f72cfaf2d298f2dfed73c10f914714',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.161Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.161Z',
        },
      ],
    },
    {
      id: '912e7f76cfb94307876bcfc3d2bdcf28',
      bloombergTicker: 'WSML',
      ricSymbol: null,
      isin: 'IE00BF4RFH31',
      cusip: null,
      name: 'iShares MSCI World Small Cap UCITS ETF USD (Acc)',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.331Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.766Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '267b4898-2e9b-4834-ae9e-beff13a1a56c',
          url: 'https://www.ishares.com/uk/individual/en/products/296576/?referrer=tickerSearch',
          instrumentId: '912e7f76cfb94307876bcfc3d2bdcf28',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.333Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.333Z',
        },
      ],
    },
    {
      id: '9353aa88d9ba805b300330d75f0a0ee6',
      bloombergTicker: 'CBU7',
      ricSymbol: null,
      isin: 'IE00B3VWN393',
      cusip: null,
      name: 'iShares $ Treasury Bond 3-7yr UCITS ETF USD A',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.251Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.700Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '90bc1d41-82b5-4483-b103-dc1a58750a67',
          url: 'https://www.ishares.com/uk/individual/en/products/253744/?referrer=tickerSearch',
          instrumentId: '9353aa88d9ba805b300330d75f0a0ee6',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.253Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.253Z',
        },
      ],
    },
    {
      id: '9868e9dc550a513d5886695b73341db1',
      bloombergTicker: 'IGLS',
      ricSymbol: null,
      isin: 'IE00B4WXJK79',
      cusip: null,
      name: 'iShares UK Gilts 0-5yr UCITS ETF GBP (Dist)',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: '340a81d9-3d4d-471e-8ade-458507f46745',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.320Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.757Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: '340a81d9-3d4d-471e-8ade-458507f46745',
        iso4217Code: 'GBP',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'b96b7acf-db6d-4c4e-83f6-1f2e11ec053f',
          url: 'https://www.ishares.com/uk/individual/en/products/251804/?referrer=tickerSearch',
          instrumentId: '9868e9dc550a513d5886695b73341db1',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.322Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.322Z',
        },
      ],
    },
    {
      id: '9ab6f79bef6675c50cda477284547fdf',
      bloombergTicker: 'VUKE',
      ricSymbol: null,
      isin: 'IE00B810Q511',
      cusip: null,
      name: 'Vanguard FTSE 100 UCITS ETF GBP Dis',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: '340a81d9-3d4d-471e-8ade-458507f46745',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.272Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.718Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: '340a81d9-3d4d-471e-8ade-458507f46745',
        iso4217Code: 'GBP',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'f798aaee-fc51-426d-965c-8da5c571233d',
          url: 'https://www.vanguardinvestor.co.uk/investments/vanguard-ftse-100-ucits-etf-gbp-distributing/overview?query=IE00B810Q511',
          instrumentId: '9ab6f79bef6675c50cda477284547fdf',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.274Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.274Z',
        },
      ],
    },
    {
      id: 'a32ccc481d0e3f15d77f566ec114be97',
      bloombergTicker: 'XESC',
      ricSymbol: null,
      isin: 'LU0380865021',
      cusip: null,
      name: 'Xtrackers Euro Stoxx 50 UCITS ETF 1C',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.257Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.704Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
        iso4217Code: 'EUR',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'faec5c22-cfcb-4c42-a7d3-694a8b0c191d',
          url: 'https://etf.dws.com/en-gb/LU0380865021-euro-stoxx-50-ucits-etf-1c/',
          instrumentId: 'a32ccc481d0e3f15d77f566ec114be97',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.259Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.259Z',
        },
      ],
    },
    {
      id: 'a46d6a2c12d72f852b9e20c9ce92dbce',
      bloombergTicker: 'IHYG',
      ricSymbol: null,
      isin: 'IE00B66F4759',
      cusip: null,
      name: 'iShares € High Yield Corp Bond UCITS ETF EUR D',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.267Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.713Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
        iso4217Code: 'EUR',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'e9550a7a-d07b-4596-b2f9-00dcd55b293f',
          url: 'https://www.ishares.com/uk/individual/en/products/251843/',
          instrumentId: 'a46d6a2c12d72f852b9e20c9ce92dbce',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.269Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.269Z',
        },
      ],
    },
    {
      id: 'a4b9bb7397118fd0e10cac154648561d',
      bloombergTicker: 'IE15',
      ricSymbol: null,
      isin: 'IE00B4L60045',
      cusip: null,
      name: 'iShares € Corp Bond 1-5 yr UCITS ETF EUR (Dist)',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.303Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.744Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
        iso4217Code: 'EUR',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '87211e6c-7b3d-4e49-be6b-029774bdf48d',
          url: 'https://www.ishares.com/uk/individual/en/products/251728/',
          instrumentId: 'a4b9bb7397118fd0e10cac154648561d',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.306Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.306Z',
        },
      ],
    },
    {
      id: 'a6fb030e7fdc40a390e9821dc1eb7243',
      bloombergTicker: 'GILS',
      ricSymbol: null,
      isin: 'LU1407892592',
      cusip: null,
      name: 'Lyxor Core UK Government Bond (DR) UCITS ETF D',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: '340a81d9-3d4d-471e-8ade-458507f46745',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.362Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.791Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: '340a81d9-3d4d-471e-8ade-458507f46745',
        iso4217Code: 'GBP',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'bb6640ab-50ab-4ac6-a48a-c08cf38451c8',
          url: 'https://www.amundietf.lu/en/individual/products/fixed-income/lyxor-core-uk-government-bond-dr-ucits-etf-dist/lu1407892592',
          instrumentId: 'a6fb030e7fdc40a390e9821dc1eb7243',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.364Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.364Z',
        },
      ],
    },
    {
      id: 'aa4f5ef7b7df5cc9065a508c7b96a649',
      bloombergTicker: 'RBOT',
      ricSymbol: null,
      isin: 'IE00BYZK4552',
      cusip: null,
      name: 'iShares Automation & Robotics UCITS ETF USD A',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.326Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.761Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '5f3a5f0f-8db5-49cb-b181-561d2d4a6b71',
          url: 'https://www.ishares.com/uk/individual/en/products/284219/?referrer=tickerSearch',
          instrumentId: 'aa4f5ef7b7df5cc9065a508c7b96a649',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.328Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.328Z',
        },
      ],
    },
    {
      id: 'b746d2cc034a4f857e52df85f4dae88e',
      bloombergTicker: 'HSPX',
      ricSymbol: null,
      isin: 'IE00B5KQNG97',
      cusip: null,
      name: 'HSBC S&P 500 UCITS ETF USD',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.309Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.748Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '7a819b53-fe76-49d2-baa2-e4fd08ec5aed',
          url: 'https://www.londonstockexchange.com/stock/HSPX/hsbc/company-page',
          instrumentId: 'b746d2cc034a4f857e52df85f4dae88e',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.311Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.311Z',
        },
      ],
    },
    {
      id: 'bf96f826faaf676681ff6661724426ae',
      bloombergTicker: 'HMWO',
      ricSymbol: null,
      isin: 'IE00B4X9L533',
      cusip: null,
      name: 'HSBC MSCI World UCITS ETF USD',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.241Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.692Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '6f2eb94d-2469-4e17-8756-56acfec81782',
          url: 'https://www.londonstockexchange.com/stock/HMWD/hsbc/company-page',
          instrumentId: 'bf96f826faaf676681ff6661724426ae',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.243Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.243Z',
        },
      ],
    },
    {
      id: 'c179abda6aa0da42256f18a53cb80f21',
      bloombergTicker: 'IUIT',
      ricSymbol: null,
      isin: 'IE00B3WJKG14',
      cusip: null,
      name: 'iShares S&P 500 Info Technolg Sctr UCITS ETF USD A',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.315Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.753Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '4db9643a-966a-47a6-ad78-e801e65ab92b',
          url: 'https://www.ishares.com/uk/individual/en/products/280510/?referrer=tickerSearch',
          instrumentId: 'c179abda6aa0da42256f18a53cb80f21',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.317Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.317Z',
        },
      ],
    },
    {
      id: 'c17f9b17d0d8177afae3453429e221aa',
      bloombergTicker: 'CASH_GBP',
      ricSymbol: null,
      isin: 'CASH_GBP',
      cusip: null,
      name: 'Cash - Pound Sterling',
      instrumentAssetClassId: '0f2a6ef3-54aa-4f48-af0b-5de94299be8e',
      instrumentExchangeId: '7d52817d-a543-4118-8fb4-387e330ed556',
      instrumentCurrencyId: '340a81d9-3d4d-471e-8ade-458507f46745',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.367Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.796Z',
      InstrumentAssetClass: {
        id: '0f2a6ef3-54aa-4f48-af0b-5de94299be8e',
        name: 'Cash',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: '340a81d9-3d4d-471e-8ade-458507f46745',
        iso4217Code: 'GBP',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: '7d52817d-a543-4118-8fb4-387e330ed556',
        bambuExchangeCode: 'CASH',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [],
    },
    {
      id: 'c1e4cd86e17da236d0b20e2258255145',
      bloombergTicker: 'ZPRR',
      ricSymbol: null,
      isin: 'IE00BJ38QD84',
      cusip: null,
      name: 'SPDR Russell 2000 US Small Cap UCITS ETF Acc',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.352Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.783Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '95a72cf8-0577-4c69-a332-e8b217ea2556',
          url: 'https://www.ssga.com/uk/en_gb/intermediary/etfs/funds/spdr-russell-2000-us-small-cap-ucits-etf-acc-zprr-gy',
          instrumentId: 'c1e4cd86e17da236d0b20e2258255145',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.354Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.354Z',
        },
      ],
    },
    {
      id: 'c7b0e8185ed2030230cd45012484a09f',
      bloombergTicker: 'IHYU',
      ricSymbol: null,
      isin: 'IE00B4PY7Y77',
      cusip: null,
      name: 'iShares $ High Yield Corp Bond UCITS ETF USD D',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.298Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.740Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '7f38c19a-d08b-457d-afce-7d5de0a9c45a',
          url: 'https://www.ishares.com/uk/individual/en/products/251833/?referrer=tickerSearch',
          instrumentId: 'c7b0e8185ed2030230cd45012484a09f',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.300Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.300Z',
        },
      ],
    },
    {
      id: 'c8d951d34d1a1440a2a40e5820df4902',
      bloombergTicker: 'IMEU',
      ricSymbol: null,
      isin: 'IE00B1YZSC51',
      cusip: null,
      name: 'iShares Core MSCI Europe UCITS ETF EUR Dist',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.214Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.670Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
        iso4217Code: 'EUR',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '15e1253a-995a-4945-886e-ac2001db49d6',
          url: 'https://www.ishares.com/uk/individual/en/products/251860/',
          instrumentId: 'c8d951d34d1a1440a2a40e5820df4902',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.215Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.215Z',
        },
      ],
    },
    {
      id: 'ca47fd5a916620d631a410f3378f7066',
      bloombergTicker: 'VUSA',
      ricSymbol: null,
      isin: 'IE00B3XXRP09',
      cusip: null,
      name: 'Vanguard S&P 500 UCITS ETF USD Dis',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.141Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.606Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'f4f47f2f-e230-449e-9722-b7bb80ab3d18',
          url: 'https://www.vanguardinvestor.co.uk/investments/vanguard-s-and-p-500-ucits-etf-usd-distributing/overview?query=IE00B3XXRP09',
          instrumentId: 'ca47fd5a916620d631a410f3378f7066',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.143Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.143Z',
        },
      ],
    },
    {
      id: 'cf6f5f7ce6d4c79b7063ec329629729c',
      bloombergTicker: 'SLXX',
      ricSymbol: null,
      isin: 'IE00B00FV011',
      cusip: null,
      name: 'iShares Core £ Corp Bond UCITS ETF GBP (Dist)',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: '340a81d9-3d4d-471e-8ade-458507f46745',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.342Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.774Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: '340a81d9-3d4d-471e-8ade-458507f46745',
        iso4217Code: 'GBP',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '9a72e394-6e43-4268-8a28-2e1f70d03215',
          url: 'https://www.ishares.com/uk/individual/en/products/251839/?referrer=tickerSearch',
          instrumentId: 'cf6f5f7ce6d4c79b7063ec329629729c',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.344Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.344Z',
        },
      ],
    },
    {
      id: 'db5a6b3a6e7ac7af48f3c876692aaba4',
      bloombergTicker: 'CSPX',
      ricSymbol: null,
      isin: 'IE00B5BMR087',
      cusip: null,
      name: 'iShares Core S&P 500 UCITS ETF USD (Acc)',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.126Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.591Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '5d49866f-2615-4acc-9f6e-3c85ddee317e',
          url: 'https://www.ishares.com/uk/individual/en/products/253743/ishares-sp-500-b-ucits-etf-acc-fund?switchLocale=y&siteEntryPassthrough=true',
          instrumentId: 'db5a6b3a6e7ac7af48f3c876692aaba4',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.130Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.130Z',
        },
      ],
    },
    {
      id: 'db926068ed09df63fccd08686f7bfdac',
      bloombergTicker: 'MEUD',
      ricSymbol: null,
      isin: 'LU0908500753',
      cusip: null,
      name: 'Lyxor Core STOXX Europe 600 (DR) - UCITS ETF Acc',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.236Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.687Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
        iso4217Code: 'EUR',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: 'a3887202-3327-4314-97d6-2e9fdc717bc9',
          url: 'https://www.amundietf.lu/en/individual/products/equity/lyxor-core-stoxx-europe-600-dr-ucits-etf-acc/lu0908500753',
          instrumentId: 'db926068ed09df63fccd08686f7bfdac',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.238Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.238Z',
        },
      ],
    },
    {
      id: 'ed3a5e64a8f773dfe02e9a80fde133a1',
      bloombergTicker: 'IBTS',
      ricSymbol: null,
      isin: 'IE00B14X4S71',
      cusip: null,
      name: 'iShares $ Treasury Bd 1-3yr UCITS ETF USD Dist',
      instrumentAssetClassId: '41070e04-76cf-403d-8bd2-a820bb8282cf',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.189Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.648Z',
      InstrumentAssetClass: {
        id: '41070e04-76cf-403d-8bd2-a820bb8282cf',
        name: 'Bonds',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '7372ca28-61ab-4222-b1f6-c3002bbf3561',
          url: 'https://www.ishares.com/uk/individual/en/products/251715/?referrer=tickerSearch',
          instrumentId: 'ed3a5e64a8f773dfe02e9a80fde133a1',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.191Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.191Z',
        },
      ],
    },
    {
      id: 'f1c1fabb14e3ae4567166dc3fe768a0a',
      bloombergTicker: 'LSPU',
      ricSymbol: null,
      isin: 'LU0496786657',
      cusip: null,
      name: 'Lyxor S&P 500 UCITS ETF - Dist (USD)',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.198Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.657Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '12e59979-4136-4504-8136-16b28f903e65',
          url: 'https://www.amundietf.lu/en/individual/products/equity/lyxor-sp-500-ucits-etf-dist-usd/lu0496786657',
          instrumentId: 'f1c1fabb14e3ae4567166dc3fe768a0a',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.200Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.200Z',
        },
      ],
    },
    {
      id: 'f9dbc972a2a51593c942a59d46bf1e28',
      bloombergTicker: 'SMEA',
      ricSymbol: null,
      isin: 'IE00B4K48X80',
      cusip: null,
      name: 'iShares Core MSCI Europe UCITS ETF EUR (Acc)',
      instrumentAssetClassId: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.277Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.722Z',
      InstrumentAssetClass: {
        id: '92b69a16-4bcd-4e0f-891a-ab5d1a6d68a5',
        name: 'Equities',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a8e77ae5-d7bd-4249-9695-74d33d59babb',
        iso4217Code: 'EUR',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '617829bf-7b74-47ed-98a2-ec71d31f0776',
          url: 'https://www.ishares.com/uk/individual/en/products/251861/?referrer=tickerSearch',
          instrumentId: 'f9dbc972a2a51593c942a59d46bf1e28',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.279Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.279Z',
        },
      ],
    },
    {
      id: 'fedd71cfeeacb6be53c38c961c6f827f',
      bloombergTicker: 'IGLN',
      ricSymbol: null,
      isin: 'IE00B4ND3602',
      cusip: null,
      name: 'iShares Physical Gold ETC',
      instrumentAssetClassId: '22e36a70-eea7-4a43-b056-0b7676e37839',
      instrumentExchangeId: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
      instrumentCurrencyId: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
      createdBy: 'QUARK',
      createdAt: '2023-11-01T11:28:21.169Z',
      updatedBy: 'QUARK',
      updatedAt: '2023-11-02T03:52:06.631Z',
      InstrumentAssetClass: {
        id: '22e36a70-eea7-4a43-b056-0b7676e37839',
        name: 'Others',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.083Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.083Z',
      },
      InstrumentCurrency: {
        id: 'a21c907a-5fdf-46ad-87b4-157ee57389db',
        iso4217Code: 'USD',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:20.897Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:20.897Z',
      },
      InstrumentExchange: {
        id: 'cb6f6f09-ceca-4178-bffb-51d4ee583faa',
        bambuExchangeCode: 'XLON',
        createdBy: 'QUARK',
        createdAt: '2023-11-01T11:28:21.077Z',
        updatedBy: 'QUARK',
        updatedAt: '2023-11-01T11:28:21.077Z',
      },
      InstrumentFactSheets: [
        {
          id: '415591a9-31dc-46e3-bba2-38fa18180fc0',
          url: 'https://www.ishares.com/uk/individual/en/products/258441/',
          instrumentId: 'fedd71cfeeacb6be53c38c961c6f827f',
          createdBy: 'QUARK',
          createdAt: '2023-11-01T11:28:21.171Z',
          updatedBy: 'QUARK',
          updatedAt: '2023-11-01T11:28:21.171Z',
        },
      ],
    },
  ],
  pageCount: 1,
  filteredCount: 46,
  allTotalCount: 46,
};
