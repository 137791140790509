export const getModelPortfolioByIdMockRes = {
  connectPortfolioSummaryId: 'string',
  createdAt: '2023-11-06T17:55:51.357Z',
  createdBy: 'string',
  description: 'string',
  expectedAnnualReturn: 0,
  expectedAnnualVolatility: 0,
  factSheetUrl: 'string',
  id: 'string',
  name: 'string',
  partnerModelId: 'string',
  rebalancingThreshold: 0,
  updatedAt: '2023-11-06T17:55:51.357Z',
  updatedBy: 'string',
  TransactModelPortfolioInstruments: [
    {
      instrumentId: 'string',
      transactModelPortfolioId: 'string',
      weightage: 0,
      createdAt: '2023-11-06T17:55:51.357Z',
      createdBy: 'string',
      id: 'string',
      updatedAt: '2023-11-06T17:55:51.357Z',
      updatedBy: 'string',
      Instrument: {
        InstrumentAssetClass: {
          createdAt: '2023-11-06T17:55:51.357Z',
          createdBy: 'string',
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          name: 'string',
          updatedAt: '2023-11-06T17:55:51.357Z',
          updatedBy: 'string',
        },
        InstrumentCurrency: {
          createdAt: '2023-11-06T17:55:51.357Z',
          createdBy: 'string',
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          iso4217Code: 'string',
          updatedAt: '2023-11-06T17:55:51.357Z',
          updatedBy: 'string',
        },
        InstrumentExchange: {
          bambuExchangeCode: 'string',
          createdAt: '2023-11-06T17:55:51.357Z',
          createdBy: 'string',
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          updatedAt: '2023-11-06T17:55:51.357Z',
          updatedBy: 'string',
        },
        InstrumentFactSheet: [
          {
            createdAt: '2023-11-06T17:55:51.357Z',
            createdBy: 'string',
            id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            instrumentId: 'string',
            updatedAt: '2023-11-06T17:55:51.357Z',
            updatedBy: 'string',
            url: 'string',
          },
        ],
        bloombergTicker: null,
        createdAt: '2023-11-06T17:55:51.357Z',
        createdBy: 'string',
        cusip: null,
        id: 'string',
        instrumentAssetClassId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        instrumentCurrencyId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        instrumentExchangeId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        isin: null,
        name: 'string',
        ricSymbol: null,
        updatedAt: '2023-11-06T17:55:51.357Z',
        updatedBy: 'string',
      },
    },
  ],
};
