import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export type OtherGoalNameIconProps = SvgIconProps;

export function LoadingErrorIcon(props: OtherGoalNameIconProps) {
  return (
    <SvgIcon viewBox="0 0 61 60" {...props}>
      <g clipPath="url(#clip0_12555_26049)">
        <path
          d="M43.625 26.25C34.3212 26.25 26.75 33.8212 26.75 43.125C26.75 52.4287 34.3212 60 43.625 60C52.9287 60 60.5 52.4287 60.5 43.125C60.5 33.8212 52.9287 26.25 43.625 26.25ZM50.5775 50.0775C50.21 50.4412 49.73 50.625 49.25 50.625C48.77 50.625 48.29 50.4412 47.9225 50.0775L42.2975 44.4525C41.9487 44.1 41.75 43.6238 41.75 43.125V35.625C41.75 34.59 42.59 33.75 43.625 33.75C44.66 33.75 45.5 34.59 45.5 35.625V42.3488L50.5775 47.4263C51.3088 48.1575 51.3088 49.3425 50.5775 50.0775Z"
          fill="#BA1A1A"
        />
        <path
          d="M24.4062 35.6963C24.2675 35.5388 24.155 35.3588 24.005 35.2088L19.25 30.4575L14.495 35.2125C12.725 36.9788 11.75 39.33 11.75 41.8388V45.0038H23.0938C23.0375 44.385 23 43.7588 23 43.125C23 40.5038 23.51 38.0063 24.4062 35.6963Z"
          fill="#0013A0"
        />
        <path
          d="M12.75 10.6612V8.5H25.75V10.6612C25.75 12.9013 24.8802 15.0018 23.2979 16.5841L19.25 20.632L15.2029 16.5849C15.2027 16.5848 15.2026 16.5847 15.2025 16.5846C13.6194 14.998 12.75 12.897 12.75 10.6612Z"
          stroke="#0013A0"
          strokeWidth="2"
        />
        <path
          d="M36.125 0H2.375C1.34 0 0.5 0.84375 0.5 1.875C0.5 2.91375 1.34 3.75 2.375 3.75H4.25V10.6613C4.25 15.1687 6.005 19.4062 9.1925 22.5938L12.8488 26.25L9.1925 29.9062C6.005 33.0975 4.25 37.3312 4.25 41.8387V48.75H2.375C1.34 48.75 0.5 49.59 0.5 50.625C0.5 51.66 1.34 52.5 2.375 52.5L25.2762 52.4963C24.665 51.3075 24.17 50.055 23.7987 48.7463L8 48.75V41.8387C8 38.3325 9.365 35.04 11.8438 32.5575L16.8275 27.5775C17.1763 27.225 17.375 26.7488 17.375 26.25C17.375 25.755 17.1763 25.275 16.8275 24.9225L11.8438 19.9425C9.365 17.4637 8 14.1675 8 10.6613V3.75H30.5V10.6613C30.5 14.1675 29.135 17.46 26.6562 19.9425L21.6725 24.9262C21.3237 25.2787 21.125 25.755 21.125 26.25C21.125 26.7488 21.3237 27.225 21.6725 27.5775L26.2137 32.115C26.8925 31.0463 27.665 30.0487 28.5238 29.1225L25.6512 26.25L29.3075 22.59C32.495 19.4062 34.25 15.1687 34.25 10.6613V3.75H36.125C37.16 3.75 38 2.91 38 1.875C38 0.84 37.16 0 36.125 0Z"
          fill="#0013A0"
        />
      </g>
      <defs>
        <clipPath id="clip0_12555_26049">
          <rect
            width="60"
            height="60"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default LoadingErrorIcon;
