import { SharedEnums } from '@bambu/shared';
import { type ConnectAdvisorGetLeadsResponseDto } from './../Leads';

//!! LEADS_STATUS_TYPES(SharedEnums.LeadsEnums.EnumLeadStatus) causes createDefaultAxiosInterceptorsRequestConfig.spec.ts to fail, reason unknown

export const leadsSummaryByIdResponse = [
  {
    displayName: 'User Details',
    key: 'USER_DETAILS',
    fields: [
      {
        displayName: 'Name',
        key: 'NAME',
        type: SharedEnums.EnumGenericDataSummaryFieldType.STRING,
        value: 'Augustina Asplin',
      },
      {
        displayName: 'Status',
        key: 'STATUS',
        type: SharedEnums.EnumGenericDataSummaryFieldType.STRING,
        value: 'NEW',
      },
    ],
  },
  {
    displayName: 'Contact Details',
    key: 'CONTACT_DETAILS',
    fields: [
      {
        displayName: 'Email',
        key: 'EMAIL',
        type: SharedEnums.EnumGenericDataSummaryFieldType.STRING,
        value: 'aasplin8@google.co.uk',
      },
      {
        displayName: 'Phone Number',
        key: 'PHONE_NUMBER',
        type: SharedEnums.EnumGenericDataSummaryFieldType.STRING,
        value: '225-205-4395',
      },
    ],
  },
  {
    displayName: 'Personal Details',
    key: 'PERSONAL_DETAILS',
    fields: [
      {
        displayName: 'Zip Code',
        key: 'ZIP_CODE',
        type: SharedEnums.EnumGenericDataSummaryFieldType.STRING,
        value: '70820',
      },
      {
        displayName: 'Age',
        key: 'AGE',
        type: SharedEnums.EnumGenericDataSummaryFieldType.STRING,
        value: '39',
      },
      {
        displayName: 'Investment Style',
        key: 'INVESTMENT_STYLE',
        type: SharedEnums.EnumGenericDataSummaryFieldType.STRING,
        value: 'BALANCED',
      },
      {
        displayName: 'Retirement Savings',
        key: 'RETIREMENT_SAVINGS',
        type: SharedEnums.EnumGenericDataSummaryFieldType.STRING,
        value: '4211202',
      },
    ],
  },
  {
    displayName: 'Goal Details',
    key: 'GOAL_DETAILS',
    fields: [
      {
        displayName: 'Type',
        key: 'GOAL_TYPE',
        type: SharedEnums.EnumGenericDataSummaryFieldType.STRING,
        value: 'Education',
      },
      {
        displayName: 'Target Year',
        key: 'TARGET_YEAR',
        type: SharedEnums.EnumGenericDataSummaryFieldType.STRING,
        value: '17.8',
      },
      {
        displayName: 'Current Monthly Expenses',
        key: 'MONTHLY_EXPENSES',
        type: SharedEnums.EnumGenericDataSummaryFieldType.STRING,
        value: '2789',
      },
      {
        displayName: 'Target Goal',
        key: 'TARGET_GOAL',
        type: SharedEnums.EnumGenericDataSummaryFieldType.STRING,
        value: '8700',
      },
      {
        displayName: 'Suggested Contribution',
        key: 'SUGGESTED_CONTRIBUTION',
        type: SharedEnums.EnumGenericDataSummaryFieldType.STRING,
        value: '2789',
      },
      {
        displayName: 'Recommended Portfolio',
        key: 'RECOMMENDED_PORTFOLIO',
        type: SharedEnums.EnumGenericDataSummaryFieldType.STRING,
        value: 'Balanced Portfolio',
      },
    ],
  },
];

export const advisorLeadsMockResponse = {
  data: [
    {
      monthlySavings: 1000,
      id: '35',
      tenantId: 'bae78a11-91a6-4e16-a13e-271b67c6697b',
      name: 'Dionne Towns',
      email: 'dtownsy@slashdot.org',
      phoneNumber: '279-723-7361',
      zipCode: '216134',
      age: 44,
      incomePerAnnum: 249723,
      currentSavings: 154466,
      isRetired: true,
      goalDescription: "XXX's Education",
      goalName: 'Other',
      goalValue: 9061,
      goalTimeframe: 9.7,
      riskAppetite: 'bc8af3f9-fa5f-4253-8274-2c6fc2da712c',
      notes:
        'nullam porttitor lacus at turpis donec posuere metus vitae ipsum aliquam non mauris morbi non lectus aliquam sit',
      initialInvestment: 4083,
      monthlyContribution: 3542,
      projectedReturns: {
        low: 967,
        high: 9394967,
        target: 967,
      },
      sendAppointmentEmail: false,
      sendGoalProjectionEmail: false,
      status: SharedEnums.LeadsEnums.EnumLeadStatus.NEW,
      createdBy: 'Towns',
      createdAt: '2022-05-11T00:00:00.000Z',
      updatedBy: 'Collymore',
      updatedAt: '2023-03-19T00:00:00.000Z',
    },
    {
      monthlySavings: 1000,
      id: '16',
      tenantId: 'bae78a11-91a6-4e16-a13e-271b67c6697b',
      name: 'Robbie Croux',
      email: 'rcrouxf@theguardian.com',
      phoneNumber: '390-452-2316',
      zipCode: '503068',
      age: 45,
      incomePerAnnum: 275320,
      currentSavings: 97229,
      isRetired: false,
      goalDescription: 'Buy a Tesla',
      goalName: 'Retirement',
      goalValue: 5009,
      goalTimeframe: 3.9,
      riskAppetite: 'bc8af3f9-fa5f-4253-8274-2c6fc2da712c',
      notes:
        'maecenas pulvinar lobortis est phasellus sit amet erat nulla tempus vivamus in felis eu sapien cursus vestibulum proin',
      initialInvestment: 797,
      monthlyContribution: 3227,
      projectedReturns: {
        low: 967,
        high: 9394967,
        target: 967,
      },
      sendAppointmentEmail: false,
      sendGoalProjectionEmail: false,
      status: SharedEnums.LeadsEnums.EnumLeadStatus.NEW,
      createdBy: 'Croux',
      createdAt: '2023-01-08T00:00:00.000Z',
      updatedBy: 'Birts',
      updatedAt: '2023-03-16T00:00:00.000Z',
    },
    {
      monthlySavings: 1000,
      id: '4',
      tenantId: 'bae78a11-91a6-4e16-a13e-271b67c6697b',
      name: 'Mariya Poolton',
      email: 'mpoolton3@techcrunch.com',
      phoneNumber: '650-612-6661',
      zipCode: '216134',
      age: 63,
      incomePerAnnum: 460642,
      currentSavings: 129422,
      isRetired: true,
      goalDescription: "XXX's Education",
      goalName: 'Other',
      goalValue: 6070,
      goalTimeframe: 15.9,
      riskAppetite: '62c52cdc-a752-45e8-9eea-d38a7fca1bb5',
      notes:
        'non velit nec nisi vulputate nonummy maecenas tincidunt lacus at velit vivamus vel nulla eget eros elementum pellentesque quisque',
      initialInvestment: 2247,
      monthlyContribution: 2515,
      projectedReturns: {
        low: 967,
        high: 9394967,
        target: 967,
      },
      sendAppointmentEmail: true,
      sendGoalProjectionEmail: true,
      status: SharedEnums.LeadsEnums.EnumLeadStatus.NEW,
      createdBy: 'Poolton',
      createdAt: '2022-07-29T00:00:00.000Z',
      updatedBy: 'Yukhin',
      updatedAt: '2023-03-15T00:00:00.000Z',
    },
    {
      monthlySavings: 1000,
      id: '84',
      tenantId: 'bae78a11-91a6-4e16-a13e-271b67c6697b',
      name: 'Wallis Meynell',
      email: 'wmeynell2b@seesaa.net',
      phoneNumber: '328-141-0927',
      zipCode: '216134',
      age: 31,
      incomePerAnnum: 270982,
      currentSavings: 38370,
      isRetired: false,
      goalDescription: 'Grow My Wealth',
      goalName: 'Other',
      goalValue: 3597,
      goalTimeframe: 11.4,
      riskAppetite: 'c532fbc1-404b-4b23-bbe0-aa46f56ad1b9',
      notes:
        'ac tellus semper interdum mauris ullamcorper purus sit amet nulla quisque arcu libero rutrum ac lobortis vel',
      initialInvestment: 4913,
      monthlyContribution: 3148,
      projectedReturns: {
        low: 967,
        high: 9394967,
        target: 967,
      },
      sendAppointmentEmail: false,
      sendGoalProjectionEmail: false,
      status: SharedEnums.LeadsEnums.EnumLeadStatus.NEW,
      createdBy: 'Meynell',
      createdAt: '2023-02-06T00:00:00.000Z',
      updatedBy: 'Langdridge',
      updatedAt: '2023-03-08T00:00:00.000Z',
    },
    {
      monthlySavings: 1000,
      id: '33',
      tenantId: 'bae78a11-91a6-4e16-a13e-271b67c6697b',
      name: 'Ivor Scalera',
      email: 'iscaleraw@reverbnation.com',
      phoneNumber: '620-148-5745',
      zipCode: '216134',
      age: 71,
      incomePerAnnum: 381238,
      currentSavings: 424388,
      isRetired: false,
      goalDescription: 'My Retirement',
      goalName: 'House',
      goalValue: 9091,
      goalTimeframe: 15.9,
      riskAppetite: '62c52cdc-a752-45e8-9eea-d38a7fca1bb5',
      notes:
        'sit amet lobortis sapien sapien non mi integer ac neque duis bibendum morbi non quam nec dui',
      initialInvestment: 2376,
      monthlyContribution: 3020,
      projectedReturns: {
        low: 967,
        high: 9394967,
        target: 967,
      },
      sendAppointmentEmail: false,
      sendGoalProjectionEmail: false,
      status: SharedEnums.LeadsEnums.EnumLeadStatus.NEW,
      createdBy: 'Scalera',
      createdAt: '2022-12-29T00:00:00.000Z',
      updatedBy: 'Godspeede',
      updatedAt: '2023-03-05T00:00:00.000Z',
    },
    {
      monthlySavings: 1000,
      id: '73',
      tenantId: 'bae78a11-91a6-4e16-a13e-271b67c6697b',
      name: 'Caren Belitz',
      email: 'cbelitz20@a8.net',
      phoneNumber: '426-595-4401',
      zipCode: '7750-124',
      age: 61,
      incomePerAnnum: 187446,
      currentSavings: 186871,
      isRetired: true,
      goalDescription: 'My Retirement',
      goalName: 'Other',
      goalValue: 4286,
      goalTimeframe: 10.3,
      riskAppetite: '62c52cdc-a752-45e8-9eea-d38a7fca1bb5',
      notes:
        'fermentum donec ut mauris eget massa tempor convallis nulla neque libero convallis eget eleifend luctus',
      initialInvestment: 2632,
      monthlyContribution: 1890,
      projectedReturns: {
        low: 967,
        high: 9394967,
        target: 967,
      },
      sendAppointmentEmail: false,
      sendGoalProjectionEmail: false,
      status: SharedEnums.LeadsEnums.EnumLeadStatus.NEW,
      createdBy: 'Belitz',
      createdAt: '2022-07-16T00:00:00.000Z',
      updatedBy: 'Height',
      updatedAt: '2023-03-03T00:00:00.000Z',
    },
    {
      monthlySavings: 1000,
      id: '67',
      tenantId: 'bae78a11-91a6-4e16-a13e-271b67c6697b',
      name: 'Thedric Quernel',
      email: 'tquernel1u@devhub.com',
      phoneNumber: '341-269-0175',
      zipCode: '216134',
      age: 29,
      incomePerAnnum: 398032,
      currentSavings: 389109,
      isRetired: false,
      goalDescription: 'Dream Holiday',
      goalName: 'Retirement',
      goalValue: 5430,
      goalTimeframe: 13.9,
      riskAppetite: 'bc8af3f9-fa5f-4253-8274-2c6fc2da712c',
      notes:
        'turpis elementum ligula vehicula consequat morbi a ipsum integer a nibh in quis justo maecenas rhoncus aliquam lacus',
      initialInvestment: 4025,
      monthlyContribution: 1094,
      projectedReturns: {
        low: 967,
        high: 9394967,
        target: 967,
      },
      sendAppointmentEmail: true,
      sendGoalProjectionEmail: true,
      status: SharedEnums.LeadsEnums.EnumLeadStatus.NEW,
      createdBy: 'Quernel',
      createdAt: '2022-09-22T00:00:00.000Z',
      updatedBy: 'Careswell',
      updatedAt: '2023-02-27T00:00:00.000Z',
    },
    {
      monthlySavings: 1000,
      id: '87',
      tenantId: 'bae78a11-91a6-4e16-a13e-271b67c6697b',
      name: 'Iain Rapaport',
      email: 'irapaport2e@wsj.com',
      phoneNumber: '793-685-8909',
      zipCode: '478008',
      age: 58,
      incomePerAnnum: 238194,
      currentSavings: 214731,
      isRetired: true,
      goalDescription: 'Dream Holiday',
      goalName: 'Growing Wealth',
      goalValue: 8847,
      goalTimeframe: 11.5,
      riskAppetite: 'c532fbc1-404b-4b23-bbe0-aa46f56ad1b9',
      notes:
        'praesent blandit nam nulla integer pede justo lacinia eget tincidunt eget tempus vel pede morbi porttitor lorem id ligula',
      initialInvestment: 4339,
      monthlyContribution: 3299,
      projectedReturns: {
        low: 967,
        high: 9394967,
        target: 967,
      },
      sendAppointmentEmail: true,
      sendGoalProjectionEmail: true,
      status: SharedEnums.LeadsEnums.EnumLeadStatus.NEW,
      createdBy: 'Rapaport',
      createdAt: '2023-04-18T00:00:00.000Z',
      updatedBy: 'Marron',
      updatedAt: '2023-02-23T00:00:00.000Z',
    },
    {
      monthlySavings: 1000,
      id: '30',
      tenantId: 'bae78a11-91a6-4e16-a13e-271b67c6697b',
      name: 'Orly Dunnett',
      email: 'odunnettt@bloglines.com',
      phoneNumber: '380-498-4050',
      zipCode: '216134',
      age: 40,
      incomePerAnnum: 244567,
      currentSavings: 84266,
      isRetired: false,
      goalDescription: 'Dream Holiday',
      goalName: 'Growing Wealth',
      goalValue: 5539,
      goalTimeframe: 14,
      riskAppetite: '62c52cdc-a752-45e8-9eea-d38a7fca1bb5',
      notes:
        'penatibus et magnis dis parturient montes nascetur ridiculus mus vivamus vestibulum sagittis sapien',
      initialInvestment: 4150,
      monthlyContribution: 2450,
      projectedReturns: {
        low: 967,
        high: 9394967,
        target: 967,
      },
      sendAppointmentEmail: true,
      sendGoalProjectionEmail: true,
      status: SharedEnums.LeadsEnums.EnumLeadStatus.NEW,
      createdBy: 'Dunnett',
      createdAt: '2022-08-02T00:00:00.000Z',
      updatedBy: 'Avarne',
      updatedAt: '2023-02-16T00:00:00.000Z',
    },
    {
      monthlySavings: 1000,
      id: '18',
      tenantId: 'bae78a11-91a6-4e16-a13e-271b67c6697b',
      name: 'Andromache Clappison',
      email: 'aclappisonh@hud.gov',
      phoneNumber: '965-962-5275',
      zipCode: '216134',
      age: 61,
      incomePerAnnum: 140898,
      currentSavings: 285086,
      isRetired: false,
      goalDescription: 'Buy a Tesla',
      goalName: 'Other',
      goalValue: 2863,
      goalTimeframe: 9.2,
      riskAppetite: 'b0b2cf1a-db34-493e-b23b-e5f9db5112be',
      notes:
        'leo pellentesque ultrices mattis odio donec vitae nisi nam ultrices libero non mattis pulvinar nulla pede ullamcorper augue a',
      initialInvestment: 3047,
      monthlyContribution: 2726,
      projectedReturns: {
        low: 967,
        high: 9394967,
        target: 967,
      },
      sendAppointmentEmail: true,
      sendGoalProjectionEmail: true,
      status: SharedEnums.LeadsEnums.EnumLeadStatus.NEW,
      createdBy: 'Clappison',
      createdAt: '2023-03-10T00:00:00.000Z',
      updatedBy: 'Barcroft',
      updatedAt: '2023-02-04T00:00:00.000Z',
    },
  ],
  pageCount: 10,
  filteredCount: 100,
  allTotalCount: 100,
  qualifiedTotalCount: 71,
  transactTotalCount: 29,
} satisfies ConnectAdvisorGetLeadsResponseDto;
