import { SvgIcon } from '@bambu/react-ui';
import type { SvgIconProps } from '@bambu/react-ui';

export type OtherGoalAmountIconProps = SvgIconProps;

export function OtherGoalAmountIcon(props: OtherGoalAmountIconProps) {
  return (
    <SvgIcon viewBox="0 0 44 44" {...props}>
      <g clipPath="url(#clip0_1875_59758)">
        <path d="M33.88 15.9497L31.2033 18.6263C32.34 20.7163 33 23.118 33 25.6663C33 33.7513 26.4183 40.333 18.3333 40.333C10.2483 40.333 3.66667 33.7513 3.66667 25.6663C3.66667 17.5813 10.2483 10.9997 18.3333 10.9997C20.8817 10.9997 23.2833 11.6597 25.3733 12.7963L28.05 10.1197C25.245 8.35967 21.9083 7.33301 18.3333 7.33301C8.23167 7.33301 0 15.5647 0 25.6663C0 35.768 8.23167 43.9997 18.3333 43.9997C28.435 43.9997 36.6667 35.768 36.6667 25.6663C36.6667 22.0913 35.64 18.7547 33.88 15.9497Z" />
        <path d="M29.1503 20.68L26.2903 23.54C26.492 24.2183 26.5837 24.9333 26.5837 25.6667C26.5837 30.2133 22.8803 33.9167 18.3337 33.9167C13.787 33.9167 10.0837 30.2133 10.0837 25.6667C10.0837 21.12 13.787 17.4167 18.3337 17.4167C19.067 17.4167 19.782 17.5083 20.4603 17.71L23.3203 14.85C21.7987 14.135 20.112 13.75 18.3337 13.75C11.7703 13.75 6.41699 19.1033 6.41699 25.6667C6.41699 32.23 11.7703 37.5833 18.3337 37.5833C24.897 37.5833 30.2503 32.23 30.2503 25.6667C30.2503 23.8883 29.8653 22.2017 29.1503 20.68Z" />
        <path d="M18.333 31.167C15.3007 31.167 12.833 28.6993 12.833 25.667C12.833 22.6347 15.3007 20.167 18.333 20.167C21.3653 20.167 23.833 22.6347 23.833 25.667C23.833 28.6993 21.3653 31.167 18.333 31.167ZM18.333 23.8337C17.3228 23.8337 16.4997 24.6568 16.4997 25.667C16.4997 26.6772 17.3228 27.5003 18.333 27.5003C19.3432 27.5003 20.1663 26.6772 20.1663 25.667C20.1663 24.6568 19.3432 23.8337 18.333 23.8337Z" />
        <path d="M18.3335 27.0414C17.9815 27.0414 17.6295 26.9076 17.3619 26.6381C16.8247 26.1009 16.8247 25.2301 17.3619 24.6929L31.1119 10.9429C31.649 10.4057 32.5199 10.4057 33.057 10.9429C33.5942 11.4801 33.5942 12.3509 33.057 12.8881L19.307 26.6381C19.0375 26.9076 18.6855 27.0414 18.3335 27.0414Z" />
        <path d="M37.1253 12.8336H32.542C31.783 12.8336 31.167 12.2176 31.167 11.4586V6.8753C31.167 6.51047 31.3118 6.1603 31.5703 5.90364L37.0703 0.403636C37.4645 0.00946913 38.0567 -0.106031 38.5682 0.104802C39.0815 0.317469 39.417 0.819803 39.417 1.3753V4.58364H42.6253C43.1808 4.58364 43.6832 4.91914 43.8958 5.43247C44.1085 5.9458 43.9912 6.53797 43.597 6.9303L38.097 12.4303C37.8403 12.6888 37.4902 12.8336 37.1253 12.8336Z" />
      </g>
      <defs>
        <clipPath id="clip0_1875_59758">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default OtherGoalAmountIcon;
