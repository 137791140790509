import { styled, useTheme } from '@bambu/react-ui';

const StyledSvg = styled('svg')(({ theme }) => ({}));

export function Illustration() {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <StyledSvg
      width="189"
      height="140"
      viewBox="0 0 189 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10797_49758)">
        <path
          d="M188.208 123.032H0.789062V123.126H188.208V123.032Z"
          fill="#EBEBEB"
        />
        <path
          d="M169.43 129.078H157.016V129.172H169.43V129.078Z"
          fill="#EBEBEB"
        />
        <path
          d="M152.292 129.078H125.75V129.172H152.292V129.078Z"
          fill="#EBEBEB"
        />
        <path
          d="M111.115 125.591H103.922V125.685H111.115V125.591Z"
          fill="#EBEBEB"
        />
        <path
          d="M33.3063 126.222H20.4531V126.316H33.3063V126.222Z"
          fill="#EBEBEB"
        />
        <path
          d="M60.0118 126.222H36.6406V126.316H60.0118V126.222Z"
          fill="#EBEBEB"
        />
        <path
          d="M85.1806 127.808H71.3828V127.902H85.1806V127.808Z"
          fill="#EBEBEB"
        />
        <path
          d="M89.6311 106.275H17.2497C16.069 106.275 15.1094 105.313 15.1094 104.129V2.14541C15.1094 0.961864 16.069 0 17.2497 0H89.6311C90.8118 0 91.7714 0.961864 91.7714 2.14541V104.129C91.7714 105.313 90.8118 106.275 89.6311 106.275ZM17.2497 0.093932C16.1214 0.093932 15.2031 1.01447 15.2031 2.14541V104.129C15.2031 105.26 16.1214 106.181 17.2497 106.181H89.6311C90.7593 106.181 91.6777 105.26 91.6777 104.129V2.14541C91.6777 1.01447 90.7593 0.093932 89.6311 0.093932H17.2497Z"
          fill="#EBEBEB"
        />
        <path
          d="M170.709 106.275H98.3278C97.1471 106.275 96.1875 105.313 96.1875 104.129V2.14541C96.1875 0.961864 97.1471 0 98.3278 0H170.709C171.89 0 172.85 0.961864 172.85 2.14541V104.129C172.85 105.313 171.89 106.275 170.709 106.275ZM98.3278 0.093932C97.1996 0.093932 96.2812 1.01447 96.2812 2.14541V104.129C96.2812 105.26 97.1996 106.181 98.3278 106.181H170.709C171.837 106.181 172.756 105.26 172.756 104.129V2.14541C172.756 1.01447 171.837 0.093932 170.709 0.093932H98.3278Z"
          fill="#EBEBEB"
        />
        <path
          d="M54.0309 59.2788L136.664 59.2788V5.47831L54.0309 5.47831V59.2788Z"
          fill="#E6E6E6"
        />
        <path
          d="M52.3318 59.2788L135.617 59.2788V5.47831L52.3318 5.47831V59.2788Z"
          fill="#F0F0F0"
        />
        <path
          d="M133.906 56.3593V8.40137L54.0619 8.40137V56.3593L133.906 56.3593Z"
          fill="#FAFAFA"
        />
        <path
          d="M124.099 56.3593L114.001 8.40137H100.766L110.864 56.3593H124.099Z"
          fill="white"
        />
        <path
          d="M108.717 56.3593L98.6193 8.40137H92.6406L102.739 56.3593H108.717Z"
          fill="white"
        />
        <path
          d="M84.9963 56.3593L74.8981 8.40137H64.6875L74.7857 56.3593H84.9963Z"
          fill="white"
        />
        <path
          d="M54.2734 56.3593V8.40137H54.056V56.3593H54.2734Z"
          fill="#E6E6E6"
        />
        <path
          d="M91.4903 59.2788H96.4531V5.47831H91.4903V59.2788Z"
          fill="#F0F0F0"
        />
        <path
          d="M96.6719 56.3593V8.40137H96.4545V56.3593H96.6719Z"
          fill="#E6E6E6"
        />
        <path
          d="M93.8901 59.2788H94.0625V5.47831H93.8901V59.2788Z"
          fill="#E6E6E6"
        />
        <path
          d="M152.943 120.533H168V81.1569H152.943V120.533Z"
          fill="#F0F0F0"
        />
        <path
          d="M90.8538 123.028L165.109 123.028V120.533L90.8538 120.533V123.028Z"
          fill="#F0F0F0"
        />
        <path
          d="M152.947 81.1611H87.9688V120.537H152.947V81.1611Z"
          fill="#F5F5F5"
        />
        <path
          d="M147.032 86.0078H93.8906V98.9216H147.032V86.0078Z"
          fill="#F0F0F0"
        />
        <path
          d="M147.032 102.776H93.8906V115.69H147.032V102.776Z"
          fill="#F0F0F0"
        />
        <path
          d="M113.74 93.3496H127.171C127.568 93.3496 127.891 93.0266 127.891 92.6282V92.3013C127.891 91.9029 127.568 91.5799 127.171 91.5799H113.74C113.343 91.5799 113.021 91.9029 113.021 92.3013V92.6282C113.021 93.0266 113.343 93.3496 113.74 93.3496Z"
          fill="#F5F5F5"
        />
        <path
          d="M113.74 110.119H127.171C127.568 110.119 127.891 109.796 127.891 109.397V109.07C127.891 108.672 127.568 108.349 127.171 108.349H113.74C113.343 108.349 113.021 108.672 113.021 109.07V109.397C113.021 109.796 113.343 110.119 113.74 110.119Z"
          fill="#F5F5F5"
        />
        <path
          d="M117.127 74.5293C117.127 74.5293 116.947 81.1571 110.009 81.1571C103.071 81.1571 102.891 74.5293 102.891 74.5293H117.131H117.127Z"
          fill="#F0F0F0"
        />
        <path
          d="M133.361 74.5293C133.361 74.5293 133.181 81.1571 126.243 81.1571C119.305 81.1571 119.125 74.5293 119.125 74.5293H133.365H133.361Z"
          fill="#F0F0F0"
        />
        <path
          d="M117.127 70.1372C117.127 70.1372 116.947 76.765 110.009 76.765C103.071 76.765 102.891 70.1372 102.891 70.1372H117.131H117.127Z"
          fill="#F0F0F0"
        />
        <path
          d="M133.361 70.1372C133.361 70.1372 133.181 76.765 126.243 76.765C119.305 76.765 119.125 70.1372 119.125 70.1372H133.365H133.361Z"
          fill="#F0F0F0"
        />
        <path
          d="M149.596 74.5293C149.596 74.5293 149.416 81.1571 142.478 81.1571C135.539 81.1571 135.359 74.5293 135.359 74.5293H149.599H149.596Z"
          fill="#F0F0F0"
        />
        <path
          d="M149.596 70.1372C149.596 70.1372 149.416 76.765 142.478 76.765C135.539 76.765 135.359 70.1372 135.359 70.1372H149.599H149.596Z"
          fill="#F0F0F0"
        />
        <path
          d="M145.226 44.9336H154.68V27.0677H145.226V44.9336Z"
          fill="#E6E6E6"
        />
        <path
          d="M143.108 44.9336H154.211V27.0677H143.108V44.9336Z"
          fill="#F5F5F5"
        />
        <path
          d="M152.523 43.3477V28.6567H144.802V43.3477H152.523Z"
          fill="white"
        />
        <path
          d="M159.539 56.4795H168.992V38.6136H159.539V56.4795Z"
          fill="#E6E6E6"
        />
        <path
          d="M157.421 56.4795H168.523V38.6136H157.421V56.4795Z"
          fill="#F5F5F5"
        />
        <path
          d="M166.836 54.8902V40.1992H159.114V54.8902H166.836Z"
          fill="white"
        />
        <path
          d="M28.0577 43.6934H41.5781V18.1439H28.0577L28.0577 43.6934Z"
          fill="#E6E6E6"
        />
        <path
          d="M25.0321 43.6934H40.9141V18.1439L25.0321 18.1439L25.0321 43.6934Z"
          fill="#F5F5F5"
        />
        <path d="M38.5 41.4277V20.417H27.4535V41.4277H38.5Z" fill="white" />
        <path
          d="M22.7949 88.5477C21.0069 83.2875 15.5568 81.33 13.9749 78.0085C11.9021 73.6576 12.6592 67.1312 15.1144 64.7754C17.2473 62.7277 21.9665 76.5695 22.4988 78.1175C24.3317 83.4378 24.5904 87.3829 24.6129 91.2078C24.9315 87.9578 25.2201 84.9745 25.4412 82.6713C26.1609 75.1605 26.2696 72.7446 26.2809 72.0006C26.0597 71.6362 25.7973 71.2154 25.6437 70.9862C24.3392 69.0662 21.2206 66.7855 19.8487 63.9939C17.847 59.921 18.7991 55.0478 20.7333 53.233C22.7574 51.3356 25.445 55.8218 26.6332 60.2629C27.8215 64.704 26.6145 68.9572 26.6707 72.6506C26.6895 73.3758 26.4758 76.1224 26.1384 79.936C27.1205 75.8331 28.4587 73.0827 30.6515 70.524C33.3466 67.3754 35.8018 63.5392 37.6722 64.0314C39.5427 64.5274 37.9271 69.6035 36.0005 73.1654C34.2837 76.3403 27.2292 79.5039 25.951 82.0551C25.4787 87.2251 24.8378 93.7703 24.2193 99.8684C25.1039 96.7198 24.774 96.453 27.4841 91.6324C29.0959 88.7656 34.7672 85.23 35.1721 87.2026C35.5694 89.1338 34.1975 92.8949 31.911 95.5024C30.0668 97.6065 26.5058 97.6027 25.535 98.9065C25.3063 99.2108 24.5379 100.379 24.0993 101.048C23.3384 108.491 22.63 115.063 22.3338 117.302L21.9852 117.325C22.0677 116.746 22.4575 112.959 22.9598 107.95C22.3976 105.283 16.7525 103.727 15.2719 100.954C13.72 98.0536 12.753 94.6683 13.8175 92.3989C14.7059 90.509 19.7175 93.079 21.5167 96.2125C22.7124 98.2978 23.3347 100.526 23.4059 103.517C23.7432 100.105 24.1068 96.438 24.4442 92.9738C24.1068 92.1096 23.3384 90.122 22.8024 88.5439L22.7949 88.5477Z"
          fill="#E6E6E6"
        />
        <path
          d="M28.0902 123.032H16.8901C16.2791 123.032 15.7505 122.315 15.6306 121.319L14.8359 114.804H30.1518L29.3572 121.319C29.2372 122.315 28.7087 123.032 28.0977 123.032H28.0902Z"
          fill="#E6E6E6"
        />
        <path
          d="M30.471 115.288H14.5141C14.1318 115.288 13.8094 115.078 13.7682 114.796L13.4421 112.666C13.3933 112.339 13.7419 112.049 14.188 112.049H30.7971C31.2469 112.049 31.5955 112.335 31.543 112.666L31.2169 114.796C31.1757 115.074 30.8533 115.288 30.471 115.288Z"
          fill="#F0F0F0"
        />
        <path
          d="M94.4978 140C134.636 140 167.175 138.096 167.175 135.747C167.175 133.398 134.636 131.494 94.4978 131.494C54.3591 131.494 21.8203 133.398 21.8203 135.747C21.8203 138.096 54.3591 140 94.4978 140Z"
          fill="#F5F5F5"
        />
        <path
          d="M84.5094 29.5771L91.7288 38.2263C93.0407 39.7969 95.0274 40.6423 97.0702 40.492L129.138 38.1249C134.119 37.7567 138.456 41.5064 138.823 46.4999L143.183 105.869C143.55 110.862 139.809 115.209 134.828 115.577L45.916 122.141C40.9344 122.51 36.5975 118.76 36.2301 113.766L30.6113 37.287C30.3527 33.7777 32.9803 30.7268 36.4813 30.4676L79.1642 27.319C81.2033 27.1687 83.1899 28.0103 84.5056 29.5846L84.5094 29.5771Z"
          fill={main}
        />
        <path
          d="M37.9558 34.7285L68.3965 32.4824C69.0844 32.4316 69.6824 32.9488 69.733 33.6383L69.7358 33.6758C69.7864 34.3653 69.2704 34.9647 68.5826 35.0154L38.1418 37.2615C37.454 37.3123 36.856 36.7951 36.8053 36.1056L36.8026 36.0681C36.7519 35.3786 37.2679 34.7792 37.9558 34.7285Z"
          fill="white"
        />
        <path
          d="M64.1918 37.8949L38.323 39.8037C37.6355 39.8544 37.1192 40.4542 37.1698 41.1433L37.1725 41.1808C37.2231 41.8699 37.8215 42.3875 38.509 42.3367L64.3778 40.428C65.0653 40.3772 65.5817 39.7775 65.5311 39.0883L65.5283 39.0509C65.4777 38.3617 64.8793 37.8442 64.1918 37.8949Z"
          fill="white"
        />
        <path
          d="M137.75 112.196L44.3475 119.09C42.0047 119.263 39.9469 117.482 39.7744 115.138L35.535 57.4183C35.3626 55.07 37.1393 53.0073 39.4783 52.8344L132.881 45.9398C135.223 45.767 137.281 47.5479 137.454 49.8925L141.693 107.612C141.866 109.96 140.089 112.023 137.75 112.196Z"
          fill="#E0E0E0"
        />
        <path
          d="M137.744 112.196L45.6834 118.989C43.3407 119.162 41.2828 117.381 41.1104 115.037L36.8709 57.3173C36.6985 54.969 38.4752 52.9063 40.8142 52.7335L132.875 45.9403C135.217 45.7675 137.275 47.5484 137.448 49.893L141.687 107.612C141.86 109.961 140.083 112.023 137.744 112.196Z"
          fill="white"
        />
        <path
          d="M48.7031 118.771L67.9773 117.347L50.5286 59.8267L48.7031 118.771Z"
          fill="#F5F5F5"
        />
        <path
          d="M155.915 96.0806L63.5618 111.666C61.2453 112.057 59.0337 110.479 58.6439 108.157L49.0593 51.0835C48.6694 48.7615 50.2438 46.5447 52.5603 46.1539L144.913 30.5687C147.23 30.1779 149.441 31.756 149.831 34.078L159.416 91.1511C159.805 93.4731 158.231 95.6899 155.915 96.0806Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M155.912 96.0806L64.8821 111.444C62.5656 111.835 60.3541 110.257 59.9642 107.935L50.3796 50.8618C49.9898 48.5398 51.5641 46.323 53.8806 45.9322L144.91 30.5687C147.227 30.1779 149.438 31.756 149.828 34.078L159.413 91.1511C159.803 93.4731 158.228 95.6899 155.912 96.0806Z"
          fill={main}
        />
        <path
          opacity="0.2"
          d="M143.751 42.8516L58.9851 57.1556C57.9655 57.3284 56.9872 56.6296 56.8147 55.6076L56.3387 52.7708C56.1663 51.7488 56.8635 50.7682 57.883 50.5954L142.649 36.2914C143.669 36.1186 144.647 36.8174 144.819 37.8394L145.295 40.6761C145.468 41.6981 144.771 42.6788 143.751 42.8516Z"
          fill={main}
        />
        <path
          opacity="0.2"
          d="M144.668 47.9576L59.7862 62.2804C58.7966 62.4457 58.1256 63.3963 58.2906 64.3844L58.7104 66.883C58.8753 67.8749 59.8236 68.5475 60.8095 68.3822L145.692 54.0594C146.681 53.8941 147.352 52.9435 147.187 51.9553L146.767 49.4568C146.603 48.4648 145.654 47.7923 144.668 47.9576Z"
          fill={main}
        />
        <path
          d="M152.845 114.244L55.0604 121.461L45.9219 122.138C50.9672 121.766 54.7606 117.362 54.3895 112.301L51.4358 72.1132C51.1921 68.7729 53.6923 65.8648 57.0283 65.6168L151.878 58.617C155.21 58.3728 158.111 60.8789 158.358 64.2229L161.312 104.411C161.683 109.468 157.894 113.872 152.848 114.244H152.845Z"
          fill={main}
        />
      </g>
      <defs>
        <clipPath id="clip0_10797_49758">
          <rect
            width="187.419"
            height="140"
            fill="white"
            transform="translate(0.789062)"
          />
        </clipPath>
      </defs>
    </StyledSvg>
  );
}

export default Illustration;
