import { rest } from 'msw';
import type { GetCountryRatesResponseDto } from '../CountryMetadata';

const getCountryRatesMockResponse: GetCountryRatesResponseDto = [
  {
    id: 1,
    countryCode: 'AF',
    inflationRateShortTerm: 0.0845,
    inflationRateLongTerm: 0.0632,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 62,
    lifeExpectancyFemale: 65,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0.1887,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 2,
    countryCode: 'AL',
    inflationRateShortTerm: 0.0311,
    inflationRateLongTerm: 0.0239,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 76,
    lifeExpectancyFemale: 80,
    annualWageGrowthShortTerm: 0.0336,
    annualWageGrowthLongTerm: 0.0336,
    averageSavingsRate: 0.1676,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 3,
    countryCode: 'DZ',
    inflationRateShortTerm: 0.0454,
    inflationRateLongTerm: 0.0496,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 75,
    lifeExpectancyFemale: 77,
    annualWageGrowthShortTerm: -0.0587,
    annualWageGrowthLongTerm: -0.0587,
    averageSavingsRate: 0.3783,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 4,
    countryCode: 'AO',
    inflationRateShortTerm: 0.1328,
    inflationRateLongTerm: 0.1477,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 59,
    lifeExpectancyFemale: 64,
    annualWageGrowthShortTerm: -0.0078,
    annualWageGrowthLongTerm: -0.0078,
    averageSavingsRate: 0.2644,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 5,
    countryCode: 'AR',
    inflationRateShortTerm: 0.0878,
    inflationRateLongTerm: 0.1128,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 73,
    lifeExpectancyFemale: 80,
    annualWageGrowthShortTerm: -0.0313,
    annualWageGrowthLongTerm: -0.0313,
    averageSavingsRate: 0.1348,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 6,
    countryCode: 'AM',
    inflationRateShortTerm: 0.0641,
    inflationRateLongTerm: 0.0424,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 71,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: -0.0245,
    annualWageGrowthLongTerm: -0.0245,
    averageSavingsRate: 0.1817,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 7,
    countryCode: 'AW',
    inflationRateShortTerm: 0.0385,
    inflationRateLongTerm: 0.0155,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 73,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 8,
    countryCode: 'AU',
    inflationRateShortTerm: 0.0293,
    inflationRateLongTerm: 0.0237,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 81,
    lifeExpectancyFemale: 85,
    annualWageGrowthShortTerm: -0.0096,
    annualWageGrowthLongTerm: -0.0096,
    averageSavingsRate: 0.2185,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 9,
    countryCode: 'AT',
    inflationRateShortTerm: 0.0221,
    inflationRateLongTerm: 0.0193,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 79,
    lifeExpectancyFemale: 83,
    annualWageGrowthShortTerm: 0.0101,
    annualWageGrowthLongTerm: 0.0101,
    averageSavingsRate: 0.2781,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 10,
    countryCode: 'AZ',
    inflationRateShortTerm: 0.1051,
    inflationRateLongTerm: 0.0794,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 69,
    lifeExpectancyFemale: 75,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0.2828,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 11,
    countryCode: 'BS',
    inflationRateShortTerm: 0.0249,
    inflationRateLongTerm: 0.0249,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 73,
    lifeExpectancyFemale: 79,
    annualWageGrowthShortTerm: -0.0406,
    annualWageGrowthLongTerm: -0.0406,
    averageSavingsRate: 0.1642,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 12,
    countryCode: 'BH',
    inflationRateShortTerm: 0.0223,
    inflationRateLongTerm: 0.0236,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 76,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: -0.0188,
    annualWageGrowthLongTerm: -0.0188,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 13,
    countryCode: 'BD',
    inflationRateShortTerm: 0.0875,
    inflationRateLongTerm: 0.0745,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 71,
    lifeExpectancyFemale: 74,
    annualWageGrowthShortTerm: 0.0539,
    annualWageGrowthLongTerm: 0.0539,
    averageSavingsRate: 0.3524,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 14,
    countryCode: 'BB',
    inflationRateShortTerm: 0.0621,
    inflationRateLongTerm: 0.04,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 73,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 15,
    countryCode: 'BY',
    inflationRateShortTerm: 0.1944,
    inflationRateLongTerm: 0.2038,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 69,
    lifeExpectancyFemale: 79,
    annualWageGrowthShortTerm: -0.1579,
    annualWageGrowthLongTerm: -0.1579,
    averageSavingsRate: 0.2861,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 16,
    countryCode: 'BE',
    inflationRateShortTerm: 0.024,
    inflationRateLongTerm: 0.0191,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 79,
    lifeExpectancyFemale: 83,
    annualWageGrowthShortTerm: 0.0185,
    annualWageGrowthLongTerm: 0.0185,
    averageSavingsRate: 0.2468,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 17,
    countryCode: 'BZ',
    inflationRateShortTerm: 0.0204,
    inflationRateLongTerm: 0.0128,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 68,
    lifeExpectancyFemale: 73,
    annualWageGrowthShortTerm: -0.0584,
    annualWageGrowthLongTerm: -0.0584,
    averageSavingsRate: 0.0965,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 18,
    countryCode: 'BM',
    inflationRateShortTerm: 0.023,
    inflationRateLongTerm: 0.023,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 78,
    lifeExpectancyFemale: 85,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 19,
    countryCode: 'BT',
    inflationRateShortTerm: 0.0623,
    inflationRateLongTerm: 0.0748,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 70,
    lifeExpectancyFemale: 70,
    annualWageGrowthShortTerm: 0.0503,
    annualWageGrowthLongTerm: 0.0503,
    averageSavingsRate: 0.294,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 20,
    countryCode: 'BA',
    inflationRateShortTerm: 0.0194,
    inflationRateLongTerm: 0.009,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 74,
    lifeExpectancyFemale: 79,
    annualWageGrowthShortTerm: 0.0465,
    annualWageGrowthLongTerm: 0.0465,
    averageSavingsRate: 0.1287,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 21,
    countryCode: 'BW',
    inflationRateShortTerm: 0.0865,
    inflationRateLongTerm: 0.0638,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 64,
    lifeExpectancyFemale: 70,
    annualWageGrowthShortTerm: 0.0206,
    annualWageGrowthLongTerm: 0.0206,
    averageSavingsRate: 0.3935,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 22,
    countryCode: 'BR',
    inflationRateShortTerm: 0.0518,
    inflationRateLongTerm: 0.0591,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 72,
    lifeExpectancyFemale: 79,
    annualWageGrowthShortTerm: -0.0475,
    annualWageGrowthLongTerm: -0.0475,
    averageSavingsRate: 0.148,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 23,
    countryCode: 'IO',
    inflationRateShortTerm: 0.023,
    inflationRateLongTerm: 0.023,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 83,
    lifeExpectancyFemale: 83,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 24,
    countryCode: 'BG',
    inflationRateShortTerm: 0.0568,
    inflationRateLongTerm: 0.0258,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 71,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: 0.0911,
    annualWageGrowthLongTerm: 0.0911,
    averageSavingsRate: 0.2865,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 25,
    countryCode: 'BI',
    inflationRateShortTerm: 0.1189,
    inflationRateLongTerm: 0.107,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 56,
    lifeExpectancyFemale: 59,
    annualWageGrowthShortTerm: -0.069,
    annualWageGrowthLongTerm: -0.069,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 26,
    countryCode: 'KH',
    inflationRateShortTerm: 0.083,
    inflationRateLongTerm: 0.0531,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 67,
    lifeExpectancyFemale: 71,
    annualWageGrowthShortTerm: 0.0627,
    annualWageGrowthLongTerm: 0.0627,
    averageSavingsRate: 0.2297,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 27,
    countryCode: 'CA',
    inflationRateShortTerm: 0.0189,
    inflationRateLongTerm: 0.0164,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 80,
    lifeExpectancyFemale: 84,
    annualWageGrowthShortTerm: -0.0038,
    annualWageGrowthLongTerm: -0.0038,
    averageSavingsRate: 0.1993,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 28,
    countryCode: 'KY',
    inflationRateShortTerm: 0.008,
    inflationRateLongTerm: 0.008,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 83,
    lifeExpectancyFemale: 83,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 29,
    countryCode: 'CL',
    inflationRateShortTerm: 0.0388,
    inflationRateLongTerm: 0.0355,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 77,
    lifeExpectancyFemale: 82,
    annualWageGrowthShortTerm: 0.0123,
    annualWageGrowthLongTerm: 0.0123,
    averageSavingsRate: 0.2051,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 30,
    countryCode: 'CN',
    inflationRateShortTerm: 0.0374,
    inflationRateLongTerm: 0.0281,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 75,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: 0.0548,
    annualWageGrowthLongTerm: 0.0548,
    averageSavingsRate: 0.4701,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 31,
    countryCode: 'CO',
    inflationRateShortTerm: 0.0448,
    inflationRateLongTerm: 0.043,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 71,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: 0.0076,
    annualWageGrowthLongTerm: 0.0076,
    averageSavingsRate: 0.1643,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 32,
    countryCode: 'KM',
    inflationRateShortTerm: 0.0404,
    inflationRateLongTerm: 0.0308,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 62,
    lifeExpectancyFemale: 65,
    annualWageGrowthShortTerm: 0.0576,
    annualWageGrowthLongTerm: 0.0576,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 33,
    countryCode: 'CR',
    inflationRateShortTerm: 0.0823,
    inflationRateLongTerm: 0.0526,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 77,
    lifeExpectancyFemale: 82,
    annualWageGrowthShortTerm: 0.0385,
    annualWageGrowthLongTerm: 0.0385,
    averageSavingsRate: 0.142,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 34,
    countryCode: 'HR',
    inflationRateShortTerm: 0.0293,
    inflationRateLongTerm: 0.0178,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 75,
    lifeExpectancyFemale: 81,
    annualWageGrowthShortTerm: -0.0003,
    annualWageGrowthLongTerm: -0.0003,
    averageSavingsRate: 0.2501,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 35,
    countryCode: 'CU',
    inflationRateShortTerm: 0.04,
    inflationRateLongTerm: 0.04,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 78,
    lifeExpectancyFemale: 82,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 36,
    countryCode: 'CZ',
    inflationRateShortTerm: 0.0272,
    inflationRateLongTerm: 0.0201,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 76,
    lifeExpectancyFemale: 81,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0.2744,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 37,
    countryCode: 'DK',
    inflationRateShortTerm: 0.023,
    inflationRateLongTerm: 0.0155,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 79,
    lifeExpectancyFemale: 83,
    annualWageGrowthShortTerm: -0.0032,
    annualWageGrowthLongTerm: -0.0032,
    averageSavingsRate: 0.2869,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 38,
    countryCode: 'DJ',
    inflationRateShortTerm: 0.0553,
    inflationRateLongTerm: 0.0384,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 61,
    lifeExpectancyFemale: 64,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0.1686,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 39,
    countryCode: 'DO',
    inflationRateShortTerm: 0.066,
    inflationRateLongTerm: 0.0457,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 71,
    lifeExpectancyFemale: 77,
    annualWageGrowthShortTerm: 0.0525,
    annualWageGrowthLongTerm: 0.0525,
    averageSavingsRate: 0.2138,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 40,
    countryCode: 'EC',
    inflationRateShortTerm: 0.0477,
    inflationRateLongTerm: 0.0376,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 74,
    lifeExpectancyFemale: 79,
    annualWageGrowthShortTerm: -0.05,
    annualWageGrowthLongTerm: -0.05,
    averageSavingsRate: 0.2582,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 41,
    countryCode: 'EG',
    inflationRateShortTerm: 0.1234,
    inflationRateLongTerm: 0.1201,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 69,
    lifeExpectancyFemale: 74,
    annualWageGrowthShortTerm: 0.0248,
    annualWageGrowthLongTerm: 0.0248,
    averageSavingsRate: 0.0992,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 42,
    countryCode: 'SV',
    inflationRateShortTerm: 0.0374,
    inflationRateLongTerm: 0.0211,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 69,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: 0.0078,
    annualWageGrowthLongTerm: 0.0078,
    averageSavingsRate: 0.1431,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 43,
    countryCode: 'ER',
    inflationRateShortTerm: 0.1548,
    inflationRateLongTerm: 0.1154,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 63,
    lifeExpectancyFemale: 67,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 44,
    countryCode: 'EE',
    inflationRateShortTerm: 0.0507,
    inflationRateLongTerm: 0.0344,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 73,
    lifeExpectancyFemale: 83,
    annualWageGrowthShortTerm: 0.0294,
    annualWageGrowthLongTerm: 0.0294,
    averageSavingsRate: 0.2705,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 45,
    countryCode: 'ET',
    inflationRateShortTerm: 0.2229,
    inflationRateLongTerm: 0.1622,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 64,
    lifeExpectancyFemale: 67,
    annualWageGrowthShortTerm: 0.0565,
    annualWageGrowthLongTerm: 0.0565,
    averageSavingsRate: 0.3271,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 46,
    countryCode: 'FJ',
    inflationRateShortTerm: 0.0543,
    inflationRateLongTerm: 0.0387,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 67,
    lifeExpectancyFemale: 73,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 47,
    countryCode: 'FI',
    inflationRateShortTerm: 0.0243,
    inflationRateLongTerm: 0.018,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 79,
    lifeExpectancyFemale: 85,
    annualWageGrowthShortTerm: 0.0222,
    annualWageGrowthLongTerm: 0.0222,
    averageSavingsRate: 0.2263,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 48,
    countryCode: 'FR',
    inflationRateShortTerm: 0.0178,
    inflationRateLongTerm: 0.013,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 79,
    lifeExpectancyFemale: 86,
    annualWageGrowthShortTerm: 0.0142,
    annualWageGrowthLongTerm: 0.0142,
    averageSavingsRate: 0.2265,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 49,
    countryCode: 'GM',
    inflationRateShortTerm: 0.0735,
    inflationRateLongTerm: 0.0735,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 60,
    lifeExpectancyFemale: 63,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 50,
    countryCode: 'GE',
    inflationRateShortTerm: 0.0733,
    inflationRateLongTerm: 0.0458,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 69,
    lifeExpectancyFemale: 77,
    annualWageGrowthShortTerm: 0.0163,
    annualWageGrowthLongTerm: 0.0163,
    averageSavingsRate: 0.2348,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 51,
    countryCode: 'DE',
    inflationRateShortTerm: 0.0178,
    inflationRateLongTerm: 0.0142,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 78,
    lifeExpectancyFemale: 83,
    annualWageGrowthShortTerm: 0.0145,
    annualWageGrowthLongTerm: 0.0145,
    averageSavingsRate: 0.2776,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 52,
    countryCode: 'GH',
    inflationRateShortTerm: 0.1095,
    inflationRateLongTerm: 0.1236,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 62,
    lifeExpectancyFemale: 64,
    annualWageGrowthShortTerm: 0.0166,
    annualWageGrowthLongTerm: 0.0166,
    averageSavingsRate: 0.2044,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 53,
    countryCode: 'GR',
    inflationRateShortTerm: 0.0328,
    inflationRateLongTerm: 0.0138,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 79,
    lifeExpectancyFemale: 84,
    annualWageGrowthShortTerm: -0.0121,
    annualWageGrowthLongTerm: -0.0121,
    averageSavingsRate: 0.1035,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 54,
    countryCode: 'GT',
    inflationRateShortTerm: 0.0602,
    inflationRateLongTerm: 0.0481,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 70,
    lifeExpectancyFemale: 77,
    annualWageGrowthShortTerm: 0.0239,
    annualWageGrowthLongTerm: 0.0239,
    averageSavingsRate: 0.1407,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 55,
    countryCode: 'GN',
    inflationRateShortTerm: 0.1655,
    inflationRateLongTerm: 0.1316,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 59,
    lifeExpectancyFemale: 61,
    annualWageGrowthShortTerm: -0.0197,
    annualWageGrowthLongTerm: -0.0197,
    averageSavingsRate: 0.1354,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 56,
    countryCode: 'GY',
    inflationRateShortTerm: 0.0639,
    inflationRateLongTerm: 0.0353,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 64,
    lifeExpectancyFemale: 69,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 57,
    countryCode: 'HT',
    inflationRateShortTerm: 0.0766,
    inflationRateLongTerm: 0.0831,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 61,
    lifeExpectancyFemale: 66,
    annualWageGrowthShortTerm: -0.0074,
    annualWageGrowthLongTerm: -0.0074,
    averageSavingsRate: 0.2564,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 58,
    countryCode: 'HN',
    inflationRateShortTerm: 0.0706,
    inflationRateLongTerm: 0.056,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 71,
    lifeExpectancyFemale: 76,
    annualWageGrowthShortTerm: 0.0229,
    annualWageGrowthLongTerm: 0.0229,
    averageSavingsRate: 0.2087,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 59,
    countryCode: 'HK',
    inflationRateShortTerm: 0.033,
    inflationRateLongTerm: 0.033,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 81,
    lifeExpectancyFemale: 87,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0.2657,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 60,
    countryCode: 'HU',
    inflationRateShortTerm: 0.054,
    inflationRateLongTerm: 0.0336,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 72,
    lifeExpectancyFemale: 79,
    annualWageGrowthShortTerm: 0.0437,
    annualWageGrowthLongTerm: 0.0437,
    averageSavingsRate: 0.2588,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 61,
    countryCode: 'IS',
    inflationRateShortTerm: 0.0783,
    inflationRateLongTerm: 0.0503,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 81,
    lifeExpectancyFemale: 84,
    annualWageGrowthShortTerm: 0.1009,
    annualWageGrowthLongTerm: 0.1009,
    averageSavingsRate: 0.2573,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 62,
    countryCode: 'IN',
    inflationRateShortTerm: 0.0932,
    inflationRateLongTerm: 0.0771,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 67,
    lifeExpectancyFemale: 70,
    annualWageGrowthShortTerm: 0.0533,
    annualWageGrowthLongTerm: 0.0533,
    averageSavingsRate: 0.3073,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 63,
    countryCode: 'ID',
    inflationRateShortTerm: 0.0639,
    inflationRateLongTerm: 0.0568,
    savingsAccountInterestRate: 0.01,
    retirementAgeMale: 55,
    retirementAgeFemale: 58,
    lifeExpectancyMale: 67,
    lifeExpectancyFemale: 71,
    annualWageGrowthShortTerm: 0.0608,
    annualWageGrowthLongTerm: 0.0608,
    averageSavingsRate: 0.3087,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 64,
    countryCode: 'IR',
    inflationRateShortTerm: 0.4,
    inflationRateLongTerm: 0.4,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 75,
    lifeExpectancyFemale: 77,
    annualWageGrowthShortTerm: 0.0233,
    annualWageGrowthLongTerm: 0.0233,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 65,
    countryCode: 'IQ',
    inflationRateShortTerm: 0.0787,
    inflationRateLongTerm: 0.0469,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 68,
    lifeExpectancyFemale: 72,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 66,
    countryCode: 'IE',
    inflationRateShortTerm: 0.0078,
    inflationRateLongTerm: 0.0061,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 80,
    lifeExpectancyFemale: 83,
    annualWageGrowthShortTerm: 0.089,
    annualWageGrowthLongTerm: 0.089,
    averageSavingsRate: 0.3663,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 67,
    countryCode: 'IL',
    inflationRateShortTerm: 0.0291,
    inflationRateLongTerm: 0.0158,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 81,
    lifeExpectancyFemale: 84,
    annualWageGrowthShortTerm: 0.0271,
    annualWageGrowthLongTerm: 0.0271,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 68,
    countryCode: 'IT',
    inflationRateShortTerm: 0.0217,
    inflationRateLongTerm: 0.0155,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 80,
    lifeExpectancyFemale: 85,
    annualWageGrowthShortTerm: 0.0296,
    annualWageGrowthLongTerm: 0.0296,
    averageSavingsRate: 0.2,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 69,
    countryCode: 'JM',
    inflationRateShortTerm: 0.1218,
    inflationRateLongTerm: 0.0871,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 74,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: -0.0091,
    annualWageGrowthLongTerm: -0.0091,
    averageSavingsRate: 0.2091,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 70,
    countryCode: 'JP',
    inflationRateShortTerm: -0.0018,
    inflationRateLongTerm: 0.003,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 81,
    lifeExpectancyFemale: 87,
    annualWageGrowthShortTerm: 0.0021,
    annualWageGrowthLongTerm: 0.0021,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 71,
    countryCode: 'JO',
    inflationRateShortTerm: 0.054,
    inflationRateLongTerm: 0.0372,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 73,
    lifeExpectancyFemale: 76,
    annualWageGrowthShortTerm: -0.0136,
    annualWageGrowthLongTerm: -0.0136,
    averageSavingsRate: 0.1224,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 72,
    countryCode: 'KZ',
    inflationRateShortTerm: 0.1014,
    inflationRateLongTerm: 0.0882,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 68,
    lifeExpectancyFemale: 77,
    annualWageGrowthShortTerm: -0.0764,
    annualWageGrowthLongTerm: -0.0764,
    averageSavingsRate: 0.2595,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 73,
    countryCode: 'KE',
    inflationRateShortTerm: 0.0965,
    inflationRateLongTerm: 0.0828,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 65,
    lifeExpectancyFemale: 69,
    annualWageGrowthShortTerm: 0.0561,
    annualWageGrowthLongTerm: 0.0561,
    averageSavingsRate: 0.0943,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 74,
    countryCode: 'KW',
    inflationRateShortTerm: 0.0516,
    inflationRateLongTerm: 0.0395,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 74,
    lifeExpectancyFemale: 76,
    annualWageGrowthShortTerm: -0.0861,
    annualWageGrowthLongTerm: -0.0861,
    averageSavingsRate: 0.3512,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 75,
    countryCode: 'KG',
    inflationRateShortTerm: 0.01,
    inflationRateLongTerm: 0.01,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 67,
    lifeExpectancyFemale: 75,
    annualWageGrowthShortTerm: 0.0512,
    annualWageGrowthLongTerm: 0.0512,
    averageSavingsRate: 0.2982,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 76,
    countryCode: 'LB',
    inflationRateShortTerm: 0.0499,
    inflationRateLongTerm: 0.0347,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 78,
    lifeExpectancyFemale: 81,
    annualWageGrowthShortTerm: -0.0173,
    annualWageGrowthLongTerm: -0.0173,
    averageSavingsRate: 0.0178,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 77,
    countryCode: 'LS',
    inflationRateShortTerm: 0.07,
    inflationRateLongTerm: 0.0599,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 52,
    lifeExpectancyFemale: 56,
    annualWageGrowthShortTerm: 0.0002,
    annualWageGrowthLongTerm: 0.0002,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 78,
    countryCode: 'LR',
    inflationRateShortTerm: 0.1042,
    inflationRateLongTerm: 0.0958,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 62,
    lifeExpectancyFemale: 63,
    annualWageGrowthShortTerm: -0.0381,
    annualWageGrowthLongTerm: -0.0381,
    averageSavingsRate: -0.4878,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 79,
    countryCode: 'LY',
    inflationRateShortTerm: 0.0748,
    inflationRateLongTerm: 0.1025,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 69,
    lifeExpectancyFemale: 75,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 80,
    countryCode: 'LI',
    inflationRateShortTerm: 0.003,
    inflationRateLongTerm: 0.003,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 81,
    lifeExpectancyFemale: 85,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 81,
    countryCode: 'LT',
    inflationRateShortTerm: 0.0528,
    inflationRateLongTerm: 0.0315,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 69,
    lifeExpectancyFemale: 80,
    annualWageGrowthShortTerm: 0.0586,
    annualWageGrowthLongTerm: 0.0586,
    averageSavingsRate: 0.1831,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 82,
    countryCode: 'LU',
    inflationRateShortTerm: 0.0266,
    inflationRateLongTerm: 0.0189,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 80,
    lifeExpectancyFemale: 85,
    annualWageGrowthShortTerm: 0.0202,
    annualWageGrowthLongTerm: 0.0202,
    averageSavingsRate: 0.2451,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 83,
    countryCode: 'MG',
    inflationRateShortTerm: 0.0945,
    inflationRateLongTerm: 0.0793,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 64,
    lifeExpectancyFemale: 68,
    annualWageGrowthShortTerm: 0.0215,
    annualWageGrowthLongTerm: 0.0215,
    averageSavingsRate: 0.1488,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 84,
    countryCode: 'MW',
    inflationRateShortTerm: 0.0814,
    inflationRateLongTerm: 0.1544,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 61,
    lifeExpectancyFemale: 66,
    annualWageGrowthShortTerm: 0.0227,
    annualWageGrowthLongTerm: 0.0227,
    averageSavingsRate: 0.1027,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 85,
    countryCode: 'MY',
    inflationRateShortTerm: 0.0259,
    inflationRateLongTerm: 0.0253,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 73,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: 0.0153,
    annualWageGrowthLongTerm: 0.0153,
    averageSavingsRate: 0.2853,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 86,
    countryCode: 'MV',
    inflationRateShortTerm: 0.0816,
    inflationRateLongTerm: 0.0569,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 76,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 87,
    countryCode: 'MR',
    inflationRateShortTerm: 0.0576,
    inflationRateLongTerm: 0.0417,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 62,
    lifeExpectancyFemale: 65,
    annualWageGrowthShortTerm: -0.0154,
    annualWageGrowthLongTerm: -0.0154,
    averageSavingsRate: 0.2613,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 88,
    countryCode: 'MU',
    inflationRateShortTerm: 0.0611,
    inflationRateLongTerm: 0.0428,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 71,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: 0.0251,
    annualWageGrowthLongTerm: 0.0251,
    averageSavingsRate: 0.0825,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 89,
    countryCode: 'MX',
    inflationRateShortTerm: 0.0439,
    inflationRateLongTerm: 0.0413,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 75,
    lifeExpectancyFemale: 80,
    annualWageGrowthShortTerm: -0.0004,
    annualWageGrowthLongTerm: -0.0004,
    averageSavingsRate: 0.2286,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 90,
    countryCode: 'MN',
    inflationRateShortTerm: 0.1188,
    inflationRateLongTerm: 0.0972,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 65,
    lifeExpectancyFemale: 74,
    annualWageGrowthShortTerm: 0.0108,
    annualWageGrowthLongTerm: 0.0108,
    averageSavingsRate: 0.2485,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 91,
    countryCode: 'MA',
    inflationRateShortTerm: 0.0176,
    inflationRateLongTerm: 0.0149,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 75,
    lifeExpectancyFemale: 77,
    annualWageGrowthShortTerm: 0.0124,
    annualWageGrowthLongTerm: 0.0124,
    averageSavingsRate: 0.2895,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 92,
    countryCode: 'MZ',
    inflationRateShortTerm: 0.0896,
    inflationRateLongTerm: 0.0821,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 56,
    lifeExpectancyFemale: 60,
    annualWageGrowthShortTerm: -0.0051,
    annualWageGrowthLongTerm: -0.0051,
    averageSavingsRate: 0.075,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 93,
    countryCode: 'MM',
    inflationRateShortTerm: 0.1114,
    inflationRateLongTerm: 0.082,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 64,
    lifeExpectancyFemale: 69,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0.2972,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 94,
    countryCode: 'NA',
    inflationRateShortTerm: 0.07,
    inflationRateLongTerm: 0.0627,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 61,
    lifeExpectancyFemale: 67,
    annualWageGrowthShortTerm: -0.0403,
    annualWageGrowthLongTerm: -0.0403,
    averageSavingsRate: 0.1592,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 95,
    countryCode: 'NP',
    inflationRateShortTerm: 0.0893,
    inflationRateLongTerm: 0.085,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 69,
    lifeExpectancyFemale: 72,
    annualWageGrowthShortTerm: 0.0099,
    annualWageGrowthLongTerm: 0.0099,
    averageSavingsRate: 0.4562,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 96,
    countryCode: 'NL',
    inflationRateShortTerm: 0.0163,
    inflationRateLongTerm: 0.0141,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 80,
    lifeExpectancyFemale: 83,
    annualWageGrowthShortTerm: 0.0123,
    annualWageGrowthLongTerm: 0.0123,
    averageSavingsRate: 0.3061,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 97,
    countryCode: 'NZ',
    inflationRateShortTerm: 0.0296,
    inflationRateLongTerm: 0.0191,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 80,
    lifeExpectancyFemale: 83,
    annualWageGrowthShortTerm: 0.01,
    annualWageGrowthLongTerm: 0.01,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 98,
    countryCode: 'NI',
    inflationRateShortTerm: 0.0964,
    inflationRateLongTerm: 0.0727,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 72,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: 0.0437,
    annualWageGrowthLongTerm: 0.0437,
    averageSavingsRate: 0.2393,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 99,
    countryCode: 'NG',
    inflationRateShortTerm: 0.1082,
    inflationRateLongTerm: 0.1128,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 53,
    lifeExpectancyFemale: 54,
    annualWageGrowthShortTerm: -0.0714,
    annualWageGrowthLongTerm: -0.0714,
    averageSavingsRate: 0.1826,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 100,
    countryCode: 'NO',
    inflationRateShortTerm: 0.0207,
    inflationRateLongTerm: 0.0207,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 81,
    lifeExpectancyFemale: 84,
    annualWageGrowthShortTerm: -0.0343,
    annualWageGrowthLongTerm: -0.0343,
    averageSavingsRate: 0.3369,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 101,
    countryCode: 'OM',
    inflationRateShortTerm: 0.0586,
    inflationRateLongTerm: 0.0339,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 75,
    lifeExpectancyFemale: 79,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0.1885,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 102,
    countryCode: 'PK',
    inflationRateShortTerm: 0.1262,
    inflationRateLongTerm: 0.0924,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 66,
    lifeExpectancyFemale: 68,
    annualWageGrowthShortTerm: 0.0508,
    annualWageGrowthLongTerm: 0.0508,
    averageSavingsRate: 0.2011,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 103,
    countryCode: 'PA',
    inflationRateShortTerm: 0.0494,
    inflationRateLongTerm: 0.0353,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 75,
    lifeExpectancyFemale: 81,
    annualWageGrowthShortTerm: 0.0035,
    annualWageGrowthLongTerm: 0.0035,
    averageSavingsRate: 0.2914,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 104,
    countryCode: 'PG',
    inflationRateShortTerm: 0.0563,
    inflationRateLongTerm: 0.0554,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 63,
    lifeExpectancyFemale: 68,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 105,
    countryCode: 'PY',
    inflationRateShortTerm: 0.0676,
    inflationRateLongTerm: 0.0509,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 71,
    lifeExpectancyFemale: 75,
    annualWageGrowthShortTerm: 0.0447,
    annualWageGrowthLongTerm: 0.0447,
    averageSavingsRate: 0.2403,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 106,
    countryCode: 'PE',
    inflationRateShortTerm: 0.0308,
    inflationRateLongTerm: 0.0319,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 72,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: 0.0111,
    annualWageGrowthLongTerm: 0.0111,
    averageSavingsRate: 0.202,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 107,
    countryCode: 'PH',
    inflationRateShortTerm: 0.0484,
    inflationRateLongTerm: 0.0347,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 66,
    lifeExpectancyFemale: 73,
    annualWageGrowthShortTerm: 0.06,
    annualWageGrowthLongTerm: 0.06,
    averageSavingsRate: 0.4377,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 108,
    countryCode: 'PL',
    inflationRateShortTerm: 0.034,
    inflationRateLongTerm: 0.02,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 74,
    lifeExpectancyFemale: 82,
    annualWageGrowthShortTerm: 0.0252,
    annualWageGrowthLongTerm: 0.0252,
    averageSavingsRate: 0.1974,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 109,
    countryCode: 'PT',
    inflationRateShortTerm: 0.0182,
    inflationRateLongTerm: 0.0135,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 78,
    lifeExpectancyFemale: 84,
    annualWageGrowthShortTerm: 0.0342,
    annualWageGrowthLongTerm: 0.0342,
    averageSavingsRate: 0.1631,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 110,
    countryCode: 'QA',
    inflationRateShortTerm: 0.0471,
    inflationRateLongTerm: 0.0334,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 77,
    lifeExpectancyFemale: 80,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0.4845,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 111,
    countryCode: 'RO',
    inflationRateShortTerm: 0.0603,
    inflationRateLongTerm: 0.0343,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 72,
    lifeExpectancyFemale: 79,
    annualWageGrowthShortTerm: 0.0393,
    annualWageGrowthLongTerm: 0.0393,
    averageSavingsRate: 0.2106,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 112,
    countryCode: 'RU',
    inflationRateShortTerm: 0.1001,
    inflationRateLongTerm: 0.0873,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 67,
    lifeExpectancyFemale: 77,
    annualWageGrowthShortTerm: -0.064,
    annualWageGrowthLongTerm: -0.064,
    averageSavingsRate: 0.2656,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 113,
    countryCode: 'WS',
    inflationRateShortTerm: 0.0561,
    inflationRateLongTerm: 0.0329,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 72,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 114,
    countryCode: 'SA',
    inflationRateShortTerm: 0.0457,
    inflationRateLongTerm: 0.0308,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 73,
    lifeExpectancyFemale: 76,
    annualWageGrowthShortTerm: -0.0578,
    annualWageGrowthLongTerm: -0.0578,
    averageSavingsRate: 0.3017,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 115,
    countryCode: 'RS',
    inflationRateShortTerm: 0.0876,
    inflationRateLongTerm: 0.0605,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 73,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: 0.038,
    annualWageGrowthLongTerm: 0.038,
    averageSavingsRate: 0.1484,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 116,
    countryCode: 'SC',
    inflationRateShortTerm: 0.1484,
    inflationRateLongTerm: 0.0845,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 69,
    lifeExpectancyFemale: 80,
    annualWageGrowthShortTerm: 0.0105,
    annualWageGrowthLongTerm: 0.0105,
    averageSavingsRate: 0.1199,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 117,
    countryCode: 'SL',
    inflationRateShortTerm: 0.0933,
    inflationRateLongTerm: 0.0902,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 51,
    lifeExpectancyFemale: 52,
    annualWageGrowthShortTerm: -0.072,
    annualWageGrowthLongTerm: -0.072,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 119,
    countryCode: 'SB',
    inflationRateShortTerm: 0.081,
    inflationRateLongTerm: 0.0522,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 69,
    lifeExpectancyFemale: 72,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 120,
    countryCode: 'SO',
    inflationRateShortTerm: 0.06,
    inflationRateLongTerm: 0.06,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 55,
    lifeExpectancyFemale: 58,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 121,
    countryCode: 'ZA',
    inflationRateShortTerm: 0.069,
    inflationRateLongTerm: 0.062,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 59,
    lifeExpectancyFemale: 66,
    annualWageGrowthShortTerm: -0.0179,
    annualWageGrowthLongTerm: -0.0179,
    averageSavingsRate: 0.1582,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 122,
    countryCode: 'KR',
    inflationRateShortTerm: 0.016,
    inflationRateLongTerm: 0.016,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 79,
    lifeExpectancyFemale: 85,
    annualWageGrowthShortTerm: 0.0303,
    annualWageGrowthLongTerm: 0.0303,
    averageSavingsRate: 0.3611,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 123,
    countryCode: 'SS',
    inflationRateShortTerm: null,
    inflationRateLongTerm: 1.112,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 56,
    lifeExpectancyFemale: 58,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 124,
    countryCode: 'ES',
    inflationRateShortTerm: 0.0231,
    inflationRateLongTerm: 0.015,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 80,
    lifeExpectancyFemale: 86,
    annualWageGrowthShortTerm: 0.0311,
    annualWageGrowthLongTerm: 0.0311,
    averageSavingsRate: 0.2287,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 125,
    countryCode: 'LK',
    inflationRateShortTerm: 0.0838,
    inflationRateLongTerm: 0.0654,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 72,
    lifeExpectancyFemale: 79,
    annualWageGrowthShortTerm: 0.0483,
    annualWageGrowthLongTerm: 0.0483,
    averageSavingsRate: 0.3403,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 126,
    countryCode: 'SD',
    inflationRateShortTerm: 0.1428,
    inflationRateLongTerm: 0.2249,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 63,
    lifeExpectancyFemale: 66,
    annualWageGrowthShortTerm: -0.0097,
    annualWageGrowthLongTerm: -0.0097,
    averageSavingsRate: 0.131,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 127,
    countryCode: 'SR',
    inflationRateShortTerm: 0.0909,
    inflationRateLongTerm: 0.1274,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 68,
    lifeExpectancyFemale: 75,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 128,
    countryCode: 'SE',
    inflationRateShortTerm: 0.0205,
    inflationRateLongTerm: 0.0141,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 80,
    lifeExpectancyFemale: 84,
    annualWageGrowthShortTerm: 0.0226,
    annualWageGrowthLongTerm: 0.0226,
    averageSavingsRate: 0.2989,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 129,
    countryCode: 'CH',
    inflationRateShortTerm: 0.0072,
    inflationRateLongTerm: 0.0015,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 81,
    lifeExpectancyFemale: 85,
    annualWageGrowthShortTerm: -0.0355,
    annualWageGrowthLongTerm: -0.0355,
    averageSavingsRate: 0.3374,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 130,
    countryCode: 'TW',
    inflationRateShortTerm: 0.004,
    inflationRateLongTerm: 0.004,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 77,
    lifeExpectancyFemale: 83,
    annualWageGrowthShortTerm: 0.02,
    annualWageGrowthLongTerm: 0.02,
    averageSavingsRate: 0.31,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 131,
    countryCode: 'TJ',
    inflationRateShortTerm: 0.1178,
    inflationRateLongTerm: 0.0862,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 68,
    lifeExpectancyFemale: 74,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0.2314,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 132,
    countryCode: 'TH',
    inflationRateShortTerm: 0.0278,
    inflationRateLongTerm: 0.0191,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 72,
    lifeExpectancyFemale: 79,
    annualWageGrowthShortTerm: 0.0057,
    annualWageGrowthLongTerm: 0.0057,
    averageSavingsRate: 0.3185,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 133,
    countryCode: 'TO',
    inflationRateShortTerm: 0.055,
    inflationRateLongTerm: 0.0371,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 70,
    lifeExpectancyFemale: 76,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 134,
    countryCode: 'TT',
    inflationRateShortTerm: 0.0851,
    inflationRateLongTerm: 0.0658,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 67,
    lifeExpectancyFemale: 74,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 135,
    countryCode: 'TN',
    inflationRateShortTerm: 0.0396,
    inflationRateLongTerm: 0.0451,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 74,
    lifeExpectancyFemale: 78,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0.0887,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 136,
    countryCode: 'TR',
    inflationRateShortTerm: 0.081,
    inflationRateLongTerm: 0.0839,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 72,
    lifeExpectancyFemale: 79,
    annualWageGrowthShortTerm: 0.0176,
    annualWageGrowthLongTerm: 0.0176,
    averageSavingsRate: 0.2547,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 137,
    countryCode: 'TM',
    inflationRateShortTerm: 0.0557,
    inflationRateLongTerm: 0.0592,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 64,
    lifeExpectancyFemale: 71,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 138,
    countryCode: 'UG',
    inflationRateShortTerm: 0.0998,
    inflationRateLongTerm: 0.0791,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 58,
    lifeExpectancyFemale: 62,
    annualWageGrowthShortTerm: -0.0644,
    annualWageGrowthLongTerm: -0.0644,
    averageSavingsRate: 0.2009,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 139,
    countryCode: 'UA',
    inflationRateShortTerm: 0.1425,
    inflationRateLongTerm: 0.1461,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 67,
    lifeExpectancyFemale: 76,
    annualWageGrowthShortTerm: 0.0234,
    annualWageGrowthLongTerm: 0.0234,
    averageSavingsRate: 0.19,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 140,
    countryCode: 'AE',
    inflationRateShortTerm: 0.0534,
    inflationRateLongTerm: 0.035,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 77,
    lifeExpectancyFemale: 79,
    annualWageGrowthShortTerm: -0.0385,
    annualWageGrowthLongTerm: -0.0385,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 143,
    countryCode: 'UY',
    inflationRateShortTerm: 0.0757,
    inflationRateLongTerm: 0.0799,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 74,
    lifeExpectancyFemale: 81,
    annualWageGrowthShortTerm: 0.012,
    annualWageGrowthLongTerm: 0.012,
    averageSavingsRate: 0.1628,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 144,
    countryCode: 'UZ',
    inflationRateShortTerm: 0.1227,
    inflationRateLongTerm: 0.1118,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 69,
    lifeExpectancyFemale: 74,
    annualWageGrowthShortTerm: 0.0214,
    annualWageGrowthLongTerm: 0.0214,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 145,
    countryCode: 'VU',
    inflationRateShortTerm: 0.0334,
    inflationRateLongTerm: 0.0243,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 70,
    lifeExpectancyFemale: 74,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 146,
    countryCode: 'VN',
    inflationRateShortTerm: 0.1321,
    inflationRateLongTerm: 0.0842,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 72,
    lifeExpectancyFemale: 81,
    annualWageGrowthShortTerm: 0.086,
    annualWageGrowthLongTerm: 0.086,
    averageSavingsRate: 0.244,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 147,
    countryCode: 'YE',
    inflationRateShortTerm: 0.1226,
    inflationRateLongTerm: 0.104,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 64,
    lifeExpectancyFemale: 66,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 148,
    countryCode: 'ZM',
    inflationRateShortTerm: 0.1073,
    inflationRateLongTerm: 0.0996,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 59,
    lifeExpectancyFemale: 64,
    annualWageGrowthShortTerm: 0,
    annualWageGrowthLongTerm: 0,
    averageSavingsRate: 0,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 149,
    countryCode: 'ZW',
    inflationRateShortTerm: 0.1939,
    inflationRateLongTerm: 0.09,
    savingsAccountInterestRate: null,
    retirementAgeMale: null,
    retirementAgeFemale: null,
    lifeExpectancyMale: 59,
    lifeExpectancyFemale: 63,
    annualWageGrowthShortTerm: -0.037,
    annualWageGrowthLongTerm: -0.037,
    averageSavingsRate: -0.0221,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 141,
    countryCode: 'GB',
    inflationRateShortTerm: 0.0317,
    inflationRateLongTerm: 0.0237,
    savingsAccountInterestRate: 0.016,
    retirementAgeMale: 68,
    retirementAgeFemale: 68,
    lifeExpectancyMale: 79,
    lifeExpectancyFemale: 83,
    annualWageGrowthShortTerm: 0.0158,
    annualWageGrowthLongTerm: 0.0158,
    averageSavingsRate: 0.1319,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 118,
    countryCode: 'SG',
    inflationRateShortTerm: 0.0348,
    inflationRateLongTerm: 0.0226,
    savingsAccountInterestRate: 0.0005,
    retirementAgeMale: 65,
    retirementAgeFemale: 65,
    lifeExpectancyMale: 81,
    lifeExpectancyFemale: 85,
    annualWageGrowthShortTerm: 0.0166,
    annualWageGrowthLongTerm: 0.0166,
    averageSavingsRate: 0.4807,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
  {
    id: 142,
    countryCode: 'US',
    inflationRateShortTerm: 0.0132,
    inflationRateLongTerm: 0.017,
    savingsAccountInterestRate: 0.02,
    retirementAgeMale: 66,
    retirementAgeFemale: 66,
    lifeExpectancyMale: 76,
    lifeExpectancyFemale: 81,
    annualWageGrowthShortTerm: 0.0024,
    annualWageGrowthLongTerm: 0.0024,
    averageSavingsRate: 0.081,
    dateCreated: '2019-02-17T21:55:33.000Z',
  },
];

export const bambuApiLibraryIntegrationCountryMetadataApiHandlers = [
  rest.get(
    'http://localhost:9000/api/v1/bambu-api-library-integration/country-metadata-apis/countries',
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json([
          {
            code: 'AD',
            internationalDialingCode: '376',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/and.svg',
            cioc: 'AND',
            name: 'Andorra',
            demonym: 'Andorran',
            cca2: 'AD',
            cca3: 'AND',
            ccn3: '020',
            id: 'AND',
          },
          {
            code: 'AE',
            internationalDialingCode: '971',
            currencyCode: 'AED',
            flag: 'https://restcountries.eu/data/are.svg',
            cioc: 'UAE',
            name: 'United Arab Emirates',
            demonym: 'Emirati',
            cca2: 'AE',
            cca3: 'ARE',
            ccn3: '784',
            id: 'ARE',
          },
          {
            code: 'AF',
            internationalDialingCode: '93',
            currencyCode: 'AFN',
            flag: 'https://restcountries.eu/data/afg.svg',
            cioc: 'AFG',
            name: 'Afghanistan',
            demonym: 'Afghan',
            cca2: 'AF',
            cca3: 'AFG',
            ccn3: '004',
            id: 'AFG',
          },
          {
            code: 'AG',
            internationalDialingCode: '268',
            currencyCode: 'XCD',
            flag: 'https://restcountries.eu/data/atg.svg',
            cioc: 'ANT',
            name: 'Antigua and Barbuda',
            demonym: 'Antiguan, Barbudan',
            cca2: 'AG',
            cca3: 'ATG',
            ccn3: '028',
            id: 'ATG',
          },
          {
            code: 'AI',
            internationalDialingCode: '264',
            currencyCode: 'XCD',
            flag: 'https://restcountries.eu/data/aia.svg',
            cioc: 'AIA',
            name: 'Anguilla',
            demonym: 'Anguillian',
            cca2: 'AI',
            cca3: 'AIA',
            ccn3: '660',
            id: 'AIA',
          },
          {
            code: 'AL',
            internationalDialingCode: '355',
            currencyCode: 'ALL',
            flag: 'https://restcountries.eu/data/alb.svg',
            cioc: 'ALB',
            name: 'Albania',
            demonym: 'Albanian',
            cca2: 'AL',
            cca3: 'ALB',
            ccn3: '008',
            id: 'ALB',
          },
          {
            code: 'AM',
            internationalDialingCode: '374',
            currencyCode: 'AMD',
            flag: 'https://restcountries.eu/data/arm.svg',
            cioc: 'ARM',
            name: 'Armenia',
            demonym: 'Armenian',
            cca2: 'AM',
            cca3: 'ARM',
            ccn3: '051',
            id: 'ARM',
          },
          {
            code: 'AO',
            internationalDialingCode: '244',
            currencyCode: 'AOA',
            flag: 'https://restcountries.eu/data/ago.svg',
            cioc: 'ANG',
            name: 'Angola',
            demonym: 'Angolan',
            cca2: 'AO',
            cca3: 'AGO',
            ccn3: '024',
            id: 'AGO',
          },
          {
            code: 'AQ',
            internationalDialingCode: '672',
            currencyCode: 'AUD',
            flag: 'https://restcountries.eu/data/ata.svg',
            cioc: 'ATA',
            name: 'Antarctica',
            demonym: 'Antarctica',
            cca2: 'AQ',
            cca3: 'ATA',
            ccn3: '010',
            id: 'ATA',
          },
          {
            code: 'AR',
            internationalDialingCode: '54',
            currencyCode: 'ARS',
            flag: 'https://restcountries.eu/data/arg.svg',
            cioc: 'ARG',
            name: 'Argentina',
            demonym: 'Argentinean',
            cca2: 'AR',
            cca3: 'ARG',
            ccn3: '032',
            id: 'ARG',
          },
          {
            code: 'AS',
            internationalDialingCode: '684',
            currencyCode: 'USD',
            flag: 'https://restcountries.eu/data/asm.svg',
            cioc: 'ASA',
            name: 'American Samoa',
            demonym: 'American Samoan',
            cca2: 'AS',
            cca3: 'ASM',
            ccn3: '016',
            id: 'ASM',
          },
          {
            code: 'AT',
            internationalDialingCode: '43',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/aut.svg',
            cioc: 'AUT',
            name: 'Austria',
            demonym: 'Austrian',
            cca2: 'AT',
            cca3: 'AUT',
            ccn3: '040',
            id: 'AUT',
          },
          {
            code: 'AU',
            internationalDialingCode: '61',
            currencyCode: 'AUD',
            flag: 'https://restcountries.eu/data/aus.svg',
            cioc: 'AUS',
            name: 'Australia',
            demonym: 'Australian',
            cca2: 'AU',
            cca3: 'AUS',
            ccn3: '036',
            id: 'AUS',
          },
          {
            code: 'AW',
            internationalDialingCode: '297',
            currencyCode: 'AWG',
            flag: 'https://restcountries.eu/data/abw.svg',
            cioc: 'ARU',
            name: 'Aruba',
            demonym: 'Aruban',
            cca2: 'AW',
            cca3: 'ABW',
            ccn3: '533',
            id: 'ABW',
          },
          {
            code: 'AZ',
            internationalDialingCode: '994',
            currencyCode: 'AZN',
            flag: 'https://restcountries.eu/data/aze.svg',
            cioc: 'AZE',
            name: 'Azerbaijan',
            demonym: 'Azerbaijani',
            cca2: 'AZ',
            cca3: 'AZE',
            ccn3: '031',
            id: 'AZE',
          },
          {
            code: 'BA',
            internationalDialingCode: '387',
            currencyCode: 'BAM',
            flag: 'https://restcountries.eu/data/bih.svg',
            cioc: 'BIH',
            name: 'Bosnia and Herzegovina',
            demonym: 'Bosnian',
            cca2: 'BA',
            cca3: 'BIH',
            ccn3: '070',
            id: 'BIH',
          },
          {
            code: 'BB',
            internationalDialingCode: '246',
            currencyCode: 'BBD',
            flag: 'https://restcountries.eu/data/brb.svg',
            cioc: 'BAR',
            name: 'Barbados',
            demonym: 'Barbadian',
            cca2: 'BB',
            cca3: 'BRB',
            ccn3: '052',
            id: 'BRB',
          },
          {
            code: 'BD',
            internationalDialingCode: '880',
            currencyCode: 'BDT',
            flag: 'https://restcountries.eu/data/bgd.svg',
            cioc: 'BAN',
            name: 'Bangladesh',
            demonym: 'Bangladeshi',
            cca2: 'BD',
            cca3: 'BGD',
            ccn3: '050',
            id: 'BGD',
          },
          {
            code: 'BE',
            internationalDialingCode: '32',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/bel.svg',
            cioc: 'BEL',
            name: 'Belgium',
            demonym: 'Belgian',
            cca2: 'BE',
            cca3: 'BEL',
            ccn3: '056',
            id: 'BEL',
          },
          {
            code: 'BF',
            internationalDialingCode: '226',
            currencyCode: 'XOF',
            flag: 'https://restcountries.eu/data/bfa.svg',
            cioc: 'BUR',
            name: 'Burkina Faso',
            demonym: 'Burkinabe',
            cca2: 'BF',
            cca3: 'BFA',
            ccn3: '854',
            id: 'BFA',
          },
          {
            code: 'BG',
            internationalDialingCode: '359',
            currencyCode: 'BGN',
            flag: 'https://restcountries.eu/data/bgr.svg',
            cioc: 'BUL',
            name: 'Bulgaria',
            demonym: 'Bulgarian',
            cca2: 'BG',
            cca3: 'BGR',
            ccn3: '100',
            id: 'BGR',
          },
          {
            code: 'BH',
            internationalDialingCode: '973',
            currencyCode: 'BHD',
            flag: 'https://restcountries.eu/data/bhr.svg',
            cioc: 'BRN',
            name: 'Bahrain',
            demonym: 'Bahraini',
            cca2: 'BH',
            cca3: 'BHR',
            ccn3: '048',
            id: 'BHR',
          },
          {
            code: 'BI',
            internationalDialingCode: '257',
            currencyCode: 'BIF',
            flag: 'https://restcountries.eu/data/bdi.svg',
            cioc: 'BDI',
            name: 'Burundi',
            demonym: 'Burundian',
            cca2: 'BI',
            cca3: 'BDI',
            ccn3: '108',
            id: 'BDI',
          },
          {
            code: 'BJ',
            internationalDialingCode: '229',
            currencyCode: 'XOF',
            flag: 'https://restcountries.eu/data/ben.svg',
            cioc: 'BEN',
            name: 'Benin',
            demonym: 'Beninese',
            cca2: 'BJ',
            cca3: 'BEN',
            ccn3: '204',
            id: 'BEN',
          },
          {
            code: 'BL',
            internationalDialingCode: '590',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/blm.svg',
            cioc: 'BLM',
            name: 'Saint Barthelemy',
            demonym: 'Saint Barthelemy',
            cca2: 'BL',
            cca3: 'BLM',
            ccn3: '652',
            id: 'BLM',
          },
          {
            code: 'BM',
            internationalDialingCode: '441',
            currencyCode: 'BMD',
            flag: 'https://restcountries.eu/data/bmu.svg',
            cioc: 'BER',
            name: 'Bermuda',
            demonym: 'Bermudian',
            cca2: 'BM',
            cca3: 'BMU',
            ccn3: '060',
            id: 'BMU',
          },
          {
            code: 'BN',
            internationalDialingCode: '673',
            currencyCode: 'BND',
            flag: 'https://restcountries.eu/data/brn.svg',
            cioc: 'BRU',
            name: 'Brunei Darussalam',
            demonym: 'Bruneian',
            cca2: 'BN',
            cca3: 'BRN',
            ccn3: '096',
            id: 'BRN',
          },
          {
            code: 'BO',
            internationalDialingCode: '591',
            currencyCode: 'BOB',
            flag: 'https://restcountries.eu/data/bol.svg',
            cioc: 'BOL',
            name: 'Bolivia',
            demonym: 'Bolivian',
            cca2: 'BO',
            cca3: 'BOL',
            ccn3: '068',
            id: 'BOL',
          },
          {
            code: 'BR',
            internationalDialingCode: '55',
            currencyCode: 'BRL',
            flag: 'https://restcountries.eu/data/bra.svg',
            cioc: 'BRA',
            name: 'Brazil',
            demonym: 'Brazilian',
            cca2: 'BR',
            cca3: 'BRA',
            ccn3: '076',
            id: 'BRA',
          },
          {
            code: 'BS',
            internationalDialingCode: '242',
            currencyCode: 'BSD',
            flag: 'https://restcountries.eu/data/bhs.svg',
            cioc: 'BAH',
            name: 'Bahamas',
            demonym: 'Bahamian',
            cca2: 'BS',
            cca3: 'BHS',
            ccn3: '044',
            id: 'BHS',
          },
          {
            code: 'BT',
            internationalDialingCode: '975',
            currencyCode: 'BTN',
            flag: 'https://restcountries.eu/data/btn.svg',
            cioc: 'BHU',
            name: 'Bhutan',
            demonym: 'Bhutanese',
            cca2: 'BT',
            cca3: 'BTN',
            ccn3: '064',
            id: 'BTN',
          },
          {
            code: 'BW',
            internationalDialingCode: '267',
            currencyCode: 'BWP',
            flag: 'https://restcountries.eu/data/bwa.svg',
            cioc: 'BOT',
            name: 'Botswana',
            demonym: 'Motswana',
            cca2: 'BW',
            cca3: 'BWA',
            ccn3: '072',
            id: 'BWA',
          },
          {
            code: 'BY',
            internationalDialingCode: '375',
            currencyCode: 'BYN',
            flag: 'https://restcountries.eu/data/blr.svg',
            cioc: 'BLR',
            name: 'Belarus',
            demonym: 'Belarusian',
            cca2: 'BY',
            cca3: 'BLR',
            ccn3: '112',
            id: 'BLR',
          },
          {
            code: 'BZ',
            internationalDialingCode: '501',
            currencyCode: 'BZD',
            flag: 'https://restcountries.eu/data/blz.svg',
            cioc: 'BIZ',
            name: 'Belize',
            demonym: 'Belizean',
            cca2: 'BZ',
            cca3: 'BLZ',
            ccn3: '084',
            id: 'BLZ',
          },
          {
            code: 'CA',
            internationalDialingCode: '1',
            currencyCode: 'CAD',
            flag: 'https://restcountries.eu/data/can.svg',
            cioc: 'CAN',
            name: 'Canada',
            demonym: 'Canadian',
            cca2: 'CA',
            cca3: 'CAN',
            ccn3: '124',
            id: 'CAN',
          },
          {
            code: 'CC',
            internationalDialingCode: '61',
            currencyCode: 'AUD',
            flag: 'https://restcountries.eu/data/cck.svg',
            cioc: 'CCK',
            name: 'Cocos (Keeling) Islands',
            demonym: 'Cocos Islander',
            cca2: 'CC',
            cca3: 'CCK',
            ccn3: '166',
            id: 'CCK',
          },
          {
            code: 'CD',
            internationalDialingCode: '243',
            currencyCode: 'CDF',
            flag: 'https://restcountries.eu/data/cod.svg',
            cioc: 'COD',
            name: 'Congo',
            demonym: 'Congolese',
            cca2: 'CD',
            cca3: 'COD',
            ccn3: '180',
            id: 'COD',
          },
          {
            code: 'CF',
            internationalDialingCode: '236',
            currencyCode: 'XAF',
            flag: 'https://restcountries.eu/data/caf.svg',
            cioc: 'CAF',
            name: 'Central African Republic',
            demonym: 'Central African',
            cca2: 'CF',
            cca3: 'CAF',
            ccn3: '140',
            id: 'CAF',
          },
          {
            code: 'CG',
            internationalDialingCode: '242',
            currencyCode: 'XAF',
            flag: 'https://restcountries.eu/data/cog.svg',
            cioc: 'CGO',
            name: 'Congo',
            demonym: 'Congolese',
            cca2: 'CG',
            cca3: 'COG',
            ccn3: '178',
            id: 'COG',
          },
          {
            code: 'CH',
            internationalDialingCode: '41',
            currencyCode: 'CHF',
            flag: 'https://restcountries.eu/data/che.svg',
            cioc: 'SUI',
            name: 'Switzerland',
            demonym: 'Swiss',
            cca2: 'CH',
            cca3: 'CHE',
            ccn3: '756',
            id: 'CHE',
          },
          {
            code: 'CK',
            internationalDialingCode: '682',
            currencyCode: 'NZD',
            flag: 'https://restcountries.eu/data/cok.svg',
            cioc: 'COK',
            name: 'Cook Islands',
            demonym: 'Cook Islander',
            cca2: 'CK',
            cca3: 'COK',
            ccn3: '184',
            id: 'COK',
          },
          {
            code: 'CL',
            internationalDialingCode: '56',
            currencyCode: 'CLP',
            flag: 'https://restcountries.eu/data/chl.svg',
            cioc: 'CHI',
            name: 'Chile',
            demonym: 'Chilean',
            cca2: 'CL',
            cca3: 'CHL',
            ccn3: '152',
            id: 'CHL',
          },
          {
            code: 'CM',
            internationalDialingCode: '237',
            currencyCode: 'XAF',
            flag: 'https://restcountries.eu/data/cmr.svg',
            cioc: 'CMR',
            name: 'Cameroon',
            demonym: 'Cameroonian',
            cca2: 'CM',
            cca3: 'CMR',
            ccn3: '120',
            id: 'CMR',
          },
          {
            code: 'CN',
            internationalDialingCode: '86',
            currencyCode: 'CNY',
            flag: 'https://restcountries.eu/data/chn.svg',
            cioc: 'CHN',
            name: 'China',
            demonym: 'Chinese',
            cca2: 'CN',
            cca3: 'CHN',
            ccn3: '156',
            id: 'CHN',
          },
          {
            code: 'CO',
            internationalDialingCode: '57',
            currencyCode: 'COP',
            flag: 'https://restcountries.eu/data/col.svg',
            cioc: 'COL',
            name: 'Colombia',
            demonym: 'Colombian',
            cca2: 'CO',
            cca3: 'COL',
            ccn3: '170',
            id: 'COL',
          },
          {
            code: 'CR',
            internationalDialingCode: '506',
            currencyCode: 'CRC',
            flag: 'https://restcountries.eu/data/cri.svg',
            cioc: 'CRC',
            name: 'Costa Rica',
            demonym: 'Costa Rican',
            cca2: 'CR',
            cca3: 'CRI',
            ccn3: '188',
            id: 'CRI',
          },
          {
            code: 'CU',
            internationalDialingCode: '53',
            currencyCode: 'CUC',
            flag: 'https://restcountries.eu/data/cub.svg',
            cioc: 'CUB',
            name: 'Cuba',
            demonym: 'Cuban',
            cca2: 'CU',
            cca3: 'CUB',
            ccn3: '192',
            id: 'CUB',
          },
          {
            code: 'CV',
            internationalDialingCode: '238',
            currencyCode: 'CVE',
            flag: 'https://restcountries.eu/data/cpv.svg',
            cioc: 'CPV',
            name: 'Cabo Verde',
            demonym: 'Cape Verdian',
            cca2: 'CV',
            cca3: 'CPV',
            ccn3: '132',
            id: 'CPV',
          },
          {
            code: 'CW',
            internationalDialingCode: '599',
            currencyCode: 'ANG',
            flag: 'https://restcountries.eu/data/cuw.svg',
            cioc: 'CUW',
            name: 'Curacao',
            demonym: 'Dutch',
            cca2: 'CW',
            cca3: 'CUW',
            ccn3: '531',
            id: 'CUW',
          },
          {
            code: 'CX',
            internationalDialingCode: '61',
            currencyCode: 'AUD',
            flag: 'https://restcountries.eu/data/cxr.svg',
            cioc: 'CXR',
            name: 'Christmas Island',
            demonym: 'Christmas Island',
            cca2: 'CX',
            cca3: 'CXR',
            ccn3: '162',
            id: 'CXR',
          },
          {
            code: 'CY',
            internationalDialingCode: '357',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/cyp.svg',
            cioc: 'CYP',
            name: 'Cyprus',
            demonym: 'Cypriot',
            cca2: 'CY',
            cca3: 'CYP',
            ccn3: '196',
            id: 'CYP',
          },
          {
            code: 'CZ',
            internationalDialingCode: '420',
            currencyCode: 'CZK',
            flag: 'https://restcountries.eu/data/cze.svg',
            cioc: 'CZE',
            name: 'Czech Republic',
            demonym: 'Czech',
            cca2: 'CZ',
            cca3: 'CZE',
            ccn3: '203',
            id: 'CZE',
          },
          {
            code: 'DE',
            internationalDialingCode: '49',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/deu.svg',
            cioc: 'GER',
            name: 'Germany',
            demonym: 'German',
            cca2: 'DE',
            cca3: 'DEU',
            ccn3: '276',
            id: 'DEU',
          },
          {
            code: 'DJ',
            internationalDialingCode: '253',
            currencyCode: 'DJF',
            flag: 'https://restcountries.eu/data/dji.svg',
            cioc: 'DJI',
            name: 'Djibouti',
            demonym: 'Djibouti',
            cca2: 'DJ',
            cca3: 'DJI',
            ccn3: '262',
            id: 'DJI',
          },
          {
            code: 'DK',
            internationalDialingCode: '45',
            currencyCode: 'DKK',
            flag: 'https://restcountries.eu/data/dnk.svg',
            cioc: 'DEN',
            name: 'Denmark',
            demonym: 'Danish',
            cca2: 'DK',
            cca3: 'DNK',
            ccn3: '208',
            id: 'DNK',
          },
          {
            code: 'DM',
            internationalDialingCode: '767',
            currencyCode: 'XCD',
            flag: 'https://restcountries.eu/data/dma.svg',
            cioc: 'DMA',
            name: 'Dominica',
            demonym: 'Dominican',
            cca2: 'DM',
            cca3: 'DMA',
            ccn3: '212',
            id: 'DMA',
          },
          {
            code: 'DZ',
            internationalDialingCode: '213',
            currencyCode: 'DZD',
            flag: 'https://restcountries.eu/data/dza.svg',
            cioc: 'ALG',
            name: 'Algeria',
            demonym: 'Algerian',
            cca2: 'DZ',
            cca3: 'DZA',
            ccn3: '012',
            id: 'DZA',
          },
          {
            code: 'EC',
            internationalDialingCode: '593',
            currencyCode: 'USD',
            flag: 'https://restcountries.eu/data/ecu.svg',
            cioc: 'ECU',
            name: 'Ecuador',
            demonym: 'Ecuadorean',
            cca2: 'EC',
            cca3: 'ECU',
            ccn3: '218',
            id: 'ECU',
          },
          {
            code: 'EE',
            internationalDialingCode: '372',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/est.svg',
            cioc: 'EST',
            name: 'Estonia',
            demonym: 'Estonian',
            cca2: 'EE',
            cca3: 'EST',
            ccn3: '233',
            id: 'EST',
          },
          {
            code: 'EG',
            internationalDialingCode: '20',
            currencyCode: 'EGP',
            flag: 'https://restcountries.eu/data/egy.svg',
            cioc: 'EGY',
            name: 'Egypt',
            demonym: 'Egyptian',
            cca2: 'EG',
            cca3: 'EGY',
            ccn3: '818',
            id: 'EGY',
          },
          {
            code: 'EH',
            internationalDialingCode: '212',
            currencyCode: 'MAD',
            flag: 'https://restcountries.eu/data/esh.svg',
            cioc: 'ESH',
            name: 'Western Sahara',
            demonym: 'Sahrawi',
            cca2: 'EH',
            cca3: 'ESH',
            ccn3: '732',
            id: 'ESH',
          },
          {
            code: 'ER',
            internationalDialingCode: '291',
            currencyCode: 'ERN',
            flag: 'https://restcountries.eu/data/eri.svg',
            cioc: 'ERI',
            name: 'Eritrea',
            demonym: 'Eritrean',
            cca2: 'ER',
            cca3: 'ERI',
            ccn3: '232',
            id: 'ERI',
          },
          {
            code: 'ES',
            internationalDialingCode: '34',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/esp.svg',
            cioc: 'ESP',
            name: 'Spain',
            demonym: 'Spanish',
            cca2: 'ES',
            cca3: 'ESP',
            ccn3: '724',
            id: 'ESP',
          },
          {
            code: 'ET',
            internationalDialingCode: '251',
            currencyCode: 'ETB',
            flag: 'https://restcountries.eu/data/eth.svg',
            cioc: 'ETH',
            name: 'Ethiopia',
            demonym: 'Ethiopian',
            cca2: 'ET',
            cca3: 'ETH',
            ccn3: '231',
            id: 'ETH',
          },
          {
            code: 'FI',
            internationalDialingCode: '358',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/fin.svg',
            cioc: 'FIN',
            name: 'Finland',
            demonym: 'Finnish',
            cca2: 'FI',
            cca3: 'FIN',
            ccn3: '246',
            id: 'FIN',
          },
          {
            code: 'FJ',
            internationalDialingCode: '679',
            currencyCode: 'FJD',
            flag: 'https://restcountries.eu/data/fji.svg',
            cioc: 'FIJ',
            name: 'Fiji',
            demonym: 'Fijian',
            cca2: 'FJ',
            cca3: 'FJI',
            ccn3: '242',
            id: 'FJI',
          },
          {
            code: 'FK',
            internationalDialingCode: '500',
            currencyCode: 'FKP',
            flag: 'https://restcountries.eu/data/flk.svg',
            cioc: 'FLK',
            name: 'Falkland Islands',
            demonym: 'Falkland Islander',
            cca2: 'FK',
            cca3: 'FLK',
            ccn3: '238',
            id: 'FLK',
          },
          {
            code: 'FM',
            internationalDialingCode: '691',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/fsm.svg',
            cioc: 'FSM',
            name: 'Micronesia',
            demonym: 'Micronesian',
            cca2: 'FM',
            cca3: 'FSM',
            ccn3: '583',
            id: 'FSM',
          },
          {
            code: 'FO',
            internationalDialingCode: '298',
            currencyCode: 'DKK',
            flag: 'https://restcountries.eu/data/fro.svg',
            cioc: 'FRO',
            name: 'Faroe Islands',
            demonym: 'Faroese',
            cca2: 'FO',
            cca3: 'FRO',
            ccn3: '234',
            id: 'FRO',
          },
          {
            code: 'FR',
            internationalDialingCode: '33',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/fra.svg',
            cioc: 'FRA',
            name: 'France',
            demonym: 'French',
            cca2: 'FR',
            cca3: 'FRA',
            ccn3: '250',
            id: 'FRA',
          },
          {
            code: 'GA',
            internationalDialingCode: '241',
            currencyCode: 'XAF',
            flag: 'https://restcountries.eu/data/gab.svg',
            cioc: 'GAB',
            name: 'Gabon',
            demonym: 'Gabonese',
            cca2: 'GA',
            cca3: 'GAB',
            ccn3: '266',
            id: 'GAB',
          },
          {
            code: 'GB',
            internationalDialingCode: '44',
            currencyCode: 'GBP',
            flag: 'https://restcountries.eu/data/gbr.svg',
            cioc: 'GBR',
            name: 'United Kingdom',
            demonym: 'British',
            cca2: 'GB',
            cca3: 'GBR',
            ccn3: '826',
            id: 'GBR',
          },
          {
            code: 'GD',
            internationalDialingCode: '473',
            currencyCode: 'XCD',
            flag: 'https://restcountries.eu/data/grd.svg',
            cioc: 'GRN',
            name: 'Grenada',
            demonym: 'Grenadian',
            cca2: 'GD',
            cca3: 'GRD',
            ccn3: '308',
            id: 'GRD',
          },
          {
            code: 'GE',
            internationalDialingCode: '995',
            currencyCode: 'GEL',
            flag: 'https://restcountries.eu/data/geo.svg',
            cioc: 'GEO',
            name: 'Georgia',
            demonym: 'Georgian',
            cca2: 'GE',
            cca3: 'GEO',
            ccn3: '268',
            id: 'GEO',
          },
          {
            code: 'GH',
            internationalDialingCode: '233',
            currencyCode: 'GHS',
            flag: 'https://restcountries.eu/data/gha.svg',
            cioc: 'GHA',
            name: 'Ghana',
            demonym: 'Ghanaian',
            cca2: 'GH',
            cca3: 'GHA',
            ccn3: '288',
            id: 'GHA',
          },
          {
            code: 'GI',
            internationalDialingCode: '350',
            currencyCode: 'GIP',
            flag: 'https://restcountries.eu/data/gib.svg',
            cioc: 'GIB',
            name: 'Gibraltar',
            demonym: 'Gibraltar',
            cca2: 'GI',
            cca3: 'GIB',
            ccn3: '292',
            id: 'GIB',
          },
          {
            code: 'GL',
            internationalDialingCode: '299',
            currencyCode: 'DKK',
            flag: 'https://restcountries.eu/data/grl.svg',
            cioc: 'GRL',
            name: 'Greenland',
            demonym: 'Greenlandic',
            cca2: 'GL',
            cca3: 'GRL',
            ccn3: '304',
            id: 'GRL',
          },
          {
            code: 'GM',
            internationalDialingCode: '220',
            currencyCode: 'GMD',
            flag: 'https://restcountries.eu/data/gmb.svg',
            cioc: 'GAM',
            name: 'Gambia',
            demonym: 'Gambian',
            cca2: 'GM',
            cca3: 'GMB',
            ccn3: '270',
            id: 'GMB',
          },
          {
            code: 'GN',
            internationalDialingCode: '224',
            currencyCode: 'GNF',
            flag: 'https://restcountries.eu/data/gin.svg',
            cioc: 'GUI',
            name: 'Guinea',
            demonym: 'Guinean',
            cca2: 'GN',
            cca3: 'GIN',
            ccn3: '324',
            id: 'GIN',
          },
          {
            code: 'GQ',
            internationalDialingCode: '240',
            currencyCode: 'XAF',
            flag: 'https://restcountries.eu/data/gnq.svg',
            cioc: 'GEQ',
            name: 'Equatorial Guinea',
            demonym: 'Equatorial Guinean',
            cca2: 'GQ',
            cca3: 'GNQ',
            ccn3: '226',
            id: 'GNQ',
          },
          {
            code: 'GR',
            internationalDialingCode: '30',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/grc.svg',
            cioc: 'GRE',
            name: 'Greece',
            demonym: 'Greek',
            cca2: 'GR',
            cca3: 'GRC',
            ccn3: '300',
            id: 'GRC',
          },
          {
            code: 'GT',
            internationalDialingCode: '502',
            currencyCode: 'GTQ',
            flag: 'https://restcountries.eu/data/gtm.svg',
            cioc: 'GUA',
            name: 'Guatemala',
            demonym: 'Guatemalan',
            cca2: 'GT',
            cca3: 'GTM',
            ccn3: '320',
            id: 'GTM',
          },
          {
            code: 'GU',
            internationalDialingCode: '671',
            currencyCode: 'USD',
            flag: 'https://restcountries.eu/data/gum.svg',
            cioc: 'GUM',
            name: 'Guam',
            demonym: 'Guamanian',
            cca2: 'GU',
            cca3: 'GUM',
            ccn3: '316',
            id: 'GUM',
          },
          {
            code: 'GW',
            internationalDialingCode: '245',
            currencyCode: 'XOF',
            flag: 'https://restcountries.eu/data/gnb.svg',
            cioc: 'GBS',
            name: 'Guinea-Bissau',
            demonym: 'Guinea-Bissauan',
            cca2: 'GW',
            cca3: 'GNB',
            ccn3: '624',
            id: 'GNB',
          },
          {
            code: 'GY',
            internationalDialingCode: '592',
            currencyCode: 'GYD',
            flag: 'https://restcountries.eu/data/guy.svg',
            cioc: 'GUY',
            name: 'Guyana',
            demonym: 'Guyanese',
            cca2: 'GY',
            cca3: 'GUY',
            ccn3: '328',
            id: 'GUY',
          },
          {
            code: 'HK',
            internationalDialingCode: '852',
            currencyCode: 'HKD',
            flag: 'https://restcountries.eu/data/hkg.svg',
            cioc: 'HKG',
            name: 'Hong Kong',
            demonym: 'Chinese',
            cca2: 'HK',
            cca3: 'HKG',
            ccn3: '344',
            id: 'HKG',
          },
          {
            code: 'HN',
            internationalDialingCode: '504',
            currencyCode: 'HNL',
            flag: 'https://restcountries.eu/data/hnd.svg',
            cioc: 'HON',
            name: 'Honduras',
            demonym: 'Honduran',
            cca2: 'HN',
            cca3: 'HND',
            ccn3: '340',
            id: 'HND',
          },
          {
            code: 'HR',
            internationalDialingCode: '385',
            currencyCode: 'HRK',
            flag: 'https://restcountries.eu/data/hrv.svg',
            cioc: 'CRO',
            name: 'Croatia',
            demonym: 'Croatian',
            cca2: 'HR',
            cca3: 'HRV',
            ccn3: '191',
            id: 'HRV',
          },
          {
            code: 'HT',
            internationalDialingCode: '509',
            currencyCode: 'HTG',
            flag: 'https://restcountries.eu/data/hti.svg',
            cioc: 'HAI',
            name: 'Haiti',
            demonym: 'Haitian',
            cca2: 'HT',
            cca3: 'HTI',
            ccn3: '332',
            id: 'HTI',
          },
          {
            code: 'HU',
            internationalDialingCode: '36',
            currencyCode: 'HUF',
            flag: 'https://restcountries.eu/data/hun.svg',
            cioc: 'HUN',
            name: 'Hungary',
            demonym: 'Hungarian',
            cca2: 'HU',
            cca3: 'HUN',
            ccn3: '348',
            id: 'HUN',
          },
          {
            code: 'ID',
            internationalDialingCode: '62',
            currencyCode: 'IDR',
            flag: 'https://restcountries.eu/data/idn.svg',
            cioc: 'INA',
            name: 'Indonesia',
            demonym: 'Indonesian',
            cca2: 'ID',
            cca3: 'IDN',
            ccn3: '360',
            id: 'IDN',
          },
          {
            code: 'IE',
            internationalDialingCode: '353',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/irl.svg',
            cioc: 'IRL',
            name: 'Ireland',
            demonym: 'Irish',
            cca2: 'IE',
            cca3: 'IRL',
            ccn3: '372',
            id: 'IRL',
          },
          {
            code: 'IL',
            internationalDialingCode: '972',
            currencyCode: 'ILS',
            flag: 'https://restcountries.eu/data/isr.svg',
            cioc: 'ISR',
            name: 'Israel',
            demonym: 'Israeli',
            cca2: 'IL',
            cca3: 'ISR',
            ccn3: '376',
            id: 'ISR',
          },
          {
            code: 'IN',
            internationalDialingCode: '91',
            currencyCode: 'INR',
            flag: 'https://restcountries.eu/data/ind.svg',
            cioc: 'IND',
            name: 'India',
            demonym: 'Indian',
            cca2: 'IN',
            cca3: 'IND',
            ccn3: '356',
            id: 'IND',
          },
          {
            code: 'IO',
            internationalDialingCode: '246',
            currencyCode: 'USD',
            flag: 'https://restcountries.eu/data/iot.svg',
            cioc: 'IOT',
            name: 'British Indian Ocean Territory',
            demonym: 'Indian',
            cca2: 'IO',
            cca3: 'IOT',
            ccn3: '086',
            id: 'IOT',
          },
          {
            code: 'IQ',
            internationalDialingCode: '964',
            currencyCode: 'IQD',
            flag: 'https://restcountries.eu/data/irq.svg',
            cioc: 'IRQ',
            name: 'Iraq',
            demonym: 'Iraqi',
            cca2: 'IQ',
            cca3: 'IRQ',
            ccn3: '368',
            id: 'IRQ',
          },
          {
            code: 'IR',
            internationalDialingCode: '98',
            currencyCode: 'IRR',
            flag: 'https://restcountries.eu/data/irn.svg',
            cioc: 'IRI',
            name: 'Iran',
            demonym: 'Iranian',
            cca2: 'IR',
            cca3: 'IRN',
            ccn3: '364',
            id: 'IRN',
          },
          {
            code: 'IS',
            internationalDialingCode: '354',
            currencyCode: 'ISK',
            flag: 'https://restcountries.eu/data/isl.svg',
            cioc: 'ISL',
            name: 'Iceland',
            demonym: 'Icelander',
            cca2: 'IS',
            cca3: 'ISL',
            ccn3: '352',
            id: 'ISL',
          },
          {
            code: 'IT',
            internationalDialingCode: '39',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/ita.svg',
            cioc: 'ITA',
            name: 'Italy',
            demonym: 'Italian',
            cca2: 'IT',
            cca3: 'ITA',
            ccn3: '380',
            id: 'ITA',
          },
          {
            code: 'JM',
            internationalDialingCode: '876',
            currencyCode: 'JMD',
            flag: 'https://restcountries.eu/data/jam.svg',
            cioc: 'JAM',
            name: 'Jamaica',
            demonym: 'Jamaican',
            cca2: 'JM',
            cca3: 'JAM',
            ccn3: '388',
            id: 'JAM',
          },
          {
            code: 'JO',
            internationalDialingCode: '962',
            currencyCode: 'JOD',
            flag: 'https://restcountries.eu/data/jor.svg',
            cioc: 'JOR',
            name: 'Jordan',
            demonym: 'Jordanian',
            cca2: 'JO',
            cca3: 'JOR',
            ccn3: '400',
            id: 'JOR',
          },
          {
            code: 'JP',
            internationalDialingCode: '81',
            currencyCode: 'JPY',
            flag: 'https://restcountries.eu/data/jpn.svg',
            cioc: 'JPN',
            name: 'Japan',
            demonym: 'Japanese',
            cca2: 'JP',
            cca3: 'JPN',
            ccn3: '392',
            id: 'JPN',
          },
          {
            code: 'KE',
            internationalDialingCode: '254',
            currencyCode: 'KES',
            flag: 'https://restcountries.eu/data/ken.svg',
            cioc: 'KEN',
            name: 'Kenya',
            demonym: 'Kenyan',
            cca2: 'KE',
            cca3: 'KEN',
            ccn3: '404',
            id: 'KEN',
          },
          {
            code: 'KG',
            internationalDialingCode: '996',
            currencyCode: 'KGS',
            flag: 'https://restcountries.eu/data/kgz.svg',
            cioc: 'KGZ',
            name: 'Kyrgyzstan',
            demonym: 'Kirghiz',
            cca2: 'KG',
            cca3: 'KGZ',
            ccn3: '417',
            id: 'KGZ',
          },
          {
            code: 'KH',
            internationalDialingCode: '855',
            currencyCode: 'KHR',
            flag: 'https://restcountries.eu/data/khm.svg',
            cioc: 'CAM',
            name: 'Cambodia',
            demonym: 'Cambodian',
            cca2: 'KH',
            cca3: 'KHM',
            ccn3: '116',
            id: 'KHM',
          },
          {
            code: 'KI',
            internationalDialingCode: '686',
            currencyCode: 'AUD',
            flag: 'https://restcountries.eu/data/kir.svg',
            cioc: 'KIR',
            name: 'Kiribati',
            demonym: 'I-Kiribati',
            cca2: 'KI',
            cca3: 'KIR',
            ccn3: '296',
            id: 'KIR',
          },
          {
            code: 'KM',
            internationalDialingCode: '269',
            currencyCode: 'KMF',
            flag: 'https://restcountries.eu/data/com.svg',
            cioc: 'COM',
            name: 'Comoros',
            demonym: 'Comoran',
            cca2: 'KM',
            cca3: 'COM',
            ccn3: '174',
            id: 'COM',
          },
          {
            code: 'KN',
            internationalDialingCode: '869',
            currencyCode: 'XCD',
            flag: 'https://restcountries.eu/data/kna.svg',
            cioc: 'SKN',
            name: 'Saint Kitts and Nevis',
            demonym: 'Kittian and Nevisian',
            cca2: 'KN',
            cca3: 'KNA',
            ccn3: '659',
            id: 'KNA',
          },
          {
            code: 'KP',
            internationalDialingCode: '850',
            currencyCode: 'KPW',
            flag: 'https://restcountries.eu/data/prk.svg',
            cioc: 'PRK',
            name: 'North Korea',
            demonym: 'North Korean',
            cca2: 'KP',
            cca3: 'PRK',
            ccn3: '408',
            id: 'PRK',
          },
          {
            code: 'KR',
            internationalDialingCode: '82',
            currencyCode: 'KRW',
            flag: 'https://restcountries.eu/data/kor.svg',
            cioc: 'KOR',
            name: 'Korea (Republic of)',
            demonym: 'South Korean',
            cca2: 'KR',
            cca3: 'KOR',
            ccn3: '410',
            id: 'KOR',
          },
          {
            code: 'KW',
            internationalDialingCode: '965',
            currencyCode: 'KWD',
            flag: 'https://restcountries.eu/data/kwt.svg',
            cioc: 'KUW',
            name: 'Kuwait',
            demonym: 'Kuwaiti',
            cca2: 'KW',
            cca3: 'KWT',
            ccn3: '414',
            id: 'KWT',
          },
          {
            code: 'KY',
            internationalDialingCode: '345',
            currencyCode: 'KYD',
            flag: 'https://restcountries.eu/data/cym.svg',
            cioc: 'CAY',
            name: 'Cayman Islands',
            demonym: 'Caymanian',
            cca2: 'KY',
            cca3: 'CYM',
            ccn3: '136',
            id: 'CYM',
          },
          {
            code: 'KZ',
            internationalDialingCode: '7',
            currencyCode: 'KZT',
            flag: 'https://restcountries.eu/data/kaz.svg',
            cioc: 'KAZ',
            name: 'Kazakhstan',
            demonym: 'Kazakhstani',
            cca2: 'KZ',
            cca3: 'KAZ',
            ccn3: '398',
            id: 'KAZ',
          },
          {
            code: 'LA',
            internationalDialingCode: '856',
            currencyCode: 'LAK',
            flag: 'https://restcountries.eu/data/lao.svg',
            cioc: 'LAO',
            name: 'Lao',
            demonym: 'Laotian',
            cca2: 'LA',
            cca3: 'LAO',
            ccn3: '418',
            id: 'LAO',
          },
          {
            code: 'LB',
            internationalDialingCode: '961',
            currencyCode: 'LBP',
            flag: 'https://restcountries.eu/data/lbn.svg',
            cioc: 'LIB',
            name: 'Lebanon',
            demonym: 'Lebanese',
            cca2: 'LB',
            cca3: 'LBN',
            ccn3: '422',
            id: 'LBN',
          },
          {
            code: 'LC',
            internationalDialingCode: '758',
            currencyCode: 'XCD',
            flag: 'https://restcountries.eu/data/lca.svg',
            cioc: 'LCA',
            name: 'Saint Lucia',
            demonym: 'Saint Lucian',
            cca2: 'LC',
            cca3: 'LCA',
            ccn3: '662',
            id: 'LCA',
          },
          {
            code: 'LI',
            internationalDialingCode: '423',
            currencyCode: 'CHF',
            flag: 'https://restcountries.eu/data/lie.svg',
            cioc: 'LIE',
            name: 'Liechtenstein',
            demonym: 'Liechtensteiner',
            cca2: 'LI',
            cca3: 'LIE',
            ccn3: '438',
            id: 'LIE',
          },
          {
            code: 'LK',
            internationalDialingCode: '94',
            currencyCode: 'LKR',
            flag: 'https://restcountries.eu/data/lka.svg',
            cioc: 'SRI',
            name: 'Sri Lanka',
            demonym: 'Sri Lankan',
            cca2: 'LK',
            cca3: 'LKA',
            ccn3: '144',
            id: 'LKA',
          },
          {
            code: 'LR',
            internationalDialingCode: '231',
            currencyCode: 'LRD',
            flag: 'https://restcountries.eu/data/lbr.svg',
            cioc: 'LBR',
            name: 'Liberia',
            demonym: 'Liberian',
            cca2: 'LR',
            cca3: 'LBR',
            ccn3: '430',
            id: 'LBR',
          },
          {
            code: 'LS',
            internationalDialingCode: '266',
            currencyCode: 'LSL',
            flag: 'https://restcountries.eu/data/lso.svg',
            cioc: 'LES',
            name: 'Lesotho',
            demonym: 'Mosotho',
            cca2: 'LS',
            cca3: 'LSO',
            ccn3: '426',
            id: 'LSO',
          },
          {
            code: 'LT',
            internationalDialingCode: '370',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/ltu.svg',
            cioc: 'LTU',
            name: 'Lithuania',
            demonym: 'Lithuanian',
            cca2: 'LT',
            cca3: 'LTU',
            ccn3: '440',
            id: 'LTU',
          },
          {
            code: 'LU',
            internationalDialingCode: '352',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/lux.svg',
            cioc: 'LUX',
            name: 'Luxembourg',
            demonym: 'Luxembourger',
            cca2: 'LU',
            cca3: 'LUX',
            ccn3: '442',
            id: 'LUX',
          },
          {
            code: 'LV',
            internationalDialingCode: '371',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/lva.svg',
            cioc: 'LAT',
            name: 'Latvia',
            demonym: 'Latvian',
            cca2: 'LV',
            cca3: 'LVA',
            ccn3: '428',
            id: 'LVA',
          },
          {
            code: 'LY',
            internationalDialingCode: '218',
            currencyCode: 'LYD',
            flag: 'https://restcountries.eu/data/lby.svg',
            cioc: 'LBA',
            name: 'Libya',
            demonym: 'Libyan',
            cca2: 'LY',
            cca3: 'LBY',
            ccn3: '434',
            id: 'LBY',
          },
          {
            code: 'MA',
            internationalDialingCode: '212',
            currencyCode: 'MAD',
            flag: 'https://restcountries.eu/data/mar.svg',
            cioc: 'MAR',
            name: 'Morocco',
            demonym: 'Moroccan',
            cca2: 'MA',
            cca3: 'MAR',
            ccn3: '504',
            id: 'MAR',
          },
          {
            code: 'MC',
            internationalDialingCode: '377',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/mco.svg',
            cioc: 'MON',
            name: 'Monaco',
            demonym: 'Monegasque',
            cca2: 'MC',
            cca3: 'MCO',
            ccn3: '492',
            id: 'MCO',
          },
          {
            code: 'MD',
            internationalDialingCode: '373',
            currencyCode: 'MDL',
            flag: 'https://restcountries.eu/data/mda.svg',
            cioc: 'MDA',
            name: 'Moldova (Republic of)',
            demonym: 'Moldovan',
            cca2: 'MD',
            cca3: 'MDA',
            ccn3: '498',
            id: 'MDA',
          },
          {
            code: 'ME',
            internationalDialingCode: '382',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/mne.svg',
            cioc: 'MNE',
            name: 'Montenegro',
            demonym: 'Montenegrin',
            cca2: 'ME',
            cca3: 'MNE',
            ccn3: '499',
            id: 'MNE',
          },
          {
            code: 'MF',
            internationalDialingCode: '590',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/maf.svg',
            cioc: 'MAF',
            name: 'Saint Martin',
            demonym: 'Saint Martin',
            cca2: 'MF',
            cca3: 'MAF',
            ccn3: '663',
            id: 'MAF',
          },
          {
            code: 'MG',
            internationalDialingCode: '261',
            currencyCode: 'MGA',
            flag: 'https://restcountries.eu/data/mdg.svg',
            cioc: 'MAD',
            name: 'Madagascar',
            demonym: 'Malagasy',
            cca2: 'MG',
            cca3: 'MDG',
            ccn3: '450',
            id: 'MDG',
          },
          {
            code: 'MH',
            internationalDialingCode: '692',
            currencyCode: 'USD',
            flag: 'https://restcountries.eu/data/mhl.svg',
            cioc: 'MHL',
            name: 'Marshall Islands',
            demonym: 'Marshallese',
            cca2: 'MH',
            cca3: 'MHL',
            ccn3: '584',
            id: 'MHL',
          },
          {
            code: 'MK',
            internationalDialingCode: '389',
            currencyCode: 'MKD',
            flag: 'https://restcountries.eu/data/mkd.svg',
            cioc: 'MKD',
            name: 'Macedonia',
            demonym: 'Macedonian',
            cca2: 'MK',
            cca3: 'MKD',
            ccn3: '807',
            id: 'MKD',
          },
          {
            code: 'ML',
            internationalDialingCode: '223',
            currencyCode: 'XOF',
            flag: 'https://restcountries.eu/data/mli.svg',
            cioc: 'MLI',
            name: 'Mali',
            demonym: 'Malian',
            cca2: 'ML',
            cca3: 'MLI',
            ccn3: '466',
            id: 'MLI',
          },
          {
            code: 'MM',
            internationalDialingCode: '95',
            currencyCode: 'MMK',
            flag: 'https://restcountries.eu/data/mmr.svg',
            cioc: 'MYA',
            name: 'Myanmar',
            demonym: 'Burmese',
            cca2: 'MM',
            cca3: 'MMR',
            ccn3: '104',
            id: 'MMR',
          },
          {
            code: 'MN',
            internationalDialingCode: '976',
            currencyCode: 'MNT',
            flag: 'https://restcountries.eu/data/mng.svg',
            cioc: 'MGL',
            name: 'Mongolia',
            demonym: 'Mongolian',
            cca2: 'MN',
            cca3: 'MNG',
            ccn3: '496',
            id: 'MNG',
          },
          {
            code: 'MO',
            internationalDialingCode: '853',
            currencyCode: 'MOP',
            flag: 'https://restcountries.eu/data/mac.svg',
            cioc: 'MAC',
            name: 'Macao',
            demonym: 'Chinese',
            cca2: 'MO',
            cca3: 'MAC',
            ccn3: '446',
            id: 'MAC',
          },
          {
            code: 'MP',
            internationalDialingCode: '670',
            currencyCode: 'USD',
            flag: 'https://restcountries.eu/data/mnp.svg',
            cioc: 'MNP',
            name: 'Northern Mariana Islands',
            demonym: 'American',
            cca2: 'MP',
            cca3: 'MNP',
            ccn3: '580',
            id: 'MNP',
          },
          {
            code: 'MR',
            internationalDialingCode: '222',
            currencyCode: 'MRO',
            flag: 'https://restcountries.eu/data/mrt.svg',
            cioc: 'MTN',
            name: 'Mauritania',
            demonym: 'Mauritanian',
            cca2: 'MR',
            cca3: 'MRT',
            ccn3: '478',
            id: 'MRT',
          },
          {
            code: 'MS',
            internationalDialingCode: '664',
            currencyCode: 'XCD',
            flag: 'https://restcountries.eu/data/msr.svg',
            cioc: 'MSR',
            name: 'Montserrat',
            demonym: 'Montserratian',
            cca2: 'MS',
            cca3: 'MSR',
            ccn3: '500',
            id: 'MSR',
          },
          {
            code: 'MT',
            internationalDialingCode: '356',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/mlt.svg',
            cioc: 'MLT',
            name: 'Malta',
            demonym: 'Maltese',
            cca2: 'MT',
            cca3: 'MLT',
            ccn3: '470',
            id: 'MLT',
          },
          {
            code: 'MU',
            internationalDialingCode: '230',
            currencyCode: 'MUR',
            flag: 'https://restcountries.eu/data/mus.svg',
            cioc: 'MRI',
            name: 'Mauritius',
            demonym: 'Mauritian',
            cca2: 'MU',
            cca3: 'MUS',
            ccn3: '480',
            id: 'MUS',
          },
          {
            code: 'MV',
            internationalDialingCode: '960',
            currencyCode: 'MVR',
            flag: 'https://restcountries.eu/data/mdv.svg',
            cioc: 'MDV',
            name: 'Maldives',
            demonym: 'Maldivan',
            cca2: 'MV',
            cca3: 'MDV',
            ccn3: '462',
            id: 'MDV',
          },
          {
            code: 'MW',
            internationalDialingCode: '265',
            currencyCode: 'MWK',
            flag: 'https://restcountries.eu/data/mwi.svg',
            cioc: 'MAW',
            name: 'Malawi',
            demonym: 'Malawian',
            cca2: 'MW',
            cca3: 'MWI',
            ccn3: '454',
            id: 'MWI',
          },
          {
            code: 'MX',
            internationalDialingCode: '52',
            currencyCode: 'MXN',
            flag: 'https://restcountries.eu/data/mex.svg',
            cioc: 'MEX',
            name: 'Mexico',
            demonym: 'Mexican',
            cca2: 'MX',
            cca3: 'MEX',
            ccn3: '484',
            id: 'MEX',
          },
          {
            code: 'MY',
            internationalDialingCode: '60',
            currencyCode: 'MYR',
            flag: 'https://restcountries.eu/data/mys.svg',
            cioc: 'MAS',
            name: 'Malaysia',
            demonym: 'Malaysian',
            cca2: 'MY',
            cca3: 'MYS',
            ccn3: '458',
            id: 'MYS',
          },
          {
            code: 'MZ',
            internationalDialingCode: '258',
            currencyCode: 'MZN',
            flag: 'https://restcountries.eu/data/moz.svg',
            cioc: 'MOZ',
            name: 'Mozambique',
            demonym: 'Mozambican',
            cca2: 'MZ',
            cca3: 'MOZ',
            ccn3: '508',
            id: 'MOZ',
          },
          {
            code: 'NA',
            internationalDialingCode: '264',
            currencyCode: 'NAD',
            flag: 'https://restcountries.eu/data/nam.svg',
            cioc: 'NAM',
            name: 'Namibia',
            demonym: 'Namibian',
            cca2: 'NA',
            cca3: 'NAM',
            ccn3: '516',
            id: 'NAM',
          },
          {
            code: 'NC',
            internationalDialingCode: '687',
            currencyCode: 'XPF',
            flag: 'https://restcountries.eu/data/ncl.svg',
            cioc: 'NCL',
            name: 'New Caledonia',
            demonym: 'New Caledonian',
            cca2: 'NC',
            cca3: 'NCL',
            ccn3: '540',
            id: 'NCL',
          },
          {
            code: 'NE',
            internationalDialingCode: '227',
            currencyCode: 'XOF',
            flag: 'https://restcountries.eu/data/ner.svg',
            cioc: 'NIG',
            name: 'Niger',
            demonym: 'Nigerien',
            cca2: 'NE',
            cca3: 'NER',
            ccn3: '562',
            id: 'NER',
          },
          {
            code: 'NG',
            internationalDialingCode: '234',
            currencyCode: 'NGN',
            flag: 'https://restcountries.eu/data/nga.svg',
            cioc: 'NGR',
            name: 'Nigeria',
            demonym: 'Nigerian',
            cca2: 'NG',
            cca3: 'NGA',
            ccn3: '566',
            id: 'NGA',
          },
          {
            code: 'NI',
            internationalDialingCode: '505',
            currencyCode: 'NIO',
            flag: 'https://restcountries.eu/data/nic.svg',
            cioc: 'NCA',
            name: 'Nicaragua',
            demonym: 'Nicaraguan',
            cca2: 'NI',
            cca3: 'NIC',
            ccn3: '558',
            id: 'NIC',
          },
          {
            code: 'NL',
            internationalDialingCode: '31',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/nld.svg',
            cioc: 'NED',
            name: 'Netherlands',
            demonym: 'Dutch',
            cca2: 'NL',
            cca3: 'NLD',
            ccn3: '528',
            id: 'NLD',
          },
          {
            code: 'NO',
            internationalDialingCode: '47',
            currencyCode: 'NOK',
            flag: 'https://restcountries.eu/data/nor.svg',
            cioc: 'NOR',
            name: 'Norway',
            demonym: 'Norwegian',
            cca2: 'NO',
            cca3: 'NOR',
            ccn3: '578',
            id: 'NOR',
          },
          {
            code: 'NP',
            internationalDialingCode: '977',
            currencyCode: 'NPR',
            flag: 'https://restcountries.eu/data/npl.svg',
            cioc: 'NEP',
            name: 'Nepal',
            demonym: 'Nepalese',
            cca2: 'NP',
            cca3: 'NPL',
            ccn3: '524',
            id: 'NPL',
          },
          {
            code: 'NR',
            internationalDialingCode: '674',
            currencyCode: 'AUD',
            flag: 'https://restcountries.eu/data/nru.svg',
            cioc: 'NRU',
            name: 'Nauru',
            demonym: 'Nauruan',
            cca2: 'NR',
            cca3: 'NRU',
            ccn3: '520',
            id: 'NRU',
          },
          {
            code: 'NU',
            internationalDialingCode: '683',
            currencyCode: 'NZD',
            flag: 'https://restcountries.eu/data/niu.svg',
            cioc: 'NIU',
            name: 'Niue',
            demonym: 'Niuean',
            cca2: 'NU',
            cca3: 'NIU',
            ccn3: '570',
            id: 'NIU',
          },
          {
            code: 'NZ',
            internationalDialingCode: '64',
            currencyCode: 'NZD',
            flag: 'https://restcountries.eu/data/nzl.svg',
            cioc: 'NZL',
            name: 'New Zealand',
            demonym: 'New Zealander',
            cca2: 'NZ',
            cca3: 'NZL',
            ccn3: '554',
            id: 'NZL',
          },
          {
            code: 'OM',
            internationalDialingCode: '968',
            currencyCode: 'OMR',
            flag: 'https://restcountries.eu/data/omn.svg',
            cioc: 'OMA',
            name: 'Oman',
            demonym: 'Omani',
            cca2: 'OM',
            cca3: 'OMN',
            ccn3: '512',
            id: 'OMN',
          },
          {
            code: 'PA',
            internationalDialingCode: '507',
            currencyCode: 'PAB',
            flag: 'https://restcountries.eu/data/pan.svg',
            cioc: 'PAN',
            name: 'Panama',
            demonym: 'Panamanian',
            cca2: 'PA',
            cca3: 'PAN',
            ccn3: '591',
            id: 'PAN',
          },
          {
            code: 'PE',
            internationalDialingCode: '51',
            currencyCode: 'PEN',
            flag: 'https://restcountries.eu/data/per.svg',
            cioc: 'PER',
            name: 'Peru',
            demonym: 'Peruvian',
            cca2: 'PE',
            cca3: 'PER',
            ccn3: '604',
            id: 'PER',
          },
          {
            code: 'PF',
            internationalDialingCode: '689',
            currencyCode: 'XPF',
            flag: 'https://restcountries.eu/data/pyf.svg',
            cioc: 'PYF',
            name: 'French Polynesia',
            demonym: 'French Polynesian',
            cca2: 'PF',
            cca3: 'PYF',
            ccn3: '258',
            id: 'PYF',
          },
          {
            code: 'PG',
            internationalDialingCode: '675',
            currencyCode: 'PGK',
            flag: 'https://restcountries.eu/data/png.svg',
            cioc: 'PNG',
            name: 'Papua New Guinea',
            demonym: 'Papua New Guinean',
            cca2: 'PG',
            cca3: 'PNG',
            ccn3: '598',
            id: 'PNG',
          },
          {
            code: 'PH',
            internationalDialingCode: '63',
            currencyCode: 'PHP',
            flag: 'https://restcountries.eu/data/phl.svg',
            cioc: 'PHI',
            name: 'Philippines',
            demonym: 'Filipino',
            cca2: 'PH',
            cca3: 'PHL',
            ccn3: '608',
            id: 'PHL',
          },
          {
            code: 'PK',
            internationalDialingCode: '92',
            currencyCode: 'PKR',
            flag: 'https://restcountries.eu/data/pak.svg',
            cioc: 'PAK',
            name: 'Pakistan',
            demonym: 'Pakistani',
            cca2: 'PK',
            cca3: 'PAK',
            ccn3: '586',
            id: 'PAK',
          },
          {
            code: 'PL',
            internationalDialingCode: '48',
            currencyCode: 'PLN',
            flag: 'https://restcountries.eu/data/pol.svg',
            cioc: 'POL',
            name: 'Poland',
            demonym: 'Polish',
            cca2: 'PL',
            cca3: 'POL',
            ccn3: '616',
            id: 'POL',
          },
          {
            code: 'PM',
            internationalDialingCode: '508',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/spm.svg',
            cioc: 'SPM',
            name: 'Saint Pierre and Miquelon',
            demonym: 'French',
            cca2: 'PM',
            cca3: 'SPM',
            ccn3: '666',
            id: 'SPM',
          },
          {
            code: 'PN',
            internationalDialingCode: '64',
            currencyCode: 'NZD',
            flag: 'https://restcountries.eu/data/pcn.svg',
            cioc: 'PCN',
            name: 'Pitcairn',
            demonym: 'Pitcairn Islander',
            cca2: 'PN',
            cca3: 'PCN',
            ccn3: '612',
            id: 'PCN',
          },
          {
            code: 'PR',
            internationalDialingCode: '787',
            currencyCode: 'USD',
            flag: 'https://restcountries.eu/data/pri.svg',
            cioc: 'PUR',
            name: 'Puerto Rico',
            demonym: 'Puerto Rican',
            cca2: 'PR',
            cca3: 'PRI',
            ccn3: '630',
            id: 'PRI',
          },
          {
            code: 'PS',
            internationalDialingCode: '970',
            currencyCode: 'ILS',
            flag: 'https://restcountries.eu/data/pse.svg',
            cioc: 'PLE',
            name: 'Palestine, State of',
            demonym: 'Palestinian',
            cca2: 'PS',
            cca3: 'PSE',
            ccn3: '275',
            id: 'PSE',
          },
          {
            code: 'PT',
            internationalDialingCode: '351',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/prt.svg',
            cioc: 'POR',
            name: 'Portugal',
            demonym: 'Portuguese',
            cca2: 'PT',
            cca3: 'PRT',
            ccn3: '620',
            id: 'PRT',
          },
          {
            code: 'PY',
            internationalDialingCode: '595',
            currencyCode: 'PYG',
            flag: 'https://restcountries.eu/data/pry.svg',
            cioc: 'PAR',
            name: 'Paraguay',
            demonym: 'Paraguayan',
            cca2: 'PY',
            cca3: 'PRY',
            ccn3: '600',
            id: 'PRY',
          },
          {
            code: 'QA',
            internationalDialingCode: '974',
            currencyCode: 'QAR',
            flag: 'https://restcountries.eu/data/qat.svg',
            cioc: 'QAT',
            name: 'Qatar',
            demonym: 'Qatari',
            cca2: 'QA',
            cca3: 'QAT',
            ccn3: '634',
            id: 'QAT',
          },
          {
            code: 'RO',
            internationalDialingCode: '40',
            currencyCode: 'RON',
            flag: 'https://restcountries.eu/data/rou.svg',
            cioc: 'ROU',
            name: 'Romania',
            demonym: 'Romanian',
            cca2: 'RO',
            cca3: 'ROU',
            ccn3: '642',
            id: 'ROU',
          },
          {
            code: 'RS',
            internationalDialingCode: '381',
            currencyCode: 'RSD',
            flag: 'https://restcountries.eu/data/srb.svg',
            cioc: 'SRB',
            name: 'Serbia',
            demonym: 'Serbian',
            cca2: 'RS',
            cca3: 'SRB',
            ccn3: '688',
            id: 'SRB',
          },
          {
            code: 'RU',
            internationalDialingCode: '7',
            currencyCode: 'RUB',
            flag: 'https://restcountries.eu/data/rus.svg',
            cioc: 'RUS',
            name: 'Russian Federation',
            demonym: 'Russian',
            cca2: 'RU',
            cca3: 'RUS',
            ccn3: '643',
            id: 'RUS',
          },
          {
            code: 'RW',
            internationalDialingCode: '250',
            currencyCode: 'RWF',
            flag: 'https://restcountries.eu/data/rwa.svg',
            cioc: 'RWA',
            name: 'Rwanda',
            demonym: 'Rwandan',
            cca2: 'RW',
            cca3: 'RWA',
            ccn3: '646',
            id: 'RWA',
          },
          {
            code: 'SA',
            internationalDialingCode: '966',
            currencyCode: 'SAR',
            flag: 'https://restcountries.eu/data/sau.svg',
            cioc: 'KSA',
            name: 'Saudi Arabia',
            demonym: 'Saudi Arabian',
            cca2: 'SA',
            cca3: 'SAU',
            ccn3: '682',
            id: 'SAU',
          },
          {
            code: 'SB',
            internationalDialingCode: '677',
            currencyCode: 'SBD',
            flag: 'https://restcountries.eu/data/slb.svg',
            cioc: 'SOL',
            name: 'Solomon Islands',
            demonym: 'Solomon Islander',
            cca2: 'SB',
            cca3: 'SLB',
            ccn3: '090',
            id: 'SLB',
          },
          {
            code: 'SC',
            internationalDialingCode: '248',
            currencyCode: 'SCR',
            flag: 'https://restcountries.eu/data/syc.svg',
            cioc: 'SEY',
            name: 'Seychelles',
            demonym: 'Seychellois',
            cca2: 'SC',
            cca3: 'SYC',
            ccn3: '690',
            id: 'SYC',
          },
          {
            code: 'SD',
            internationalDialingCode: '249',
            currencyCode: 'SDG',
            flag: 'https://restcountries.eu/data/sdn.svg',
            cioc: 'SUD',
            name: 'Sudan',
            demonym: 'Sudanese',
            cca2: 'SD',
            cca3: 'SDN',
            ccn3: '729',
            id: 'SDN',
          },
          {
            code: 'SE',
            internationalDialingCode: '46',
            currencyCode: 'SEK',
            flag: 'https://restcountries.eu/data/swe.svg',
            cioc: 'SWE',
            name: 'Sweden',
            demonym: 'Swedish',
            cca2: 'SE',
            cca3: 'SWE',
            ccn3: '752',
            id: 'SWE',
          },
          {
            code: 'SG',
            internationalDialingCode: '65',
            currencyCode: 'SGD',
            flag: 'https://restcountries.eu/data/sgp.svg',
            cioc: 'SIN',
            name: 'Singapore',
            demonym: 'Singaporean',
            cca2: 'SG',
            cca3: 'SGP',
            ccn3: '702',
            id: 'SGP',
          },
          {
            code: 'SH',
            internationalDialingCode: '290',
            currencyCode: 'SHP',
            flag: 'https://restcountries.eu/data/shn.svg',
            cioc: 'SHN',
            name: 'Saint Helena, Ascension and Tristan da Cunha',
            demonym: 'Saint Helenian',
            cca2: 'SH',
            cca3: 'SHN',
            ccn3: '654',
            id: 'SHN',
          },
          {
            code: 'SI',
            internationalDialingCode: '386',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/svn.svg',
            cioc: 'SLO',
            name: 'Slovenia',
            demonym: 'Slovene',
            cca2: 'SI',
            cca3: 'SVN',
            ccn3: '705',
            id: 'SVN',
          },
          {
            code: 'SJ',
            internationalDialingCode: '47',
            currencyCode: 'NOK',
            flag: 'https://restcountries.eu/data/sjm.svg',
            cioc: 'SJM',
            name: 'Svalbard and Jan Mayen',
            demonym: 'Norwegian',
            cca2: 'SJ',
            cca3: 'SJM',
            ccn3: '744',
            id: 'SJM',
          },
          {
            code: 'SK',
            internationalDialingCode: '421',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/svk.svg',
            cioc: 'SVK',
            name: 'Slovakia',
            demonym: 'Slovak',
            cca2: 'SK',
            cca3: 'SVK',
            ccn3: '703',
            id: 'SVK',
          },
          {
            code: 'SL',
            internationalDialingCode: '232',
            currencyCode: 'SLL',
            flag: 'https://restcountries.eu/data/sle.svg',
            cioc: 'SLE',
            name: 'Sierra Leone',
            demonym: 'Sierra Leonean',
            cca2: 'SL',
            cca3: 'SLE',
            ccn3: '694',
            id: 'SLE',
          },
          {
            code: 'SM',
            internationalDialingCode: '378',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/smr.svg',
            cioc: 'SMR',
            name: 'San Marino',
            demonym: 'Sammarinese',
            cca2: 'SM',
            cca3: 'SMR',
            ccn3: '674',
            id: 'SMR',
          },
          {
            code: 'SN',
            internationalDialingCode: '221',
            currencyCode: 'XOF',
            flag: 'https://restcountries.eu/data/sen.svg',
            cioc: 'SEN',
            name: 'Senegal',
            demonym: 'Senegalese',
            cca2: 'SN',
            cca3: 'SEN',
            ccn3: '686',
            id: 'SEN',
          },
          {
            code: 'SO',
            internationalDialingCode: '252',
            currencyCode: 'SOS',
            flag: 'https://restcountries.eu/data/som.svg',
            cioc: 'SOM',
            name: 'Somalia',
            demonym: 'Somali',
            cca2: 'SO',
            cca3: 'SOM',
            ccn3: '706',
            id: 'SOM',
          },
          {
            code: 'SR',
            internationalDialingCode: '597',
            currencyCode: 'SRD',
            flag: 'https://restcountries.eu/data/sur.svg',
            cioc: 'SUR',
            name: 'Suriname',
            demonym: 'Surinamer',
            cca2: 'SR',
            cca3: 'SUR',
            ccn3: '740',
            id: 'SUR',
          },
          {
            code: 'SS',
            internationalDialingCode: '211',
            currencyCode: 'SSP',
            flag: 'https://restcountries.eu/data/ssd.svg',
            cioc: 'SSD',
            name: 'South Sudan',
            demonym: 'South Sudanese',
            cca2: 'SS',
            cca3: 'SSD',
            ccn3: '728',
            id: 'SSD',
          },
          {
            code: 'ST',
            internationalDialingCode: '239',
            currencyCode: 'STD',
            flag: 'https://restcountries.eu/data/stp.svg',
            cioc: 'STP',
            name: 'Sao Tome and Principe',
            demonym: 'Sao Tomean',
            cca2: 'ST',
            cca3: 'STP',
            ccn3: '678',
            id: 'STP',
          },
          {
            code: 'SV',
            internationalDialingCode: '503',
            currencyCode: 'USD',
            flag: 'https://restcountries.eu/data/slv.svg',
            cioc: 'ESA',
            name: 'El Salvador',
            demonym: 'Salvadoran',
            cca2: 'SV',
            cca3: 'SLV',
            ccn3: '222',
            id: 'SLV',
          },
          {
            code: 'SX',
            internationalDialingCode: '721',
            currencyCode: 'ANG',
            flag: 'https://restcountries.eu/data/sxm.svg',
            cioc: 'SXM',
            name: 'Sint Maarten (Dutch part)',
            demonym: 'Dutch',
            cca2: 'SX',
            cca3: 'SXM',
            ccn3: '534',
            id: 'SXM',
          },
          {
            code: 'SY',
            internationalDialingCode: '963',
            currencyCode: 'SYP',
            flag: 'https://restcountries.eu/data/syr.svg',
            cioc: 'SYR',
            name: 'Syrian Arab Republic',
            demonym: 'Syrian',
            cca2: 'SY',
            cca3: 'SYR',
            ccn3: '760',
            id: 'SYR',
          },
          {
            code: 'SZ',
            internationalDialingCode: '268',
            currencyCode: 'SZL',
            flag: 'https://restcountries.eu/data/swz.svg',
            cioc: 'SWZ',
            name: 'Swaziland',
            demonym: 'Swazi',
            cca2: 'SZ',
            cca3: 'SWZ',
            ccn3: '748',
            id: 'SWZ',
          },
          {
            code: 'TC',
            internationalDialingCode: '649',
            currencyCode: 'USD',
            flag: 'https://restcountries.eu/data/tca.svg',
            cioc: 'TCA',
            name: 'Turks and Caicos Islands',
            demonym: 'Turks and Caicos',
            cca2: 'TC',
            cca3: 'TCA',
            ccn3: '796',
            id: 'TCA',
          },
          {
            code: 'TD',
            internationalDialingCode: '235',
            currencyCode: 'XAF',
            flag: 'https://restcountries.eu/data/tcd.svg',
            cioc: 'CHA',
            name: 'Chad',
            demonym: 'Chadian',
            cca2: 'TD',
            cca3: 'TCD',
            ccn3: '148',
            id: 'TCD',
          },
          {
            code: 'TG',
            internationalDialingCode: '228',
            currencyCode: 'XOF',
            flag: 'https://restcountries.eu/data/tgo.svg',
            cioc: 'TOG',
            name: 'Togo',
            demonym: 'Togolese',
            cca2: 'TG',
            cca3: 'TGO',
            ccn3: '768',
            id: 'TGO',
          },
          {
            code: 'TH',
            internationalDialingCode: '66',
            currencyCode: 'THB',
            flag: 'https://restcountries.eu/data/tha.svg',
            cioc: 'THA',
            name: 'Thailand',
            demonym: 'Thai',
            cca2: 'TH',
            cca3: 'THA',
            ccn3: '764',
            id: 'THA',
          },
          {
            code: 'TJ',
            internationalDialingCode: '992',
            currencyCode: 'TJS',
            flag: 'https://restcountries.eu/data/tjk.svg',
            cioc: 'TJK',
            name: 'Tajikistan',
            demonym: 'Tadzhik',
            cca2: 'TJ',
            cca3: 'TJK',
            ccn3: '762',
            id: 'TJK',
          },
          {
            code: 'TK',
            internationalDialingCode: '690',
            currencyCode: 'NZD',
            flag: 'https://restcountries.eu/data/tkl.svg',
            cioc: 'TKL',
            name: 'Tokelau',
            demonym: 'Tokelauan',
            cca2: 'TK',
            cca3: 'TKL',
            ccn3: '772',
            id: 'TKL',
          },
          {
            code: 'TL',
            internationalDialingCode: '670',
            currencyCode: 'USD',
            flag: 'https://restcountries.eu/data/tls.svg',
            cioc: 'TLS',
            name: 'Timor-Leste',
            demonym: 'East Timorese',
            cca2: 'TL',
            cca3: 'TLS',
            ccn3: '626',
            id: 'TLS',
          },
          {
            code: 'TM',
            internationalDialingCode: '993',
            currencyCode: 'TMT',
            flag: 'https://restcountries.eu/data/tkm.svg',
            cioc: 'TKM',
            name: 'Turkmenistan',
            demonym: 'Turkmen',
            cca2: 'TM',
            cca3: 'TKM',
            ccn3: '795',
            id: 'TKM',
          },
          {
            code: 'TN',
            internationalDialingCode: '216',
            currencyCode: 'TND',
            flag: 'https://restcountries.eu/data/tun.svg',
            cioc: 'TUN',
            name: 'Tunisia',
            demonym: 'Tunisian',
            cca2: 'TN',
            cca3: 'TUN',
            ccn3: '788',
            id: 'TUN',
          },
          {
            code: 'TO',
            internationalDialingCode: '676',
            currencyCode: 'TOP',
            flag: 'https://restcountries.eu/data/ton.svg',
            cioc: 'TGA',
            name: 'Tonga',
            demonym: 'Tongan',
            cca2: 'TO',
            cca3: 'TON',
            ccn3: '776',
            id: 'TON',
          },
          {
            code: 'TR',
            internationalDialingCode: '90',
            currencyCode: 'TRY',
            flag: 'https://restcountries.eu/data/tur.svg',
            cioc: 'TUR',
            name: 'Turkey',
            demonym: 'Turkish',
            cca2: 'TR',
            cca3: 'TUR',
            ccn3: '792',
            id: 'TUR',
          },
          {
            code: 'TT',
            internationalDialingCode: '868',
            currencyCode: 'TTD',
            flag: 'https://restcountries.eu/data/tto.svg',
            cioc: 'TTO',
            name: 'Trinidad and Tobago',
            demonym: 'Trinidadian',
            cca2: 'TT',
            cca3: 'TTO',
            ccn3: '780',
            id: 'TTO',
          },
          {
            code: 'TV',
            internationalDialingCode: '688',
            currencyCode: 'AUD',
            flag: 'https://restcountries.eu/data/tuv.svg',
            cioc: 'TUV',
            name: 'Tuvalu',
            demonym: 'Tuvaluan',
            cca2: 'TV',
            cca3: 'TUV',
            ccn3: '798',
            id: 'TUV',
          },
          {
            code: 'TW',
            internationalDialingCode: '886',
            currencyCode: 'TWD',
            flag: 'https://restcountries.eu/data/twn.svg',
            cioc: 'TPE',
            name: 'Taiwan',
            demonym: 'Taiwanese',
            cca2: 'TW',
            cca3: 'TWN',
            ccn3: '158',
            id: 'TWN',
          },
          {
            code: 'TZ',
            internationalDialingCode: '255',
            currencyCode: 'TZS',
            flag: 'https://restcountries.eu/data/tza.svg',
            cioc: 'TAN',
            name: 'Tanzania, United Republic of',
            demonym: 'Tanzanian',
            cca2: 'TZ',
            cca3: 'TZA',
            ccn3: '834',
            id: 'TZA',
          },
          {
            code: 'UA',
            internationalDialingCode: '380',
            currencyCode: 'UAH',
            flag: 'https://restcountries.eu/data/ukr.svg',
            cioc: 'UKR',
            name: 'Ukraine',
            demonym: 'Ukrainian',
            cca2: 'UA',
            cca3: 'UKR',
            ccn3: '804',
            id: 'UKR',
          },
          {
            code: 'UG',
            internationalDialingCode: '256',
            currencyCode: 'UGX',
            flag: 'https://restcountries.eu/data/uga.svg',
            cioc: 'UGA',
            name: 'Uganda',
            demonym: 'Ugandan',
            cca2: 'UG',
            cca3: 'UGA',
            ccn3: '800',
            id: 'UGA',
          },
          {
            code: 'US',
            internationalDialingCode: '1',
            currencyCode: 'USD',
            flag: 'https://restcountries.eu/data/usa.svg',
            cioc: 'USA',
            name: 'United States of America',
            demonym: 'American',
            cca2: 'US',
            cca3: 'USA',
            ccn3: '840',
            id: 'USA',
          },
          {
            code: 'UY',
            internationalDialingCode: '598',
            currencyCode: 'UYU',
            flag: 'https://restcountries.eu/data/ury.svg',
            cioc: 'URU',
            name: 'Uruguay',
            demonym: 'Uruguayan',
            cca2: 'UY',
            cca3: 'URY',
            ccn3: '858',
            id: 'URY',
          },
          {
            code: 'UZ',
            internationalDialingCode: '998',
            currencyCode: 'UZS',
            flag: 'https://restcountries.eu/data/uzb.svg',
            cioc: 'UZB',
            name: 'Uzbekistan',
            demonym: 'Uzbekistani',
            cca2: 'UZ',
            cca3: 'UZB',
            ccn3: '860',
            id: 'UZB',
          },
          {
            code: 'VA',
            internationalDialingCode: '379',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/vat.svg',
            cioc: 'VAT',
            name: 'Holy See',
            demonym: 'Holy See',
            cca2: 'VA',
            cca3: 'VAT',
            ccn3: '336',
            id: 'VAT',
          },
          {
            code: 'VC',
            internationalDialingCode: '784',
            currencyCode: 'XCD',
            flag: 'https://restcountries.eu/data/vct.svg',
            cioc: 'VIN',
            name: 'Saint Vincent and the Grenadines',
            demonym: 'Saint Vincentian',
            cca2: 'VC',
            cca3: 'VCT',
            ccn3: '670',
            id: 'VCT',
          },
          {
            code: 'VE',
            internationalDialingCode: '58',
            currencyCode: 'VEF',
            flag: 'https://restcountries.eu/data/ven.svg',
            cioc: 'VEN',
            name: 'Venezuela',
            demonym: 'Venezuelan',
            cca2: 'VE',
            cca3: 'VEN',
            ccn3: '862',
            id: 'VEN',
          },
          {
            code: 'VG',
            internationalDialingCode: '284',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/vgb.svg',
            cioc: 'IVB',
            name: 'Virgin Islands (British)',
            demonym: 'Virgin Islander',
            cca2: 'VG',
            cca3: 'VGB',
            ccn3: '092',
            id: 'VGB',
          },
          {
            code: 'VI',
            internationalDialingCode: '340',
            currencyCode: 'USD',
            flag: 'https://restcountries.eu/data/vir.svg',
            cioc: 'ISV',
            name: 'Virgin Islands (U.S.)',
            demonym: 'Virgin Islander',
            cca2: 'VI',
            cca3: 'VIR',
            ccn3: '850',
            id: 'VIR',
          },
          {
            code: 'VN',
            internationalDialingCode: '84',
            currencyCode: 'VND',
            flag: 'https://restcountries.eu/data/vnm.svg',
            cioc: 'VIE',
            name: 'Viet Nam',
            demonym: 'Vietnamese',
            cca2: 'VN',
            cca3: 'VNM',
            ccn3: '704',
            id: 'VNM',
          },
          {
            code: 'VU',
            internationalDialingCode: '678',
            currencyCode: 'VUV',
            flag: 'https://restcountries.eu/data/vut.svg',
            cioc: 'VAN',
            name: 'Vanuatu',
            demonym: 'Ni-Vanuatu',
            cca2: 'VU',
            cca3: 'VUT',
            ccn3: '548',
            id: 'VUT',
          },
          {
            code: 'WF',
            internationalDialingCode: '681',
            currencyCode: 'XPF',
            flag: 'https://restcountries.eu/data/wlf.svg',
            cioc: 'WLF',
            name: 'Wallis and Futuna',
            demonym: 'Wallis and Futuna',
            cca2: 'WF',
            cca3: 'WLF',
            ccn3: '876',
            id: 'WLF',
          },
          {
            code: 'WS',
            internationalDialingCode: '685',
            currencyCode: 'WST',
            flag: 'https://restcountries.eu/data/wsm.svg',
            cioc: 'SAM',
            name: 'Samoa',
            demonym: 'Samoan',
            cca2: 'WS',
            cca3: 'WSM',
            ccn3: '882',
            id: 'WSM',
          },
          {
            code: 'YE',
            internationalDialingCode: '967',
            currencyCode: 'YER',
            flag: 'https://restcountries.eu/data/yem.svg',
            cioc: 'YEM',
            name: 'Yemen',
            demonym: 'Yemeni',
            cca2: 'YE',
            cca3: 'YEM',
            ccn3: '887',
            id: 'YEM',
          },
          {
            code: 'YT',
            internationalDialingCode: '262',
            currencyCode: 'EUR',
            flag: 'https://restcountries.eu/data/myt.svg',
            cioc: 'MYT',
            name: 'Mayotte',
            demonym: 'French',
            cca2: 'YT',
            cca3: 'MYT',
            ccn3: '175',
            id: 'MYT',
          },
          {
            code: 'ZA',
            internationalDialingCode: '27',
            currencyCode: 'ZAR',
            flag: 'https://restcountries.eu/data/zaf.svg',
            cioc: 'RSA',
            name: 'South Africa',
            demonym: 'South Africa',
            cca2: 'ZA',
            cca3: 'ZAF',
            ccn3: '710',
            id: 'ZAF',
          },
          {
            code: 'ZM',
            internationalDialingCode: '260',
            currencyCode: 'ZMW',
            flag: 'https://restcountries.eu/data/zmb.svg',
            cioc: 'ZAM',
            name: 'Zambia',
            demonym: 'Zambian',
            cca2: 'ZM',
            cca3: 'ZMB',
            ccn3: '894',
            id: 'ZMB',
          },
          {
            code: 'ZW',
            internationalDialingCode: '263',
            currencyCode: 'BWP',
            flag: 'https://restcountries.eu/data/zwe.svg',
            cioc: 'ZIM',
            name: 'Zimbabwe',
            demonym: 'Zimbabwean',
            cca2: 'ZW',
            cca3: 'ZWE',
            ccn3: '716',
            id: 'ZWE',
          },
        ])
      );
    }
  ),
  rest.get(
    'http://localhost:9000/api/v1/bambu-api-library-integration/country-metadata-apis/country-rates',
    (req, res, ctx) => {
      const searchParams = req.url.searchParams.get('countryAlpha2Code');

      if (searchParams) {
        const countryRate = getCountryRatesMockResponse.filter(
          (country) => country.countryCode === searchParams
        );

        return res(
          ctx.status(200),
          ctx.json<GetCountryRatesResponseDto>(countryRate)
        );
      }

      return res(
        ctx.status(200),
        ctx.json<GetCountryRatesResponseDto>(getCountryRatesMockResponse)
      );
    }
  ),
];
