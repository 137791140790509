import { SvgIcon } from '@bambu/react-ui';
import type { SvgIconProps } from '@bambu/react-ui';

export function BooksIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox={'0 0 44 44'} {...props}>
      <path d="M37.2125 38.2685C37.2125 38.848 36.7428 39.318 36.1631 39.318H34.4778H30.9796H5.5361C3.55892 39.318 1.95052 37.7093 1.95052 35.7324V31.7961C1.95052 29.8191 3.55892 28.2105 5.5361 28.2105H30.9796H34.4778H36.1631C36.7428 28.2105 37.2125 28.6804 37.2125 29.26C37.2125 29.8395 36.7428 30.3094 36.1631 30.3094H35.5273V37.219H36.1631C36.7428 37.219 37.2125 37.689 37.2125 38.2685ZM41.7074 25.8003C41.7074 26.3798 41.2376 26.8498 40.6579 26.8498H39.0795H35.4744H14.3662C12.5276 26.8498 11.0319 25.3541 11.0319 23.5156V20.7142C11.0319 18.8758 12.5276 17.3801 14.3662 17.3801H35.4742H39.0793H40.6577C41.2374 17.3801 41.7071 17.85 41.7071 18.4295C41.7071 19.0091 41.2374 19.479 40.6577 19.479H40.1288V24.7508H40.6577C41.2376 24.7508 41.7074 25.2206 41.7074 25.8003ZM14.3662 24.7508H35.4742H38.0298V19.4788H35.4742H14.3662C13.6851 19.4788 13.1308 20.0329 13.1308 20.714V23.5154C13.131 24.1967 13.6851 24.7508 14.3662 24.7508ZM42.9505 4.68164H33.9611H10.0391H1.04946C0.469737 4.68164 0 5.15159 0 5.7311C0 6.31061 0.469737 6.78056 1.04946 6.78056H1.95052V15.7551C1.47008 16.0886 1.15419 16.6436 1.15419 17.2716C1.15419 18.2893 1.98222 19.1174 2.99998 19.1174C4.01775 19.1174 4.84577 18.2893 4.84577 17.2716C4.84577 16.6436 4.52988 16.0888 4.04944 15.7551V6.78056H8.98966V15.0547C8.98966 15.6342 9.4594 16.1042 10.0391 16.1042H33.9611C34.5408 16.1042 35.0106 15.6342 35.0106 15.0547V6.78056H42.9505C43.5303 6.78056 44 6.31061 44 5.7311C44 5.15159 43.5303 4.68164 42.9505 4.68164Z" />
    </SvgIcon>
  );
}
