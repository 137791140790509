import { SvgIcon } from '@bambu/react-ui';
import type { SvgIconProps } from '@bambu/react-ui';

export type ExpenseIcon = SvgIconProps;

export function ExpenseIcon(props: ExpenseIcon) {
  return (
    <SvgIcon viewBox="0 0 44 44" {...props}>
      <path d="M30.0225 28.875C30.0225 29.2093 30.1553 29.5299 30.3916 29.7662C30.628 30.0026 30.9486 30.1354 31.2829 30.1354H37.5852V27.6146H31.2829C30.9486 27.6146 30.628 27.7474 30.3916 27.9837C30.1553 28.2201 30.0225 28.5407 30.0225 28.875Z" />
      <path d="M35.0639 19.0311V17.5312C35.0639 16.5284 34.6656 15.5666 33.9564 14.8575C33.2473 14.1484 32.2855 13.75 31.2826 13.75H27.8037L25.2828 16.2708H31.2826C31.6169 16.2708 31.9375 16.4036 32.1739 16.64C32.4102 16.8764 32.543 17.197 32.543 17.5312V18.7917H11.1153C10.781 18.7917 10.4604 18.6589 10.2241 18.4225C9.98769 18.1861 9.85489 17.8655 9.85489 17.5312C9.85489 17.197 9.98769 16.8764 10.2241 16.64C10.4604 16.4036 10.781 16.2708 11.1153 16.2708H17.1151L14.5942 13.75H11.1153C10.1125 13.75 9.15066 14.1484 8.44152 14.8575C7.73238 15.5666 7.33398 16.5284 7.33398 17.5312V35.1771C7.33398 36.1799 7.73238 37.1417 8.44152 37.8508C9.15066 38.56 10.1125 38.9583 11.1153 38.9583H33.8035C34.8064 38.9583 35.7682 38.56 36.4773 37.8508C37.1865 37.1417 37.5848 36.1799 37.5848 35.1771V32.6562H31.2826C30.2797 32.6562 29.3179 32.2579 28.6088 31.5487C27.8996 30.8396 27.5012 29.8778 27.5012 28.875C27.5012 27.8721 27.8996 26.9104 28.6088 26.2013C29.3179 25.4921 30.2797 25.0938 31.2826 25.0938H37.5848V22.5729C37.5801 21.7949 37.3354 21.0374 36.8843 20.4035C36.4331 19.7697 35.7975 19.2905 35.0639 19.0311Z" />
      <path d="M22.0947 4.03949C21.9775 3.92136 21.8381 3.82759 21.6845 3.7636C21.5309 3.69961 21.3661 3.66666 21.1997 3.66666C21.0333 3.66666 20.8686 3.69961 20.715 3.7636C20.5614 3.82759 20.422 3.92136 20.3048 4.03949L16.5235 7.82074C16.3486 7.99799 16.2301 8.22306 16.183 8.46757C16.1359 8.71208 16.1622 8.96506 16.2588 9.1946C16.3533 9.42477 16.5139 9.62181 16.7203 9.76087C16.9266 9.89994 17.1695 9.97481 17.4184 9.97605H19.9393V15.0177C19.9393 15.352 20.0721 15.6726 20.3085 15.909C20.5448 16.1453 20.8654 16.2781 21.1997 16.2781C21.534 16.2781 21.8546 16.1453 22.091 15.909C22.3274 15.6726 22.4602 15.352 22.4602 15.0177V9.97605H24.9811C25.2299 9.97481 25.4728 9.89994 25.6792 9.76087C25.8856 9.62181 26.0461 9.42477 26.1407 9.1946C26.2372 8.96506 26.2636 8.71208 26.2165 8.46757C26.1694 8.22306 26.0509 7.99799 25.876 7.82074L22.0947 4.03949Z" />
    </SvgIcon>
  );
}

export default ExpenseIcon;
